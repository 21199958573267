import { useEffect, useState } from "react";
import { api } from "../../../api";
import { DocumentObject } from "../../../types/Document";
import { InvitationTypes } from "../../../types/Invitations";

/**
 * Loads a document from the api
 *
 * @param id the document id
 * @param shareType
 * @returns a document object state
 */
export const useDocument = (id: string, shareType: InvitationTypes) => {
  const [document, setDocument] = useState<DocumentObject | null>(null);

  useEffect(() => {
    if (shareType !== InvitationTypes.Document) return;
    (async () => {
      const _document = await api.documents.getDocument(id);
      setDocument(_document);
    })();
  }, []);

  return document;
};

import React, { useContext, useEffect, useState } from "react";
import { formatTimestamp, retrieveAircraftImg, truncateString } from "../../utils/helpers";
import Label from "../Forms/Label";
import aircraftIcon from "../../images/aircraft-icon.svg";
import editIcon from "../../images/edit-small.svg";
import { AircraftModalProps, AircraftFormData, ModalOptions } from "../../types/Modal";
import { AircraftModalTypes } from "../../types/Aircraft";
import alert from "../../images/alert.svg";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { AircraftContext, UserDataContext } from "../../utils/store";
import Loader from "../Loader";
import { useModalData } from "../Modals/hooks/useModalData";
import { api } from "../../api";
import { toast } from "react-toastify";
import { TOAST_STATUSES } from "../../utils/toast_statuses";
import { aircraftOwnerNames } from "../../utils/aircrafts.utils";
import volasUser from "../../images/volas-user.svg";
import {Box} from "@chakra-ui/react";

const AircraftOverview = () => {

  const { aircraftData, setAircraftData } = useContext(AircraftContext);
  const { userData } = useContext(UserDataContext);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [profilePic, setProfilePic] = useState("");

  const canUserEditAircraft =
    aircraftData.created_by === userData.id ||
    aircraftData.sharedWith.find(user => user.id === userData.id && user.permissionLevel.toLowerCase() === 'admin');

  const isUserPotentialBuyer = aircraftData.sharedWith
    .find(user =>  {
      return user.id === userData.id &&
        user.aircraftRoles.map(role => role.name).includes('potential buyer')
    })

  const aircraftInputs = [
    {
      title: "aircraft title",
      value: aircraftData.title,
    },
    {
      title: "aircraft owners",
      value: aircraftOwnerNames(aircraftData.sharedWith).join(", "),
    },
    {
      title: "aircraft type",
      value: aircraftData.type,
    },
    {
      title: "aircraft base",
      value: aircraftData.base,
    },
    {
      title: "tail number",
      value: aircraftData.tail_number,
    },
    {
      title: "serial number",
      value: aircraftData.serial_number,
    },
  ];

  /**
   * handles submission for updating aircraft information
   * 
   * @param aircraftId 
   * @param aircraftData 
   */
  const handleUpdateAircraft = async (aircraftData: AircraftFormData, aircraftId: string) => {
    try {
      const aircraft = await toast.promise(
        api.aircraft.updateAircraft(aircraftId, aircraftData),
        TOAST_STATUSES.saveAircraft
      );

      setProfilePic("")
      setProfilePic(retrieveAircraftImg(aircraft.id))

      setAircraftData(aircraft);
    } catch (error) {
      console.error(error);
    } finally {
      setIsOpen(false);
    }
  };

  /**
   * AircraftOverview {@link AircraftModal}
   */
  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: ModalOptions.Aircraft,
    childModalProps: {
      aircraftData,
      modalType: AircraftModalTypes.Edit,
      handleSubmit: handleUpdateAircraft
    } as AircraftModalProps,
  };

  useEffect( () => {
    if (aircraftData.profile_picture_key) {
      setProfilePic(retrieveAircraftImg(aircraftData.id))
    }
  }, []);

  useModalData(modalData)

  if (!aircraftData) {
    return <Loader />;
  }

  return (
    <div className="bg-neutral-gray w-full md:w-full mt-16 md:pl-16">
      <div className="flex flex-col pb-32 md:pb-0 md:mr-10 items-start md:max-w-70 md:w-865 px-6 md:px-0">
        <h1 className="text-lg md:text-xxxl text-center self-start">
          Aircraft Overview
        </h1>
        <p className="text-xs md:text-base text-welcome-gray text-center md:text-left mt-1 md:mt-4">
          {`Added ${formatTimestamp(aircraftData.created_at)}`}
        </p>
        <div className="border-b border-gray-300 self-center md:self-start w-full mt-4 my-6" />
        <div className="flex flex-col justify-evenly self-center md:self-start h-auto w-11/12 md:w-450 bg-white rounded pt-6 pb-8">
          <div className="flex justify-between self-center w-10/12">
            <div className="flex mb-6 items-center">
              <img src={aircraftIcon} alt="" className="w-4 mr-3" />
              <h2 className="text-xl md:text-xxxl">
                {truncateString(aircraftData.title, 18)}
              </h2>
            </div>
            { canUserEditAircraft && (
                <button
                  className="flex items-center border border-volas-red bg-white rounded-md px-2 font-semibold tracking-wider h-7 text-xs hover:bg-gray-100"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <img src={editIcon} alt="" className="mr-2"/>
                  EDIT
                </button>
              )
            }
          </div>

          {aircraftInputs.map((data) => {
            if (data.title === "aircraft title") return;
            return (
              <div className="w-10/12 self-center mb-3" key={data.title}>
                <Box ml={"-8px"}>
                  <Label
                    hidden="false"
                    title={data.title.toUpperCase()}
                    required={false}
                  />
                </Box>
                <div className="minimum-height-20 h-auto text-sm text-xx-neutral-gray border-b select-none border-gray-600 pl-0 mt-2 w-full focus:outline-none">
                  {(isUserPotentialBuyer && data.title === "aircraft owners") ? (
                   <></>
                  ) : (
                    <>{data.value}</>
                  )}
                  {data.title === "aircraft owners" && (!data.value) && (
                    <Tooltip
                      overlay="Aircraft requires a registered owner, this can be set in the 'Users' section above"
                      placement="right"
                    >
                      <img src={alert} alt="" className="cursor-pointer" />
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          })}
          <div className="w-10/12 self-center" key="picture">
            <Label
              hidden="false"
              title="PROFILE PICTURE"
              required={false}
            />
            {
              profilePic ? (
                <img alt="aircraft profile" src={profilePic} />
              ): (
                <div className="h-7 text-sm text-xx-neutral-gray border-b select-none border-gray-600 pl-0 mt-1 w-full focus:outline-none">
                  No Profile Picture Uploaded
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AircraftOverview;

import React from "react";
import { Link } from "react-router-dom";
import logoDecor1 from "../images/logo-graphic-bottom.svg";
import logoDecor2 from "../images/logo-graphic-top.svg";
import logo from "../images/volas-logo-vertical.svg";
import RedButton from "./Buttons/RedButton";
import { RedBtnImg } from "../types/Button";

const LandingPage = () => {
  return (
    <div className="h-screen w-screen bg-logo-grey absolute flex flex-col justify-evenly items-center">
      <img className="logo-decor h-1/5 fixed top-0" src={logoDecor2} alt="" />
      <div className="fixed -translate-y-1/2 object-contain z-50 max-h-auto bg-logo-grey w-auto flex flex-col items-center justify-center">
        <img className="max-w-160 h-auto md:w-1/2" src={logo} alt="" />
        <h2 className="text-white text-xl md:text-2xl font-light py-4 md:py-8 text-center">
          The digital standard in aviation.
        </h2>
        <Link to="/login" className="flex justify-center">
          <RedButton
            onClickEvent={null}
            styles="self-center mt-6 md:mt-0 w-44 px-3 py-2 md:py-3 font-semibold text-xxs md:text-xs"
            title="LOG IN"
            img={RedBtnImg.Arrow}
          />
        </Link>
      </div>
      <img className="logo-decor h-1/5 fixed bottom-0" src={logoDecor1} alt="" />
    </div>
  );
};

export default LandingPage;

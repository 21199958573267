import React from "react";
import { PermissionedRoles } from "../../types/Invitations";

const PermissionRole: React.FC<PermissionedRoles> = (props) => {
  const { user, setUserPermission, permission, updatedPermission } = props;

  const hideRole =
    (!updatedPermission && user.permission === permission) ||
    (updatedPermission && updatedPermission === permission);

  return (
    <div
      className={`${
        hideRole && "hidden"
      } permission-role text-xx-neutral-gray block px-2 md:px-4 py-2 text-xs focus:outline-none hover:bg-gray-100`}
      role="menuitem"
      id="menu-item-2"
      onClick={() => setUserPermission(permission)}
    >
      {permission}
    </div>
  );
};

export default PermissionRole;

import { UserObject } from "./User";
import { DocumentObject } from "./Document";

export interface TransactionObject {
  id: string;
  name: string;
  created_at: string;
  aircraft_id: string;
  created_by: string;
  is_complete: boolean;
  is_seller_approval: boolean;
  is_forms_filled: boolean;
  is_deposit_state: boolean;
  is_disbursement_funds: boolean;
  is_funds_received: boolean;
  users: UserObject[];
  sharedWith: UserObject[];
  sharedWithPending: UserObject[];
  updated_at?: string;
  aircraft_title?: string;
  aircraft_type?: string;
  aircraft_tail_number?: string;
  requiredDocuments?: DocumentObject[];

  // For permissions. hate this, need to update return type on API call
  // and use a different type on front end
  roles?: (string | null)[];
  permission_level?: string;
}

export enum TxsDisplayType {
  User,
  Aircraft,
}

export enum Status {
  Pending = "pending",
  Complete = "complete",
}

export enum RequiredForms {
  Faa = "Registration forms filed",
  Seller = "Seller approval",
  Deposit = "Deposit Received",
  Disbursement = "Funds Released",
  Funds = "All Funds Received"
}

export enum TxUserRole {
  Buyer = "Buyer",
  Seller = "Seller",
  Escrow = "Escrow",
}

export enum TxDocumentType {
  Required = "Required",
  Optional = "Optional",
}

export enum TransactionRole {
  Buyer = "buyer",
  Seller = "seller",
  Escrow = "escrow",
  Other = "other",
}

export interface RequiredTxForms {
  id: string;
  title: string;
  subTitle: string;
  complete: boolean;
  disabled: boolean;
}

export interface BadgeProps {
  badges: string[];
  completedBadges: string[];
}

export interface TransactionHeaderProps {
  title: string;
  subTitle: string;
  badges?: string[];
  userRole?: TxUserRole;
  sectionCompleted: boolean;
  completedBadges?: string[];
}

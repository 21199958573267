import { Role } from "../types/Role";
import { del, get, post } from "./axios";

/**
 * Get all roles
 *
 * @returns an array of roles
 */
const getRoles = async (): Promise<Role[]> => {
  try {
    const res = await get("/role");
    const roles = res.data;
    return roles;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Creates a new role
 *
 * @param name the role name
 * @param category the role category
 * @returns the id of the new role
 */
const createRole = async (name: string, category: string): Promise<Role> => {
  try {
    const res = await post("/role", { name, category });
    const role = res.data;
    return role;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Deletes a role
 *
 * @param id the role id
 */
const deleteRole = async (id: string): Promise<void> => {
  try {
    await del(`/role/${id}`);
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

export const roles = { getRoles, createRole, deleteRole };

import React from "react";
import plusSign from "../../../images/plus-sign.svg";
import closeMark from "../../../images/x.svg";
import { Tag } from "../../../types/Tag";
import TagComponent from '../Tag'
interface TagListProps {
  // @todo update type with tags are defined
  tagList: Tag[];
  resourceId?: string;
  activeTagFilters?: any[];
  handleNewTag?(): void;
  handleClearTags?(): void;
  handleOnClick?(arg0: any): void;
  handleRemoveTag?(resourceId: string, tagId: string): void;
  updateTagFilters?(tag: Tag): void;
  tagInputValue?: string;
  tagType?: 'network' | 'document'
}
const TagList = ({
  tagList,
  resourceId,
  activeTagFilters,
  handleNewTag,
  handleClearTags,
  handleOnClick,
  handleRemoveTag,
  updateTagFilters,
  tagInputValue
}: TagListProps) => {
  const mappedActiveTagIds = activeTagFilters && activeTagFilters.length ? activeTagFilters!.map((t) => t.id) : [];

  const STANDARD_PILL_STYLES = "border-xx-neutral-gray text-xx-neutral-gray";
  const ACTIVE_PILL_STYLES = "border-volas-darker-red bg-volas-light-red text-volas-darker-red";

  const CleanTagFiltersButton = () =>
    !!handleClearTags && !!tagList.length ? (
      <button className="underline ml-4 text-sm" onClick={handleClearTags}>
        <div>Clear Filters</div>
      </button>
    ) : null;

  const CreateNewTag = () =>
    !!handleNewTag ? (
      <TagComponent
        title="Add Tag"
        styles="border-x-neutral-gray text-xxx-gray cursor-pointer"
        handleClick={handleNewTag}
        icon={plusSign}
        isAdd={true}
      />
    ) : null;

  const handleClick = (tag: Tag) => {
    if (!!updateTagFilters) {
      updateTagFilters(tag);
    }
    if (!!handleRemoveTag) {
      handleRemoveTag(resourceId!, tag.id);
    }
    if(!!handleOnClick) {
      handleOnClick(tag)
    }
  };
  
  return (
    <div className="flex flex-wrap">
      {tagList.map((tag: Tag, i) => {
        const isTagActive = mappedActiveTagIds.includes(tag.id) || tag.name === tagInputValue;
        const styles = isTagActive ? ACTIVE_PILL_STYLES : STANDARD_PILL_STYLES;
        const icon = !!handleRemoveTag ? closeMark : undefined
        return (
          <TagComponent
            key={i}
            title={tag.name}
            styles={`${styles}`}
            handleClick={() => handleClick(tag)}
            icon={icon}
          />
        );
      })}
      <CleanTagFiltersButton />
      <CreateNewTag />
    </div>
  );
};

export default TagList;

import React, { useRef, useState } from "react";
import fileIcon from "../../images/file-icon.svg";
import download from "../../images/download.svg";
import { DocumentObject } from "../../types/Document";
import { formatTimestamp, truncateString } from "../../utils/helpers";
import { useOutsideClickToCloseModal } from "../../hooks/useOutsideClickToCloseModal";

const DocumentVersions = (props: {
  documentVersions: DocumentObject[];
  onViewFile: (id: string) => void;
}) => {
  const { documentVersions, onViewFile } = props;
  const [showDocumentVersions, setShowDocumentVersions] = useState(false);

  const versionRef = useRef<HTMLInputElement>(null);
  const documentVersionsLength = documentVersions.length;

  useOutsideClickToCloseModal(versionRef, setShowDocumentVersions);
  return (
    <div
      ref={versionRef}
      className={`${!documentVersions.length && "hidden"} mr-4`}
    >
      <button
        onClick={() => setShowDocumentVersions(!showDocumentVersions)}
        id="dropdownButton"
        data-dropdown-toggle="dropdown"
        className="w-36 text-gray-700 tracking-wide text-xs bg-volas-yellow py-1 rounded-sm font-semibold px-2  text-center flex justify-between items-center"
        type="button"
      >
        <span className="flex">
          <img src={fileIcon} alt="" className="mr-1" />
          History
        </span>
        <svg
          className="ml-2 w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>

      <div
        id="dropdown"
        className={`${
          !showDocumentVersions && "hidden"
        } absolute z-10 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 max-h-32 overflow-y-auto`}
      >
        {documentVersions.map((version, index) => {
          return (
            <ul aria-labelledby="dropdownButton" key={`version.file_name${index}`}>
              <li className="bg-yellow-divider border-b border-gray-300">
                <div className="block px-4 text-xxs text-gray-700 pt-1 hover:bg-gray-100 dark:hover:bg-gray-100 dark:text-gray-700">
                  <div
                    className="flex flex-col cursor-pointer"
                    onClick={() => onViewFile(version.file_key)}
                    onTouchStart={() => onViewFile(version.file_key)}
                  >
                    <div
                      className="flex justify-between"
                      style={{ marginLeft: "-3px" }}
                    >
                      <div className="flex flex-col w-11/12 text-base align-top">
                        <div className="flex text-xs">
                          <img src={fileIcon} alt="" className="w-4" />
                          {truncateString(version.title, 14)}
                        </div>
                        <div
                          className="text-base text-xxxs leading-4"
                          style={{ marginLeft: "3px" }}
                        >
                          {truncateString(version.file_name, 12, true)}
                        </div>
                      </div>
                      <img src={download} alt="" className="w-4 self-start" />
                    </div>
                    <div className="flex justify-between text-xxxs text-yellow-date">
                      <span>{formatTimestamp(version.created_at)}</span>
                      <span>{`V${documentVersionsLength - index}`}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentVersions;

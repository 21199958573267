import { useContext, useEffect, useState } from "react";
import { Permission } from "../../../types/Permission";
import { TransactionContext, UserDataContext } from "../../../utils/store";

export const useTransactionPermission = () => {
  const { transaction } = useContext(TransactionContext);
  const { userData } = useContext(UserDataContext);
  const [permission, setPermission] = useState<Permission>(Permission.VIEWER);

  useEffect(() => {
    if (!transaction || !userData) return;
    if (transaction.created_by === userData.id) {
      setPermission(Permission.ADMIN);
      return;
    }

    if (transaction.sharedWith) {
      const self = transaction.sharedWith.find((x) => x.id === userData.id);
      if (!self) return;
      if (self.permissionLevel === Permission.ADMIN.toLowerCase()) {
        setPermission(Permission.ADMIN);
      }
    }
  }, [transaction, userData]);

  return permission;
};

export enum PrivacySettingName {
  Briefcase = "briefcase",
  Permissions = "permissions",
  Invitations = "invitations"
}
export interface PrivacySetting {
  name: PrivacySettingName;
  isVisible: boolean;
  setIsVisible?: (arg0: boolean) => void;
}

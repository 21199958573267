import React, { useRef, useState } from "react";
import { DropdownPermissions, DropdownTypes } from "../../types/Invitations";
import DropdownIcon from "../Icons/DropdownIcon";
import PermissionRole from "./PermissionRole";
import redX from "../../images/red-x.svg";
import { InvitationRecipient } from "../../types/Invitations";
import { useOutsideClickToCloseModal } from "../../hooks/useOutsideClickToCloseModal";

const PermissionDropdown: React.FC<DropdownPermissions> = (props) => {
  const {
    user,
    userEmailList,
    setUserEmailList,
    rolesList,
    dropdownType,
    updatedPermission,
    setUpdatedPermission,
    isDisabled
  } = props;

  const [showPermissions, setShowPermissions] = useState(false);

  const dropdownRef = useRef<HTMLInputElement>(null);

  useOutsideClickToCloseModal(dropdownRef, setShowPermissions);

  const findUser = () => {
    if (!userEmailList) return;

    const updatedUserDataList = userEmailList.slice();
    return updatedUserDataList.find((invitee) => invitee.email === user.email);
  };

  const setUserPermission = (permission: string) => {
    const foundUser = findUser();

    if (dropdownType === DropdownTypes.EditUser) {
      setUpdatedPermission && setUpdatedPermission(permission);
    } else {
      if (!userEmailList || !setUserEmailList) return;

      const updatedUserDataList = userEmailList.slice();

      if (foundUser) {
        foundUser.permission = permission;
      }

      setUpdatedPermission && setUpdatedPermission(permission);

      setUserEmailList(updatedUserDataList);
    }
    setShowPermissions(false);
  };

  const setUserRole = (role: string) => {
    const foundUser = findUser();

    if (!foundUser) return;

    if (dropdownType === DropdownTypes.OptionalRoles) {
      foundUser.optionalRole = role;
    }

    if (dropdownType === DropdownTypes.RequiredRoles) {
      foundUser.role = role;
    }

    setShowPermissions(false);
  };

  const handleDeleteInvite = () => {
    if (!userEmailList || !setUserEmailList) return;

    const updatedUserDataList = userEmailList.slice();

    updatedUserDataList.forEach(
      (userData: InvitationRecipient, idx: number) => {
        if (userData.email === user.email) {
          updatedUserDataList.splice(idx, 1);
        }
      }
    );

    setUserEmailList(updatedUserDataList);
    setShowPermissions(false);
  };

  const getDropdownLabel = () => {
    if (updatedPermission) {
      return updatedPermission;
    } else {
      switch (dropdownType) {
        case DropdownTypes.Permissions:
        case DropdownTypes.EditUser:
          return user.permission || "Select";
        case DropdownTypes.RequiredRoles:
          return user.role || "SELECT";
        case DropdownTypes.OptionalRoles:
          return user.optionalRole || "SELECT";
      }
    }
  };

  const getStyles = () => {
    switch (dropdownType) {
      case DropdownTypes.RequiredRoles:
        return "w-28 border-lightest-gray bg-white rounded-md shadow-sm ";
      case DropdownTypes.Permissions:
        return "w-28 bg-gray-200 font-semibold rounded-md shadow-sm ";
      case DropdownTypes.EditUser:
        return "ring-1 rounded w-32 border-none h-9";
    }
  };

  const handleSetRoleOrPermission = () => {
    return [DropdownTypes.Permissions, DropdownTypes.EditUser].includes(dropdownType)
      ? setUserPermission
      : setUserRole;
  };

  return (
    <div className="relative inline-block text-left">
      <button
        type="button"
        disabled={!!isDisabled}
        className={`${getStyles()} flex justify-between px-2 items-center h-8 md:h-9 border px-1 bg-white text-xxs text-xx-neutral-gray hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-gray-100`}
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        onClick={() => setShowPermissions(!showPermissions)}
      >
        {getDropdownLabel()}
        <DropdownIcon styles="w-4" fill="#7d7d7d" />
      </button>

      <div
        ref={dropdownRef}
        className={`${!showPermissions && "hidden"} ${
          [DropdownTypes.RequiredRoles, DropdownTypes.OptionalRoles].includes(
            dropdownType
          ) && "max-h-36 overflow-y-auto"
        } text-black origin-top-right absolute right-0 mt-2 w-full text-center rounded-md shadow-lg bg-white z-50 ring-1 ring-black ring-opacity-5 focus:outline-none`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
      >
        <div className="cursor-pointer" role="none">
          {rolesList.map((role: string) => {
            return (
              <div key={role}>
                <PermissionRole
                  user={user}
                  setUserPermission={handleSetRoleOrPermission()}
                  updatedPermission={updatedPermission}
                  permission={role}
                />
              </div>
            );
          })}

          {dropdownType === DropdownTypes.Permissions && (
            <div
              className="flex justify-center items-center block px-2 py-2 text-xs focus:outline-none text-error-red hover:bg-gray-100 md:px-4"
              onClick={handleDeleteInvite}
            >
              <img
                src={redX}
                width="10"
                alt=""
                className="-ml-3 mr-1 md:mr-2"
              />
              Remove
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PermissionDropdown;

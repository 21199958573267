import React, { SyntheticEvent, useContext, useState } from "react";
import { AuthContext, UserDataContext } from "../utils/store";
import eyeClosed from "../images/eye-closed.svg";
import eyeOpen from "../images/open-eye-icon.svg";
import redExclamation from "../images/red-exclamation.svg";
import OnboardingLogo from "./Logos/OnboardingLogo";
import { Link, useNavigate } from "react-router-dom";
import pattern from "../images/yellow-pattern.svg";
import ErrorHandler from "./Errors/ErrorHandler";
import Label from "./Forms/Label";
import MobileHeader from "./Logos/MobileHeader";
import { api } from "../api";
import RedButton from "./Buttons/RedButton";
import { useRerouteValidUser } from "../hooks/useRerouteValidUser";
import { RedBtnImg } from "../types/Button";

const Login = () => {
  const { userData, setUserData } = useContext(UserDataContext);
  const { setIsAuthorized } = useContext(AuthContext);

  enum ErrorOptions {
    Password,
    Email,
    Unknown,
    Default,
  }

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [loginError, setLoginError] = useState(ErrorOptions.Default);

  useRerouteValidUser();

  const userLogin = async (e: SyntheticEvent) => {
    try {
      e.preventDefault();

      const { user } = await api.auth.login(email, password);

      setIsAuthorized(true);
      setUserData({ ...userData, id: user.id });
      navigate(`/profile/${user.id}`);
    } catch (_error) {
      const error: any = _error;

      if (error.toString().includes("password")) {
        setLoginError(ErrorOptions.Password);
      } else if (error.toString().includes("email")) {
        setLoginError(ErrorOptions.Email);
      } else {
        setLoginError(ErrorOptions.Unknown);
      }
    }
  };

  return (
    <div className="font-light h-screen mx-auto my-0 md:font-normal flex flex-col md:flex-row">
      <OnboardingLogo />
      <div className="md:w-2/3 md:overflow-auto">
        <MobileHeader />
        <div className="flex md:self-start h-auto mt-10 md:mt-0 md:h-full w-full max-w-400 md:max-w-screen-lg text-center md:text-left md:pl-16 lg:pl-28">
          <div className="my-auto h-auto flex flex-col md:justify-center w-full">
            <h2 className="md:mt-10 mb-3 text-sm-xx text-gray-400 tracking-widest font-bold">
              WELCOME TO VOLAS
            </h2>
            <h3 className="text-2xl md:text-3xl">
              The digital standard in aviation.
            </h3>
            <div className="flex justify-center md:justify-start">
              <img src={pattern} alt="" className="yellow-pattern w-60" />
            </div>
            <p className="mt-8 md:mt-16 text-xxs md:text-sm tracking-widest font-semibold">
              LOGIN
            </p>
            <form
              className="md:text-left md:mt-4 text-gray-700 flex-col"
              onChange={() => setLoginError(ErrorOptions.Default)}
              onSubmit={userLogin}
            >
              <div className="flex flex-wrap justify-center w-full md:mb-14 text-xs md:justify-start md:text-sm lg:text-xl lg:flex-col ">
                <div className="flex flex-col justify-center w-9/12 mt-8 md:mt-2">
                  <Label required={true} hidden={email} title="EMAIL" />
                  <div className="flex flex-col">
                    <div className="flex">
                      <input
                        required
                        className={`w-full md:w-96 pl-2 pb-2 bg-white outline-none text-xs md:text-sm text-left font-bold md:font-normal focus:outline-none border-b md:mx-0 placeholder-gray-300 md:placeholder-gray-700
                          ${
                            loginError === ErrorOptions.Email
                              ? "border-red-600"
                              : "border-xxx-gray"
                          }`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder="Email"
                      />
                      <img
                        src={redExclamation}
                        alt=""
                        className={`self-end w-1.25 mb-2 ${
                          loginError !== ErrorOptions.Email && "invisible"
                        }`}
                      />
                    </div>
                    <p
                      className={`${
                        loginError !== ErrorOptions.Email && "invisible"
                      } flex w-full justify-center md:justify-start text-red-600 text-xs tracking-wide mt-1 px-2`}
                    >
                      Invalid email
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-center w-9/12 mt-2">
                  <Label required={true} hidden={password} title="PASSWORD" />
                  <div className="flex flex-col">
                    <div className="flex">
                      <input
                        required
                        className={`w-full md:w-96 md:text-sm pl-2 pb-2 bg-white outline-none text-xs text-left font-bold md:font-normal focus:outline-none border-b placeholder-gray-300 md:placeholder-gray-700   ${
                          loginError === ErrorOptions.Password
                            ? "border-red-600"
                            : "border-xxx-gray"
                        }`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={!passwordVisibility ? "password" : "text"}
                        placeholder="Password"
                      />
                      <img
                        src={redExclamation}
                        alt=""
                        className={`self-end w-1.25 mb-2 ${
                          loginError !== ErrorOptions.Password && "invisible"
                        }`}
                      />
                      {password && loginError === ErrorOptions.Default && (
                        <img
                          alt=""
                          src={passwordVisibility ? eyeOpen : eyeClosed}
                          onClick={() =>
                            setPasswordVisibility(!passwordVisibility)
                          }
                          className="select-none cursor-pointer self-center mb-1 hidden md:block"
                        />
                      )}
                    </div>
                    <p
                      className={`${
                        loginError !== ErrorOptions.Password && "invisible"
                      } flex w-full justify-center md:justify-start text-red-600 text-xs tracking-wide mt-1 px-2`}
                    >
                      Incorrect password
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <RedButton
                  styles="w-183 mt-14 mb-4 md:mt-3 px-3 py-3 text-xs md:text-sm self-center md:self-start"
                  title="SIGN IN"
                  disabled={!email || !password}
                  img={RedBtnImg.Arrow}
                  type="submit"
                />
              </div>
            </form>
            <Link
              to="/forgot-password"
              className="text-volas-red text-sm md:mt-3 underline md:pb-0"
            >
              Reset Password
            </Link>
            {loginError === ErrorOptions.Unknown && (
              <ErrorHandler errorMessage="unknown error" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

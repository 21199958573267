import React, { useContext, useEffect, useState } from "react";
import { InvitationRecipient } from "../../../types/Invitations";
import { DocumentObject } from "../../../types/Document";
import volasUser from "../../../images/volas-user.svg";
import {capitalize, retrieveUserImg} from "../../../utils/helpers";
import { UserDataContext } from "../../../utils/store";

/**
 * Loads a list of users that a document has been shared with
 *
 * @param document the document that has been shared with the users
 * @returns a list of users
 */
export const useUserEmailList = (
  document: DocumentObject | null
): [
    InvitationRecipient[],
    React.Dispatch<React.SetStateAction<InvitationRecipient[]>>
  ] => {
  const { userData } = useContext(UserDataContext);
  const [userEmailList, setUserEmailList] = useState<InvitationRecipient[]>([]);

  // Prepopulates list with users that have already been invited
  useEffect(() => {
    if (!document) return;
    const sharedWith = document.shared_with;
    let sharedWithPending = document.shared_with_pending;
    if (!sharedWithPending || !sharedWith) return;

    // remove session user from shared with list
    const sessionUserIndex = sharedWith.findIndex((user) => user.id === userData.id);
    if (sessionUserIndex !== -1) {
      sharedWith.splice(sessionUserIndex, 1);
    }

    if (sharedWithPending) {
      sharedWithPending = sharedWithPending.map((pendingInvite) => {
        return {
          ...pendingInvite,
          hasPendingInvite: true
        }
      })
    }

    const _userEmailList = sharedWith.concat(sharedWithPending).map((docUser) => {
      return {
        id: docUser.id,
        email: docUser.email,
        permission: capitalize(docUser.permissionLevel),
        picture: retrieveUserImg(docUser.id) || volasUser,
        hasPendingInvite: docUser.hasPendingInvite
      };
    });
    setUserEmailList(_userEmailList);
  }, [document]);

  return [userEmailList, setUserEmailList];
};

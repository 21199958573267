import { Tag } from "../types/Tag";
import { del, get, post } from "./axios";
import { buildQueryParams } from "../utils/helpers";

/**
 * Gets all document tags by user
 *
 * @returns an array of tag objects
 */
const getTags = async (usedTagsOnly?: boolean): Promise<Tag[]> => {
  try {
    const params = buildQueryParams({}, [{ usedTagsOnly }]);
    const res = await get(`/tag?${params}`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Gets all network tags by user
 *
 * @returns an array of tag objects
 */
const getNetworkTags = async (usedTagsOnly?: boolean): Promise<Tag[]> => {
  try {
    const params = buildQueryParams({}, [{ usedTagsOnly }]);
    const res = await get(`/tag/network?${params}`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Creates a tag and adds to document
 *
 * @param name the name of the tag
 * @param documentId document id to add tag to
 * @returns the tag id
 */
const createTagAndAddToDocument = async (
  name: string,
  documentId: string
): Promise<Tag> => {
  try {
    const res = await post("/tag", { name, documentId });
    const tag = res.data;
    return tag;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Creates a network tag and adds to network tags
 *
 * @param name the name of the tag
 * @param userId the user to add a tag to
 * @returns the tag id
 */
const createTagAndAddToUser = async (
    name: string,
    userId: string
): Promise<Tag> => {
  try {
    const res = await post("/tag/network", { name, userId });
    const tag = res.data;
    return tag;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Creates a network tag and adds to user
 *
 * @param userId the user to add a tag to
 * @param tagId
 * @returns the tag id
 */
const addNetworkTag = async (userId: string, tagId: string): Promise<void> => {
  try {
    await post(`/network/${userId}/add-tag/${tagId}`);
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Removes a network tag
 *
 * @param userId
 * @param tagId
 */
const removeNetworkTag = async (userId: string, tagId: string): Promise<void> => {
  try {
    await del(`/tag/network/${userId}/remove-tag/${tagId}`);
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Deletes a document tag
 *
 * @param id the tag id
 */
const deleteTag = async (id: string): Promise<void> => {
  try {
    await del(`/tag/${id}`);
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Deletes a network tag
 *
 * @param id the tag id
 */
const deleteNetworkTag = async (id: string): Promise<void> => {
  try {
    await del(`/tag/network/${id}`);
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

export const tags = { getTags, getNetworkTags, createTagAndAddToDocument, createTagAndAddToUser, addNetworkTag, removeNetworkTag, deleteTag, deleteNetworkTag };

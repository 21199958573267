import React from "react";
import { IndexContainer } from "../UI/Containers/IndexContainer";

const TemplatesIndex = () => {
  return (
    <IndexContainer
      title="Templates"
      subTitle="Coming soon.."
    >
      <div className="flex w-full justify-between mb-4">
        Check back here soon for templates.
      </div>
    </IndexContainer>
  )
};

export default TemplatesIndex;
import React, { useEffect, useState } from "react";
import Select, { MultiValue } from "react-select";
import { DropdownTypes, InvitationTypes } from "../../types/Invitations";
import { UserRoles } from "../../types/UserRoles";
import { capitalize } from "../../utils/helpers";
import RedButton from "../Buttons/RedButton";
import PermissionDropdown from "../Invitations/PermissionDropdown";
import { useResourceRoles } from "./hooks/useResourceRoles";
import { EditUserModalProps } from "../../types/Modal";
import { ResourceRole } from "../../types/ResourceRole";

interface UserRole {
  label: string;
  value: string;
}

const EditUserModal: React.FC<EditUserModalProps> = (props) => {
  const {
    setIsOpen,
    user,
    permissionsList,
    shareType,
    onSave,
    permissionsDisabled,
  } = props;

  const resourceRoles = useResourceRoles(shareType);
  const mapRoles = (resourceRoles: ResourceRole[]): UserRole[] => {
    return resourceRoles.map((role) => {
      return { label: role.name, value: role.id };
    });
  };

  const mappedRoleOptions = mapRoles(resourceRoles).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const [filterOptions, setFilterOptions] = useState(mappedRoleOptions);
  const [userRoles, setUserRoles]: MultiValue<any> = useState([] as UserRole[]);
  const [updatedPermission, setUpdatedPermission] = useState<
    undefined | string
  >(undefined);

  useEffect(() => {
    if (user?.permissionLevel) {
      setUpdatedPermission(capitalize(user.permissionLevel));
    }
  }, [user]);

  // TODO -- this code is duplicated in the UserListItem.tsx file
  // For aircraft users, when permission is not set or set to viewer, allow the potential buyer role to be selected
  // This role is not allowed to be set with the admin permission
  useEffect(() => {
    if (shareType !== InvitationTypes.AircraftUser) {
      return;
    }

    if (updatedPermission?.toLowerCase() === 'admin') {
      const removePotentialBuyerFilter = (roles: {label: string; value: string}[]) => {
        return roles.filter(role => {
          return role.label !== 'potential buyer'
        });
      }

      setFilterOptions(removePotentialBuyerFilter(mappedRoleOptions));
      setUserRoles(removePotentialBuyerFilter(userRoles));
    } else {
      setFilterOptions(mappedRoleOptions);
    }
  }, [updatedPermission, resourceRoles])

  const selectRolesInputStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      border: "none",
    }),
    menuList: (provided: any, state: any) => {
      const maxHeight = "100px";
      const backgroundColor = "white";
      return { ...provided, maxHeight, backgroundColor };
    },
    dropdownIndicator: (provided: any, state: any) => {
      const cursor = "pointer";
      return { ...provided, cursor };
    },
  };

  const handleMultiChange = (
    option: MultiValue<{ label: string; value: string }[]>
  ) => {
    setUserRoles(option);
  };

  // TODO -- this code is duplicated in the UserListItem.tsx file
  // TODO -- potentially remove owner role if user selects potential buyer
  // TODO: move this logic to the api
  useEffect(() => {
    if (shareType !== InvitationTypes.TransactionUser) {
      return
    }
    // A user can't have both a 'Seller' and a 'Buyer' role, so this logic ensures both can't be selected
    let updatedFilteredOptions = resourceRoles;

    const sellerIndex = userRoles.findIndex(
      (role: any) => role.label === UserRoles.Seller
    );

    const buyerIndex = userRoles.findIndex(
      (role: any) => role.label === UserRoles.Buyer
    );

    const buyerAndSellerFilter = (role: string) => {
      return (
        (sellerIndex !== -1 && role === UserRoles.Buyer) ||
        (buyerIndex !== -1 && role === UserRoles.Seller)
      );
    };
    updatedFilteredOptions = updatedFilteredOptions.filter(
      (role) => !buyerAndSellerFilter(role.name)
    );

    setFilterOptions(mapRoles(updatedFilteredOptions));
  }, [userRoles, resourceRoles]);

  useEffect(() => {
    if (!user) return;
    setUpdatedPermission(capitalize(user.permissionLevel));

    switch (shareType) {
      case InvitationTypes.AircraftUser:
        setUserRoles(mapRoles(user.aircraftRoles));
        break;
      case InvitationTypes.TransactionUser:
        setUserRoles(mapRoles(user.transactionRoles));
        break;
      default:
        break;
    }
  }, [user]);

  const handleUpdateUser = async () => {
    if (!user || !updatedPermission) return;
    const ids = userRoles.map((x: any) => {
      if (x.value) {
        return x.value;
      }
      throw Error("user roles has an undefined value");
    });
    if (onSave) {
      onSave(ids, updatedPermission);
    }
  };

  if (!user) return <div></div>;

  return (
    <div className="flex flex-col text-center text-xxx-gray justify-center w-full md:w-336 h-312">
      <h2 className="text-2xl text-gray-700 tracking-wide mb-5">
        {`Edit user roles${!permissionsDisabled ? " or permissions" : ""}.`}
      </h2>
      <div className="flex items-center justify-center w-full">
        {!permissionsDisabled && (
          <>
            <h2 className="mr-4">Permission:</h2>
            <PermissionDropdown
              user={user}
              dropdownType={DropdownTypes.EditUser}
              rolesList={permissionsList}
              updatedPermission={updatedPermission}
              setUpdatedPermission={setUpdatedPermission}
            />
          </>
        )}
      </div>
      <div className="flex items-center justify-center w-full">
        <h2 className="mr-4">Roles:</h2>
        <Select
          className="w-2/3 text-left text-xs ring-1 mt-2 rounded"
          name="filters"
          placeholder="Edit roles"
          value={userRoles}
          options={filterOptions}
          onChange={handleMultiChange}
          styles={selectRolesInputStyles}
          isMulti
        />
      </div>
      <RedButton
        onClickEvent={() => {
          handleUpdateUser();
          setIsOpen(false);
        }}
        styles="w-44 px-3 py-3 my-5 text-xs self-center"
        title="SAVE"
        disabled={!userRoles.length}
      />
    </div>
  );
};

export default EditUserModal;

import { useContext } from "react";
import { UserDataContext } from "../../utils/store";
import {Link} from "react-router-dom";

export const AuthHeader = () => {
  const { userData } = useContext(UserDataContext);

  if (!userData?.id) {
    return <></>
  }

  return (
    <div className="absolute z-10 text-volas-red text-xxs left-0 right-0 m-auto text-center mt-2 md:right-0 md:left-auto md:mr-10 md:mt-4">
      <Link to={`/profile/${userData.id}`} ><span className="text-welcome-gray">Logged in as</span> {userData.email}</Link>
    </div>
  )
}
import React from "react";
import logo from "../../images/volas-logo-vertical.svg";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="bg-gray-800 fixed top-0 left-0 z-50 h-screen w-screen flex flex-col justify-evenly md:justify-center no-scrollbar">
      <img src={logo} alt="volas-logo" className="w-44 md:w-52 self-center" />
      <h1 className="self-center text-white text-3xl md:text-5xl md:mt-32">
        Page Not Found
      </h1>
      <h4 className="self-center underline text-white text-xl mt-4">
        <Link to={"/"} >
          Click here to go back.
        </Link>
      </h4>
    </div>
  );
};

export default PageNotFound;

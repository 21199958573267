import React, { useContext } from "react";
import logo from "../../images/logo.svg";
import { useLocation } from "react-router-dom";
import NavItem from "./NavItem";
import AircraftIcon from "../Icons/AircraftIcon";
import TransactionIcon from "../Icons/TransactionIcon";
import ProfileIcon from "../Icons/ProfileIcon";
import DocumentIcon from "../Icons/DocumentIcon";
import { UserDataContext } from "../../utils/store";

const NavMenu = () => {
  const styles = "md:ml-4 md:mr-2 w-6 mb-2 md:my-0";
  const defaultColor = "#FDFDFD";
  const activeColor = "#ff0000";

  const { pathname } = useLocation();
  const { userData } = useContext(UserDataContext);

  const iconColor = (url: string) =>
    url === pathname.split("/")[1] ? activeColor : defaultColor;

  return (
    <div className="fixed bottom-0 mb-auto z-50 flex h-20 w-full md:h-full md:w-44 bg-xxx-gray">
      <div className="flex justify-evenly self-start w-full md:self-start md:block">
        <img
          src={logo}
          alt="volas-logo"
          className="hidden md:block w-9 ml-4 my-6"
        />
        <div className="nav-menu-divider" />
        <NavItem
          url={`/profile/${userData.id}`}
          title="PROFILE"
          icon={<ProfileIcon fill={iconColor("profile")} styles={styles} />}
        />
        <div className="nav-menu-divider" />
        <NavItem
          url="/aircraft"
          title="AIRCRAFT"
          icon={<AircraftIcon fill={iconColor("aircraft")} styles={styles} />}
        />
        <div className="nav-menu-divider" />
        <NavItem
          url="/transactions"
          title="TRANSACTIONS"
          icon={
            <TransactionIcon fill={iconColor("transactions")} styles={styles} />
          }
        />
        <div className="nav-menu-divider" />
        <NavItem
          url="/documents"
          title="PAPERWORK"
          icon={<DocumentIcon fill={iconColor("documents")} styles={styles} />}
        />
        <div className="nav-menu-divider" />
        {
          userData.is_admin &&
          <NavItem
            url="/network/my-network"
            title="NETWORK"
            icon={<ProfileIcon fill={iconColor("network")} styles={styles} />}
          />
        }
        <div className="nav-menu-divider" />
        {
          userData.is_admin &&
          <NavItem
            url="/admin/invite"
            title="ADMIN"
            icon={<ProfileIcon fill={iconColor("admin")} styles={styles} />}
          />
        }
        <div className="nav-menu-divider" />
      </div>
    </div>
  );
};

export default NavMenu;

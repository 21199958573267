export enum DocumentResourceType {
  Profile = "profile",
  Aircraft = "aircraft",
  Transaction = "transaction",
  Application = "application"
}

export enum UserResourceType {
  Document = "document",
  Aircraft = "aircraft",
  Transaction = "transaction",
  Application = "application"
}
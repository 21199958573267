import { AircraftObject } from "../types/Aircraft";
import { DocumentObject } from "../types/Document";
import { del, get, post, put } from "./axios";
import { resizeFile } from "../utils/helpers";

/**
 * Get all aircrafts
 *
 * @returns an array of aircrafts
 */
const getAircrafts = async (): Promise<AircraftObject[]> => {
  try {
    const res = await get("/aircraft");
    const aircrafts = res.data;
    return aircrafts;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Get a single aircraft
 *
 * @returns a single aircraft
 */
const getAircraft = async (aircraftId: string): Promise<AircraftObject> => {
  try {
    const res = await get(`/aircraft/${aircraftId}`);
    const aircraft = res.data;
    return aircraft;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Creates a new aircraft
 *
 * @param title
 * @param type
 * @param base
 * @param tailNumber
 * @param serialNumber
 * @param files
 * @param addAsOwner
 * @returns the new aircraft
 */
const createAircraft = async (
  title: string,
  type: string,
  base: string,
  tailNumber: string,
  serialNumber: string,
  files?: any,
  addAsOwner?: string
): Promise<AircraftObject> => {
  try {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("type", type);
    formData.append("base", base);
    formData.append("tailNumber", tailNumber);
    formData.append("serialNumber", serialNumber);

    if (files) {
      const resizedFile = await resizeFile(files)
      formData.append("file", resizedFile);
    }
    if (addAsOwner) {
      formData.append("addAsOwner", addAsOwner);
    }
    const res = await post("/aircraft", formData);
    const aircraft = res.data;
    return aircraft;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Updates an aircraft
 *
 * @param id
 * @param updateBody
 * @returns the updated aircraft
 */
const updateAircraft = async (
  id: string,
  updateBody: {
    title: string;
    type: string;
    base: string;
    tailNumber: string;
    serialNumber: string;
    files?: any;
  }
): Promise<AircraftObject> => {
  const formData = new FormData();
  formData.append("title", updateBody.title);
  formData.append("type", updateBody.type);
  formData.append("base", updateBody.base);
  formData.append("tailNumber", updateBody.tailNumber);
  formData.append("serialNumber", updateBody.serialNumber);

  if (updateBody.files) {
    const resizedFile = await resizeFile(updateBody.files)
    formData.append("file", resizedFile);
  }

  try {
    const res = await put(`/aircraft/${id}`, formData);
    const aircraft = res.data;
    return aircraft;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Get all aircraft documents by aircraft id
 *
 * @param aircraftId the id of the aircraft
 * @returns aircraft documents
 */
const getAircraftDocuments = async (
  aircraftId: string
): Promise<DocumentObject[]> => {
  try {
    const res = await get(`/aircraft/${aircraftId}/documents`);
    const documents = res.data;
    return documents;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Removes a user from an aircraft
 */
const removeAircraftUser = async (
  aircraftId: string,
  email: string
): Promise<boolean> => {
  try {
    await del(`/aircraft/${aircraftId}/user?email=${email}`);
    return true;
  } catch (_error) {
    const error: any = _error;
    throw new Error(error);
  }
};

/**
 * Updates a user's roles and permission on an aircraft regardless if they have accepted an
 * invitation or not.
 *
 * @param aircraftId the aircraft id
 * @param email the user's email
 * @param roleIds the role ids to replace the user's current roles
 * @param permissionLevel
 */
const updateAircraftUser = async (
  aircraftId: string,
  email: string,
  roleIds: string[],
  permissionLevel: string
): Promise<void> => {
  try {
    await put(`/aircraft/${aircraftId}/update-aircraft-user?email=${email}`, {
      roleIds,
      permissionLevel: permissionLevel.toLowerCase(),
    });
  } catch (_error) {
    const error: any = _error;
    throw new Error(error);
  }
};

export const aircraft = {
  getAircrafts,
  getAircraft,
  createAircraft,
  updateAircraft,
  getAircraftDocuments,
  removeAircraftUser,
  updateAircraftUser,
};

import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useReducer,
} from "react";
import pattern from "../../images/yellow-pattern.svg";
import eyeClosed from "../../images/eye-closed.svg";
import eyeOpen from "../../images/open-eye-icon.svg";
import greenCheck from "../../images/checkmark.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext, UserDataContext } from "../../utils/store";
import { ErrorOptions, Payload } from "../../types/Payload";
import Label from "../Forms/Label";
import { api } from "../../api";
import RedButton from "../Buttons/RedButton";
import { useRerouteValidUser } from "../../hooks/useRerouteValidUser";
import redExclamation from "../../images/red-exclamation.svg";
import { RedBtnImg } from "../../types/Button";

const CreateAccount = () => {
  const navigate = useNavigate();

  const location = useLocation();

  // TODO -- determine why location.state is throwing an error without ts-ignore
  // @ts-ignore
  const { tokenVerified, token } = location.state || {};

  const { userData, setUserData } = useContext(UserDataContext);
  const { setIsAuthorized } = useContext(AuthContext);

  useRerouteValidUser();

  useEffect(() => {
    if (!tokenVerified) {
      navigate("/");
    }
  }, [tokenVerified, navigate]);

  const initialState = {
    name: "",
    phone: "",
    password: "",
    repeatPassword: "",
    passwordVisibility: false,
    repeatPasswordVisibility: false,
    userRegistered: false,
    errorMessage: ErrorOptions.Default,
  };

  const reducer = (state = { ...initialState }, { name, value }: Payload) => {
    return {
      ...state,
      [name]: value,
    };
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ name, value });
  };

  const [state, dispatch] = useReducer(reducer,{ ...initialState });
  // This is dirty -- adding to make sure initial state for these fields is blank.
  useEffect(() => {
    setTimeout(() => {
      dispatch({name: "phone", value: ""});
      dispatch({name: "password", value: ""});
    }, 800)
  }, []);

  const passwordsMatch = () => state.password === state.repeatPassword;

  const formInvalid = () => {
    const requiredFields = [
      state.password,
      state.repeatPassword,
      state.name,
      state.phone,
    ];

    return requiredFields.some((e) => !e) || !passwordsMatch();
  };

  const createUserAccount = async (e: SyntheticEvent) => {
    try {
      e.preventDefault();

      const userRegisterData = {
        password: state.password,
        passwordConfirm: state.repeatPassword,
        phone: state.phone,
        name: state.name,
      };

      const { name, password, repeatPassword, phone } = state;
      const { user, tokens, resource } = await api.auth.register(
        name,
        password,
        repeatPassword,
        phone,
        token
      );

      setIsAuthorized(true);

      setUserData({
        ...userData,
        id: user.id,
        access_token: tokens.access.token,
        preventNavigationOnUpdate: true,
      });

      navigate(`/onboard/experience?resourceStr=${resource ? JSON.stringify(resource) : undefined}`, {
        state: userRegisterData.name,
      });

      dispatch({ name: "userRegistered", value: true });
    } catch (_error) {
      console.error("error", _error);

      let error: any = _error;
      error = error.toString();
      let errorLocation;

      if (error.includes("name")) {
        errorLocation = ErrorOptions.Name;
      } else if (error.includes("phone")) {
        errorLocation = ErrorOptions.Phone;
      } else if (error.includes("password")) {
        errorLocation = ErrorOptions.Password;
      } else {
        errorLocation = ErrorOptions.Unknown;
      }

      dispatch({
        name: "errorMessage",
        value: errorLocation,
      });
    }
  };

  const retrieveBorderColor = (data: FormData) => {
    return state.errorMessage === data.errorEnum ||
      (data.input === "repeatPassword" &&
        state.repeatPassword &&
        !passwordsMatch())
      ? "border-error-red"
      : state.repeatPassword &&
        passwordsMatch() &&
        data.input === "repeatPassword" &&
        state.errorMessage === ErrorOptions.Default
        ? "border-input-green"
        : "border-xxx-gray";
  };

  interface FormData {
    input: string;
    title: string;
    text: string;
    errorEnum: ErrorOptions;
    passwordVisibility: boolean | null;
    errorMessage: string;
    type: string;
  }

  const formInputData = [
    {
      input: "name",
      title: state.name,
      text: "FULL NAME",
      errorEnum: ErrorOptions.Name,
      passwordVisibility: null,
      errorMessage: "name is invalid",
      type: "text"
    },
    {
      input: "phone",
      title: state.phone,
      text: "PHONE NUMBER",
      errorEnum: ErrorOptions.Phone,
      passwordVisibility: null,
      errorMessage: "phone number is invalid",
      type: "tel"
    },
    {
      input: "password",
      title: state.password,
      text: "PASSWORD",
      errorEnum: ErrorOptions.Password,
      passwordVisibility: state.passwordVisibility,
      errorMessage: "password must be at least 8 characters",
      type: "password"
    },
    {
      input: "repeatPassword",
      title: state.repeatPassword,
      text: "CONFIRM PASSWORD",
      errorEnum: ErrorOptions.Password,
      passwordVisibility: state.repeatPasswordVisibility,
      errorMessage: "password must be at least 8 characters",
      type: "password"
    },
  ];

  return (
    <div className="flex flex-col text-center h-screen md:w-2/3 md:text-left md:pt-12 md:pl-16 lg:pl-28 overflow-auto">
      <div className="flex flex-col my-auto h-full md:h-auto w-10/12 self-center md:self-start">
        <div className="flex justify-center md:justify-start text-xs md:text-sm mt-10 md:mt-0">
          <p>Already have an acccount?</p>
          <Link className="ml-1 text-volas-red active:text-red-700" to="/login">
            Return to login.
          </Link>
        </div>
        <p className="mobile-steps">CREATE ACCOUNT 1/2</p>
        <h1 className="text-xxl md:text-xxxl text-xxx-gray">
          Your Information
        </h1>
        <div className="flex justify-center md:justify-start mt-4 md:mt-6">
          <img src={pattern} alt="" className="self-center w-64 md:w-72" />
        </div>
        <form
          className="md:text-left text-gray-700 flex-col"
          onChange={() =>
            state.errorMessage !== ErrorOptions.Default &&
            dispatch({
              name: "errorMessage",
              value: ErrorOptions.Default,
            })
          }
          onSubmit={(e) => createUserAccount(e)}
        >
          <div className="flex flex-col justify-center md:justify-start mb-10 mt-10 md:mt-8 text-xs md:text-sm lg:text-xl w-full">
            {formInputData.map((data) => {
              return (
                <div key={data.input} className="flex flex-col justify-center w-full md:w-96">
                  <Label
                    hidden={data.title}
                    title={data.text}
                    required={true}
                  />
                  <div className="flex flex-col">
                    <div className="flex">
                      <input
                        autoFocus={data.input === "name" && true}
                        required
                        className={`input ${retrieveBorderColor(data)}`}
                        value={data.title}
                        name={data.input}
                        onChange={onChange}
                        type={data.passwordVisibility === true ? "text" : data.type}
                        placeholder={data.text}
                      />
                      <img
                        src={redExclamation}
                        alt=""
                        className={`self-end w-1.25 mb-2 ${state.errorMessage !== data.errorEnum && "invisible"
                          }`}
                      />
                      <p
                        className={`self-center text-red-600 ${data.title && "invisible"
                          }`}
                      >
                        *
                      </p>
                      {data.text.includes("PASSWORD") &&
                        state.errorMessage === ErrorOptions.Default && (
                          <img
                            alt=""
                            src={!data.passwordVisibility ? eyeOpen : eyeClosed}
                            onClick={() =>
                              dispatch({
                                name:
                                  data.input === "repeatPassword"
                                    ? "repeatPasswordVisibility"
                                    : "passwordVisibility",
                                value: !data.passwordVisibility,
                              })
                            }
                            className={`hidden md:block self-center md:-mr-5 mb-2 select-none cursor-pointer ${!data.title && "invisible"
                              }`}
                          />
                        )}
                      {data.input === "repeatPassword" &&
                        state.errorMessage === ErrorOptions.Default && (
                          <img
                            alt=""
                            src={greenCheck}
                            className={`${state.repeatPassword && passwordsMatch()
                              ? "visible"
                              : "invisible"
                              } md:hidden self-center pb-2 h-full select-none text-input-green -mr-4 md:mr-0`}
                          />
                        )}
                    </div>
                    {data.input !== "repeatPassword" && (
                      <p
                        className={`${state.errorMessage !== data.errorEnum && "invisible"
                          } flex w-full justify-center text-sm mt-.5 md:mt-1 text-red-600 text-xxs md:text-xs tracking-wider px-2`}
                      >
                        {data.errorMessage}
                      </p>
                    )}
                  </div>
                  {data.input === "repeatPassword" &&
                    state.repeatPassword &&
                    state.errorMessage === ErrorOptions.Default && (
                      <p
                        className={`flex w-full text-sm md:pr-10 justify-center ${passwordsMatch() ? "text-input-green" : "text-red-400"
                          }`}
                      >
                        {passwordsMatch()
                          ? "Looks good"
                          : "Passwords don't match"}
                      </p>
                    )}
                </div>
              );
            })}
          </div>
          <div className="flex flex-col md:flex-row md:justify-left w-full mb-0 ">
            <RedButton
              onClickEvent={createUserAccount}
              styles="self-center w-44 mt-4 md:mb-10 md:mt-3 px-3 py-3 text-xs"
              title="NEXT"
              disabled={formInvalid()}
              img={RedBtnImg.Arrow}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAccount;

import React, { useEffect, useState } from "react";
import pattern from "../../images/yellow-pattern.svg";
import vector from "../../images/arrow-vector.svg";
import logo from "../../images/volas-logo-vertical.svg";
import { Link, useNavigate } from "react-router-dom";
import ErrorHandler from "../Errors/ErrorHandler";
import { api } from "../../api";
import RedButton from "../Buttons/RedButton";
import { RedBtnImg } from "../../types/Button";

const Welcome = () => {
  const token = new URLSearchParams(window.location.search).get("token");
  const navigate = useNavigate();

  const [tokenVerified, setTokenVerified] = useState(false);
  const [showTokenError, setShowTokenError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (token) {
          const user = await api.invitations.validateInviteToken(token);
          // If we have a user, then we want to skip onboarding
          // and redirect to the acceptance page
          if (user) {
            navigate(`/invitations/accept/${token}`)
          }

          setTokenVerified(true);
        }
      } catch (_error) {
        const error: any = _error;
        setShowTokenError(true);
        console.error(error);
      }
    })();
  }, [token]);

  const volasAccessList = [
    { id: "1", value: "Customizeable aircraft profiles" },
    { id: "2", value: "A network of aviation professionals" },
    { id: "3", value: "Convenient, organized document storage" },
    {
      id: "4",
      value: "Streamlined transaction room for buying and selling aircraft",
    },
  ];

  return (
    <div className="flex flex-col bg-logo-grey text-white md:text-black md:bg-white text-center h-screen md:w-2/3 md:text-left md:pl-16 lg:pl-28 overflow-hidden md:overflow-auto">
      <div className="flex flex-col justify-between my-auto h-full md:h-auto">
        <div className="pt-28 flex flex-col">
          <img
            alt=""
            src={logo}
            className="self-center w-1/3 h-auto my-10 md:hidden"
          />
          <h1 className="text-4xl font-light md:font-normal lg:text-5xl my-3">
            Welcome to Volas
          </h1>
          <h3 className="font-light md:font-normal md:mb-0 text-xl md:text-base lg:text-xl">
            The New Standard in Aviation
          </h3>
          <div className="flex md:justify-start md:mb-10">
            <img
              src={pattern}
              alt=""
              className="yellow-pattern hidden md:block"
            />
          </div>
          {showTokenError ? (
              <div className="py-0">
                <ErrorHandler errorMessage="This invitation is no longer valid" />
              </div>
          ) : (
              <>
                <h2 className="text-sm md:text-lg hidden md:block lg:text-2xl mb-8 mt-8 lg:mt-0">
                  By joining Volas, you will have access to:
                </h2>
                <div className="hidden md:block">
                  {volasAccessList.map((item) => {
                    return (
                        <div
                            className="flex text-left py-2 w-3/4 ml-14 md:ml-0 md:w-full"
                            key={item.id}
                        >
                          <img src={vector} alt="" className="mr-4 mt-1 md:mt-2" />
                          <p className="text-sm md:text-base lg:text-lg">
                            {item.value}
                          </p>
                        </div>
                    );
                  })}
                </div>
              </>
          )}
        </div>
        <div>
          <Link
            to="/onboard/register"
            state={{ tokenVerified, token }}
            className="self-center flex justify-center md:justify-start"
          >
            <RedButton
              styles="self-center w-44 mt-3 mb-30 md:mb-8 md:mt-28 px-3 py-3 text-xs"
              title="GET STARTED"
              disabled={!tokenVerified}
              img={RedBtnImg.Arrow}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Welcome;

import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { api } from "../../api";
import PageNotFound from "../../components/Errors/PageNotFound";
import Loader from "../../components/Loader";
import DocumentsIndex from "../../components/Paperwork/PaperworkIndex";
import PaperworkWrapper from "../../components/Paperwork/PaperworkWrapper";
import { createPaperworkInstance, Paperwork, PaperworkResponse } from '../../models/Paperwork';
import { DocumentContext } from "../../utils/store";
import TemplatesIndex from "../../components/Paperwork/TemplatesIndex";

const PaperworkPage = () => {
  const [documents, setDocuments] = useState<Map<string, PaperworkResponse>>(new Map());

  /**
   * retrieves user documents from database
   * includes owned and shared documents
   * includes aircraft, transaction, profile documents
   */
  const retrieveUserDocuments = useCallback(async () => {
    try {
      const documentMap = new Map(documents);
      const documentsData = await api.documents.getDocumentsAndVersions();
      documentsData.forEach((paperworkResponse: PaperworkResponse) => {
        documentMap.set(paperworkResponse.id, paperworkResponse);
      });
      setDocuments(documentMap);
    } catch (error) {
      // @todo alert user to error
      console.error(error);
    }
  }, []);

  useEffect(() => {
    retrieveUserDocuments();
  }, []);

  if (!documents) {
    return <Loader />;
  }

  return (
    <DocumentContext.Provider value={{ documents, setDocuments }}>
      <Routes>
        <Route element={<PaperworkWrapper />}>
          <Route path="/" element={<DocumentsIndex retrieveUserDocuments={retrieveUserDocuments}/>} />
          <Route path="/templates" element={<TemplatesIndex />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </DocumentContext.Provider>
  );
};

export default PaperworkPage;

import addIcon from "../../images/add-icon-black.svg";
import React from "react";
import Tooltip from "rc-tooltip";
import cx from "classnames";

export const AddResourceButton: React.FC<{
  onClickEvent: () => void;
  title: string;
  styles: string;
  prefix?: string;
  disabledTooltip?: string;
}> = (props) => {
  const {onClickEvent, title, styles, prefix = "ADD", disabledTooltip} = props;

  const addButton = () => {
    return (
      <button
        className={
          cx(
            `${styles} flex justify-center md:self-end bg-white font-semibold tracking-wider rounded text-sm hover:bg-gray-100 cursor-pointer`,
            { "bg-gray-300 text-gray-500 hover:bg-gray-300": disabledTooltip },
            { "ring-1 ring-red-400": !disabledTooltip }
          )
        }
        type="button"
        onClick={disabledTooltip ? () => {
        } : onClickEvent}
      >
        <img src={addIcon} alt="" className="w-1.4 self-center mr-1 py-2"/>
        <p className="self-center text-sm">{`${prefix} ${title.toUpperCase()}`}</p>
      </button>
    )
  }

  if(disabledTooltip) {
    return (
      <Tooltip
        overlay={disabledTooltip}
        placement="left"
      >
        {addButton()}
      </Tooltip>
    )
  } else {
    return (
      <>
        {addButton()}
      </>
    )
  }
};

import React from "react";
import { BadgeProps } from "../../types/Transaction";
import CheckSuccess from "../Icons/CheckSuccess";

const Badges: React.FC<BadgeProps> = (props) => {
  const { badges, completedBadges  } = props;
  return (
    <div className="grid gap-2 md:grid-cols-2 lg:grid-cols-3 mx-3 md:mx-0">
      {badges.map((badge) => {
        return (
          <div
            key={badge}
            className={`${
              completedBadges.includes(badge)
                ? "border-xx-green text-xx-green bg-light-green"
                : "border-x-neutral-gray text-welcome-gray"
            } flex items-center w-auto mr-4 px-3 md:px-5 py-1 text-xs md:text-sm border-2 rounded`}
          >
            <CheckSuccess
              fill={completedBadges.includes(badge) ? "#255201" : "#8C8C8C"}
              styles="mr-2 self-center"
            />
            <p>{badge}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Badges;

import React, {
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Outlet, useParams } from "react-router-dom";
import { retrieveTabsFor } from "../../data/tabs";
import { TabContainer } from "../UI/Containers/TabContainer";
import { TabPageHeader } from "../UI/TabHeader";
import Tabs, { TabsPage } from "../UI/Tabs";
import { PageHeader } from "../../types/UI";
import { TransactionContext } from "../../utils/store";
import { api } from "../../api";
import { TransactionObject } from "../../types/Transaction";

const TransactionsWrapper = () => {
  const { transaction, setTransaction } = useContext(TransactionContext);
  const { transactionId } = useParams();

  const getTransaction = useCallback(async () => {
    try {
      if (transactionId) {
        const transaction = await api.transactions.getTransaction(
          transactionId
        );
        setTransaction(transaction);
      }
    } catch (error) {
      console.error(error);
    }
  }, [transactionId]);

  useEffect(() => {
    getTransaction();

    // Poll transaction data every 30 seconds
    const interval = setInterval(() => {
      getTransaction();
    }, 30000)

    return () => {
      setTransaction({} as TransactionObject);
      clearInterval(interval);
    };
  }, [getTransaction]);

  const _pageHeader = useMemo(
    () =>
      transactionId && transaction.aircraft_title
        ? `Aircraft > ${transaction.aircraft_title}`
        : transactionId
        ? ""
        : PageHeader.Transactions,
    [transactionId, transaction]
  );

  return (
    <TabContainer
      header={<TabPageHeader pageTitle={_pageHeader} />}
      tabs={<Tabs tabsList={retrieveTabsFor(TabsPage.Transactions)} />}
    >
      <Outlet />
    </TabContainer>
  );
};

export default TransactionsWrapper;

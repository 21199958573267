import React from "react";
import { NavIcon } from "../../types/Icons";

const DocumentIcon: React.FC<NavIcon> = (props) => {
  const { fill, styles } = props;
  return (
    <svg
      className={styles}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.057 3H5.722C4.775 3 4 3.736 4 4.636v11.455h1.722V4.636h10.335V3zm2.584 3.273H9.167c-.947 0-1.722.736-1.722 1.636v11.455c0 .9.775 1.636 1.722 1.636h9.474c.948 0 1.723-.736 1.723-1.636V7.909c0-.9-.776-1.636-1.723-1.636zm0 13.09H9.167V7.91h9.474v11.455z"
        fill={fill}
      />
      <path fill={fill} d="M8.092 7.092h11.454v13.091H8.092z" />
    </svg>
  );
};

export default DocumentIcon;

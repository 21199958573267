import React, { useContext, useEffect, useState } from "react";
import { api } from "../../../api";
import { AircraftObject } from "../../../types/Aircraft";
import { IndexContainer } from "../../UI/Containers/IndexContainer";
import { LoadingContext } from "../../../utils/store";
import { TransactionObject } from "../../../types/Transaction";
import { ResourceContainer } from "../../UI/Containers/ResourceContainer";
import { useNavigate } from "react-router-dom";
import { truncateString } from "../../../utils/helpers";
import Tag from "../../UI/Tag";
import { Role } from "../../UI/Role";

const Permissions = () => {
  const [aircrafts, setAircrafts] = useState<AircraftObject[]>([]);
  const [transactions, setTransactions] = useState<TransactionObject[]>([]);
  const { showLoading, setShowLoading } = useContext(LoadingContext);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setShowLoading(true);

      try {
        const _aircrafts = await api.users.getUserAircraftRolesAndPermissions();
        setAircrafts(_aircrafts);

        const _transactions = await api.users.getUserTransactionRolesAndPermissions();
        setTransactions(_transactions);
      } catch (error) {
        console.error(error);
      } finally {
        setShowLoading(false);
      }
    })();
  }, []);

  const rolesComponent = (roles: (string | null)[] | undefined, permission: string | undefined) => {
    return (
      <div className="flex self-start pt-2 md:items-center md:justify-self-start">
        <Tag
          title={permission || "admin"}
          styles="ring-1 ring-volas-red text-volas-red mr-4"
        />
        {roles?.filter(role => role).map((role) => (
          <Role name={role!} key={role!} />
        ))}
      </div>
    )
  }

  if (showLoading) {
    return <></>
  }

  return (
    <>
      <IndexContainer
        title="Aircraft Permissions"
        subTitle="List of your aircraft and associated permissions and roles."
      >
        {
          aircrafts.map((aircraft) => {
            return (
              <ResourceContainer
                key={aircraft.id}
                title={truncateString(aircraft.title, 18)}
                titleExtension={aircraft.tail_number}
                timestamp={aircraft.created_at}
                onClick={() => navigate(`/aircraft/${aircraft.id}`)}
                rolesComponent={
                  rolesComponent(aircraft.roles, aircraft.permission_level)
                }
              />
            )
          })
        }
      </IndexContainer>

      <IndexContainer
        title="Transaction Permissions"
        subTitle="List of your transactions and associated permissions and roles."
        classList="-mt-48 md:-mt-16"
      >
        {
          transactions.map((transaction) => {
            return (
              <ResourceContainer
                key={transaction.id}
                title={`Transaction on ${truncateString(transaction.aircraft_title!, 18)}`}
                timestamp={transaction.created_at}
                onClick={() => navigate(`/transactions/${transaction.id}`)}
                rolesComponent={
                  rolesComponent(transaction.roles, transaction.permission_level)
                }
              />
            )
          })
        }
      </IndexContainer>
    </>
  );
};

export default Permissions;

import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../../components/Errors/PageNotFound";
import TransactionsIndex from "../../components/Transactions/TransactionsIndex";
import { TransactionObject, TxsDisplayType } from "../../types/Transaction";
import { TransactionContext } from "../../utils/store";
import TransactionsWrapper from "../../components/Transactions/TransactionsWrapper";
import TransactionPage from "../../components/Transactions/TransactionPage";
import { DocumentObject } from "../../types/Document";

const TransactionsPage = () => {
  const [transaction, setTransaction] = useState<TransactionObject>(
    {} as TransactionObject
  );

  const [requiredDocuments, setRequiredDocuments] = useState<DocumentObject[]>(
    []
  );

  return (
    <TransactionContext.Provider value={{transaction, setTransaction, requiredDocuments, setRequiredDocuments}}>
      <Routes>
        <Route element={<TransactionsWrapper/>}>
          <Route
            path="/"
            element={
              <TransactionsIndex txHistoryType={TxsDisplayType.User}/>
            }
          />
          <Route path="/:transactionId" element={<TransactionPage/>}/>
          <Route path="*" element={<PageNotFound/>}/>
        </Route>
      </Routes>
    </TransactionContext.Provider>
  );
};

export default TransactionsPage;

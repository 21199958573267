import React, { useContext } from "react";
import { RequiredForms, RequiredTxForms } from "../../types/Transaction";
import { TransactionContext, UserDataContext } from "../../utils/store";
import cx from "classnames";
import CheckBox from "../UI/CheckBox";
import { api } from "../../api";

interface TransactionFormProps {
  requiredForms: RequiredTxForms[];
}

const TransactionForms = ({ requiredForms }: TransactionFormProps) => {
  const { transaction, setTransaction } = useContext(TransactionContext);

  const isReadOnly = transaction.is_complete;

  const handleApproval = async (approvalTitle: string) => {
    // do nothing if the form is disabled
    const form = requiredForms.find((x) => x.title === approvalTitle);
    if (form && form.disabled) return;

    switch (approvalTitle) {
      case RequiredForms.Deposit: {
        try {
          await api.transactions.updateTransaction(transaction.id, {
            isDepositState: !transaction.is_deposit_state,
          });
        } catch (error) {
          console.error(error);
        }
        break;
      }
      case RequiredForms.Disbursement: {
        try {
          await api.transactions.updateTransaction(transaction.id, {
            isDisbursementFunds: !transaction.is_disbursement_funds,
          });
        } catch (error) {
          console.error(error);
        }
        break;
      }
      case RequiredForms.Seller: {
        try {
          await api.transactions.updateTransaction(transaction.id, {
            isSellerApproval: !transaction.is_seller_approval,
          });
        } catch (error) {
          console.error(error);
        }
        break;
      }
      case RequiredForms.Faa: {
        try {
          await api.transactions.updateTransaction(transaction.id, {
            isFormsFilled: !transaction.is_forms_filled,
          });
        } catch (error) {
          console.error(error);
        }
        break;
      }
      case RequiredForms.Funds: {
        try {
          await api.transactions.updateTransaction(transaction.id, {
            isFundsReceived: !transaction.is_funds_received,
          });
        } catch (error) {
          console.error(error);
        }
        break;
      }
    }

    // Yeah this is an extra step but the getTransaciton endpoint returns data
    // that the updateTransaction endpoint doesn't. This is just easier for now.
    const updatedTransaction = await api.transactions.getTransaction(
      transaction.id
    );
    setTransaction(updatedTransaction);
  };

  return (
    <div className="flex flex-wrap md:max-w-865 mt-5 md:md-0">
      {requiredForms.map((form) => {
        return (
          <div
            key={form.id}
            className={cx(
              "flex h-auto md:h-16 bg-x-gray border w-full md:max-w-max rounded mx-2 my-1 md:my-4 py-4 md:py-0",
              { "opacity-50 pointer-events-none": form.disabled || isReadOnly }
            )}
          >
            <div
              className={cx("w-16 border-r flex justify-center items-center", {
                "cursor-pointer": !isReadOnly,
              })}
              onClick={() => (!isReadOnly ? handleApproval(form.title) : null)}
            >
              <CheckBox isChecked={form.complete} />
            </div>
            <div className="flex flex-col md:flex-row items-center">
              <div className="flex flex-col text-left justify-center px-4">
                <div className="flex">
                  <h1 className="tracking-widest text-sm-x font-semibold">
                    {form.title.toUpperCase()}
                  </h1>
                  <p className="text-volas-red ml-1">*</p>
                </div>
                <h3 className="text-xs text-logo-grey">{form.subTitle}</h3>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TransactionForms;

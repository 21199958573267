import validator from "validator";
import { api } from "../api";
import Resizer from "react-image-file-resizer";
import { UserObject } from "../types/User";
import { useEffect, useState } from "react";
import { InvitedResource } from "../api/auth";
import { UserResourceType } from "../types/Resource";

export const viewResource = (url: string): void => {
  const link = document.createElement("a");
  console.log("viewing doc:", url);
  link.setAttribute("href", url);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  setTimeout(() => {
    link.click();
    link.remove();
  }, 70);
};

export const formatTimestamp = (date?: string): string => {
  if (!date) {
    return ''
  }

  // Handle invited prefix case
  let prefix = 'Added '
  if (date.startsWith('Invited')) {
    date = date.substring(11)
    prefix = 'Invited on '
  }

  let formattedDate = new Date(date).toDateString().toString()

  // Handle case where timestamp isn't a timestamp and just a string (hacky)
  if (formattedDate.toLowerCase().includes('invalid')) {
    return date
  }

  return prefix + formattedDate;
};

export const validateEmail = (email: string) => validator.isEmail(email);

export const mapRoles = (roles: { id: string; name: string }[]) => {
  return roles.map((role) => {
    return { label: role.name, value: role.id };
  });
};

export const retrieveUserImg = (userId: string) => {
  return `${process.env.REACT_APP_BASE_URL}/user/${userId}/profile-picture`;
};

export const retrieveAircraftImg = (aircraftId: string) => {
  return `${process.env.REACT_APP_BASE_URL}/aircraft/${aircraftId}/profile-picture`;
};

export const onChangeImage = async (
  e: any,
  setUserData: (user: UserObject) => void,
  userData: UserObject,
  setProfileUser?: (user: UserObject) => void,
) => {
  const files: FileList | null = e.target.files;
  if (!files) return;

  const fileUploaded = files[0];

  try {
    new Promise((resolve, setData) => {
      Resizer.imageFileResizer(
        fileUploaded,
        180,
        180,
        "JPEG",
        100,
        0,
        async (blob: any) => {
          try {
            await api.users.updateProfilePicture(blob);
            const blobURL = URL.createObjectURL(blob);
            setUserData({ ...userData, profile_picture: blobURL });
            if (setProfileUser) {
              setProfileUser({ ...userData, profile_picture: blobURL})
            }
          } catch (error) {
            console.log({ error });
          }
        },
        "blob"
      );
    });
  } catch (error) {
    console.log({ error });
  }
};

export const resizeFile = (file: any): Promise<Blob> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (blob: any) => {
        resolve(blob);
      },
      "blob"
    );
  });

export const handlePhotoToURL = (
  e: React.ChangeEvent<HTMLInputElement>
): string | undefined => {
  const files: FileList | null = e.target.files;
  if (!files) return;

  const fileUploaded = files[0];
  const blob = new Blob([fileUploaded]);
  return URL.createObjectURL(blob);
};

export const useLocalStorage = (key: string, initialDefault: boolean) => {
  const [val, setVal] = useState(() => {
    const localStorageVal = localStorage.getItem(key);

    return localStorageVal !== null
      ? JSON.parse(localStorageVal)
      : initialDefault;
  });

  useEffect(() => {
    if (localStorage.getItem(key) === null) {
      setVal(initialDefault);
    }
  }, [key, initialDefault]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(val));
  }, [val, key]);

  return [val, setVal];
};

export const truncateString = (
  stringToTruncate: string,
  maxLength: number = 20,
  ellipsesInMiddle?: boolean
) => {
  if (!stringToTruncate) {
    return "";
  }
  if (stringToTruncate.length <= maxLength + 3) {
    return stringToTruncate;
  }

  if (ellipsesInMiddle) {
    const first = stringToTruncate.substring(0, Math.round(maxLength / 2));
    const second = stringToTruncate.substring(
      Math.round(stringToTruncate.length - Math.round(maxLength / 2))
    );
    return first + " ... " + second;
  }

  const slicedName = stringToTruncate.slice(0, maxLength);
  return slicedName + "...";
};

export const sharedResourceUrl = (invitedResource?: InvitedResource): string => {
  if (!invitedResource) return '';

  console.log("invitedResource", invitedResource)

  switch(invitedResource.resourceType) {
    case UserResourceType.Aircraft:
      return `/aircraft/${invitedResource.id}`;
    case UserResourceType.Transaction:
      return `/transactions/${invitedResource.id}`
    case UserResourceType.Document:
      return `/documents?id=${invitedResource.id}`
    default:
      return '';
  }
}

/**
 * Captilize a word, making only the first character upper case
 *
 * @param word a string
 * @returns a string
 */
export const capitalize = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

/**
 * assists in building URLSearchParams for get request queries
 * 
 * @param params empty object or required params {}
 * @param optionalParams 
 * @returns url safe query params for api requests
 */
export const buildQueryParams = (params: any, optionalParams: any[]): string => {
  optionalParams.forEach(optionalParam => {
    if (typeof Object.values(optionalParam)[0] !== 'undefined') {
      Object.assign(params, optionalParam);
    }
  });

  return new URLSearchParams(params).toString();
};
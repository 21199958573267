import { ResourceContainer } from "../UI/Containers/ResourceContainer";
import MeatballMenu from "../../components/UI/MeatballMenu";
import TagList from "../../components/UI/Tags/TagList";
import { ModalOptions } from "../../types/Modal";
import share from "../../images/share.svg";
import { useContext, useState } from "react";
import { Paperwork } from "../../models/Paperwork";
import { api } from "../../api";
import { viewResource } from "../../utils/helpers";
import download from "../../images/download.svg";
import { useDocumentPermission } from "../../hooks/useDocumentPermission";
import { Permission } from "../../types/Permission";
import { UserDataContext } from "../../utils/store";
interface DocumentProps {
  document: Paperwork;
  setModalType: (modalType: ModalOptions) => void;
  setIsOpen: (isOpen: boolean) => void;
  setSelectedDocument(document: Paperwork): void;
  removeTag: (documentId: string, tagId: string) => void;
}

const PaperworkComponent = ({
  document,
  setModalType,
  setIsOpen,
  setSelectedDocument,
  removeTag,
}: DocumentProps) => {
  const [showMeatballMenu, setShowMeatballMenu] = useState(false);
  const { userData } = useContext(UserDataContext);
  const permission = useDocumentPermission(document);
  const isAdmin = [Permission.ADMIN].includes(permission);

  const onViewFile = async (fileKey: string) => {
    if (fileKey) {
      const signedUrl = await api.documents.getDocumentSignedUrl(fileKey);
      viewResource(signedUrl);
    }
  };

  const onClick = async () => {
    onViewFile(document.file_key)
  }

  const viewMenuItem = document.file_key ? [{
    title: `View`,
    img: download,
    events: [() => onViewFile(document.file_key)],
  }] : []

  const shareMenuItem = isAdmin ? [{
    title: `Share`,
    img: share,
    events: [() => setModalType(ModalOptions.Share), () => setIsOpen!(true)],
  }] : []

  const meatballMenuItems = [
    ...shareMenuItem,
    ...viewMenuItem
  ];

  return (
    <ResourceContainer
      title={document.formattedTitle}
      subTitle={document.formattedFileName}
      timestamp={document.updatedAt}
      documentLink={document.documentPageLink(userData.id)}
      documentLinkLabel={document.formattedLinkLabel}
      onViewFile={onViewFile}
      onClick={onClick}
      documentVersions={document.file_name ? document.documentVersions : undefined}
      tagListComponent={
        <TagList
          tagList={document.tags}
          resourceId={document.id}
          handleRemoveTag={removeTag}
          handleNewTag={() => {
            setSelectedDocument(document);
            setModalType(ModalOptions.Tags);
            setIsOpen!(true);
          }}
        />
      }
      meatBallMenu={
        <MeatballMenu
          meatballMenuItems={meatballMenuItems}
          showMeatballMenu={showMeatballMenu}
          setShowMeatballMenu={setShowMeatballMenu}
          setSelectedDoc={setSelectedDocument}
          doc={document}
        />
      }
    />
  );
};

export default PaperworkComponent;

import React, { useCallback, useContext, useEffect, useState } from "react";
import UserInfo from "./Overview/UserInfo";
import volasLogo from "../../images/logo.svg";
import { InvitedResource } from "../../api/auth";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserDataContext } from "../../utils/store";
import { api } from "../../api";
import Tabs, { TabsPage } from "../UI/Tabs";
import { ModalOptions } from "../../types/Modal";
import { TabContainer } from "../UI/Containers/TabContainer";
import { useModalData } from "../Modals/hooks/useModalData";
import { retrieveTabsFor } from "../../data/tabs";
import { sharedResourceUrl } from "../../utils/helpers";

const ProfileWrapper = (props: {
  children: React.ReactNode;
  tabsToHide: string[];
}) => {
  const { tabsToHide, children } = props;
  const navigate = useNavigate();

  const { userData, setUserData } = useContext(UserDataContext);

  const { id } = useParams();
  const isCurrentUser = userData.id === id;

  const [modalIsOpen, setIsOpen] = useState(false);

  // Grab invited resource if available
  let invitedResource: InvitedResource | undefined;

  const [queryParams] = useSearchParams();
  const resourceStr = queryParams.get("resourceStr");

  if (resourceStr) {
    invitedResource = JSON.parse(resourceStr);
  }

  const getCurrentUser = useCallback(async () => {
    try {
      const user = await api.users.getCurrentUser();
      setUserData({ ...user });
    } catch (_error) {
      const error: any = _error;
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getCurrentUser();
  }, []);

  const handleSubmit = async () => {
    setIsOpen(false);
    navigate(sharedResourceUrl(invitedResource));
  };

  /**
   * ProfileWrapper {@link OnboardingModal}
   */
  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: ModalOptions.Onboard,
    childModalProps: {
      closeModal: () => setIsOpen(false),
      handleSubmit,
      invitedResource,
    },
  };

  useModalData(modalData);

  useEffect(() => {
    if (invitedResource) {
      setIsOpen(true);
    }
  });

  const profileHeader = () => {
    return (
      <>
        <h2 className="flex items-center md:hidden mt-16 text-sm font-semibold tracking-widest text-gray-500">
          <img src={volasLogo} className="mr-3 select-none" alt="" />
          PROFILE
        </h2>
        <div className="flex self-center md:hidden">
          <UserInfo />
        </div>
        <h2 className="hidden md:flex select-none self-start ml-16 my-14 text-sm text-xxx-red tracking-widest font-semibold">
          {isCurrentUser ? "MY PROFILE" : `USER PROFILE > ${userData.name}`}
        </h2>
      </>
    );
  };

  return (
    <TabContainer
      header={profileHeader()}
      tabs={
        <Tabs tabsList={retrieveTabsFor(TabsPage.Profile, id, tabsToHide)} />
      }
    >
      {children}
    </TabContainer>
  );
};

export default ProfileWrapper;

import React, { useContext, useState } from "react";
import { RedBtnImg } from "../../types/Button";
import RedButton from "../Buttons/RedButton";
import { TransactionContext, UserDataContext } from "../../utils/store";
import { api } from "../../api";
import { ModalOptions } from "../../types/Modal";
import { useModalData } from "../Modals/hooks/useModalData";
import { toast } from "react-toastify";
import { TOAST_STATUSES } from "../../utils/toast_statuses";
import { useErrorMessages } from "./hooks/useErrorMessages";
import TransactionHeader from "./TransactionHeader";
import {ProgressBar} from "./ProgressBar";
import {useTransactionProgress} from "./hooks/useTransactionProgress";

const FinalizeTransaction = () => {
  const { transaction, setTransaction, requiredDocuments } = useContext(TransactionContext);
  const { userData } = useContext(UserDataContext);
  const [modalType, setModalType] = useState(ModalOptions.Default);
  const [modalIsOpen, setIsOpen] = useState(false);

  const errorMessages = useErrorMessages(transaction, requiredDocuments);
  const { transactionProgress, rolesProgress, approvalsProgress, documentsProgress  } = useTransactionProgress(transaction, requiredDocuments);
  const isUserTransactionCreator = transaction.created_by === userData.id;

  const progressTooltip = `${rolesProgress[0]}/${rolesProgress[1]} Parties Complete \n${approvalsProgress[0]}/${approvalsProgress[1]} Approvals Complete \n${documentsProgress[0]}/${documentsProgress[1]} Required Documents Complete`

  const handleClickFinalize = () => {
    setModalType(ModalOptions.ConfirmFinalize);
    setIsOpen(true);
  };

  const handleConfirmFinalize = async () => {
    try {
      if (errorMessages.length === 0) {
        await toast.promise(
          api.transactions.finalizeTransaction(transaction.id),
          TOAST_STATUSES.finalizeTransaction
        );
        const _transaction = await api.transactions.getTransaction(
          transaction.id
        );
        setTransaction(_transaction);
        setModalType(ModalOptions.Success);
      } else {
        setModalType(ModalOptions.TransactionErrors);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelFinalize = () => {
    setIsOpen(false);
  };

  const handleAcceptErrors = () => {
    setIsOpen(false);
  };

  const handleCloseSuccess = () => {
    setIsOpen(false);
  };

  const retrieveChildProps = () => {
    switch (modalType) {
      case ModalOptions.ConfirmFinalize:
        return {
          confirmMessage: "Are you sure you want to finalize this transaction?",
          onConfirm: handleConfirmFinalize,
          onCancel: handleCancelFinalize,
        };
      /**
       * TransactionForms {@link TransactionErrorsModal}
       */
      case ModalOptions.TransactionErrors:
        return {
          errorMessages,
          onAccept: handleAcceptErrors,
        };
      case ModalOptions.Success:
        return {
          successMessage: "Transaction finalized!",
          buttonTitle: "AWESOME",
          handleSubmit: handleCloseSuccess,
        };
    }
  };

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: modalType,
    childModalProps: retrieveChildProps(),
  };

  useModalData(modalData);

  const isReadOnly = transaction.is_complete;

  return (
    <>
      <ProgressBar percentageComplete={transactionProgress} progressTooltip={progressTooltip} />
      <TransactionHeader
        title="Finalize Transaction"
        subTitle="The transaction creator is responsible for finalizing the transaction."
        sectionCompleted={false}
      />
      <div className="flex flex-wrap md:max-w-865 mt-5 md:md-0">
        { !isReadOnly &&
        <RedButton
          title="FINALIZE"
          styles="text-xs h-42 w-32 md:mr-3 self-start md:self-center mt-3 md:mt-0 ml-4 md:ml-0 disabled:cursor-none"
          img={RedBtnImg.Arrow}
          onClickEvent={handleClickFinalize}
          disabled={isReadOnly || !isUserTransactionCreator}
        />
        }
      </div>
    </>
  );
};

export default FinalizeTransaction;

import React from "react";

export const InviteNotice: React.FC<{
  text: string,
  color: string
}> = ({text, color}) => {
  return (
    <div className="self-center w-11/12 md:w-10/12 w-410 text-sm self-center flex flex-col items-center">
      <div className="text-center">
        <span className={`${color}`}>{text}</span>
      </div>
    </div>
  )
}
import axios from "axios";
import { getAccessToken } from "./local-storage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 150000,
});

instance.interceptors.request.use(
  async (config) => {
    const accessToken = getAccessToken();
    if (!accessToken) return config;
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;

import {UserObject} from "../types/User";
import {Tag} from "../types/Tag";

/**
 * filters and returns documents matching active tag filters
 *
 * @param users
 * @param activeTagFilters
 * @returns
 */
export const filterNetworkUsers = (
    users: UserObject[],
    activeTagFilters: Map<string, Tag>
): UserObject[] => {
    // if no active filters return unfiltered documents
    if (!activeTagFilters.size) {
        return users;
    }
    return users.filter((user: UserObject) => {
        let isIncluded: boolean = false;
        const tagIds = user.tags ? user.tags.map((tag) => tag.id) : [];
        const tagFilterArray = Array.from(activeTagFilters.keys())

        tagFilterArray.forEach((tagId: string) => {
            if (tagIds.includes(tagId)) {
                isIncluded = true;
            }
        });
        return isIncluded;
    });
};
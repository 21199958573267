
export interface PermissionedRoles {
  user: InvitationRecipient;
  setUserPermission: (arg0: string) => void;
  permission: string;
  updatedPermission: string | undefined;
}

export interface SharingUser {
  email: string;
  permission: string;
}

export interface DropdownPermissions {
  user: InvitationRecipient;
  userEmailList?: InvitationRecipient[];
  setUserEmailList?: (arg0: InvitationRecipient[]) => void;
  rolesList: string[];
  dropdownType: DropdownTypes;
  updatedPermission?: string | undefined;
  setUpdatedPermission?: (permission: string) => void;
  isDisabled?: boolean;
}

export enum DropdownTypes {
  Permissions,
  RequiredRoles,
  OptionalRoles,
  EditUser,
}

export enum InvitationTypes {
  AircraftUser = "aircraft",
  Document = "document",
  TransactionUser = "transaction",
  ApplicationUser = "application"
}

export interface DropdownRoles {
  user: InvitationRecipient;
  userEmailList: InvitationRecipient[];
  setUserEmailList: (arg0: InvitationRecipient[]) => void;
}

export interface ShareInput {
  setUserEmail: (arg0: string) => void;
  userEmail: string;
  handleAddUser: (user: InvitationRecipient) => Promise<void>;
  tempUserList: InvitationRecipient[];
  validateUser: (arg0: string) => boolean;
}

export interface UserListItems {
  invitationRecipient: InvitationRecipient;
  userInviteList: InvitationRecipient[];
  setUserInviteList: (arg0: InvitationRecipient[]) => void;
  permissionsList: string[];
  shareType: InvitationTypes;
  hidePermissionMenu?: boolean;
  setResourceRoleIds: (ids: string[]) => void;
}

export interface InvitationRecipient {
  email: string;
  permission?: string;
  resourceRoleIds?: string[];
  role?: string;
  hasPendingInvite?: boolean;
  requiredRole?: string;
  optionalRole?: string;
  name?: string;
  isAdmin?: boolean;
  id?: string;
  picture?: string;
  isNewInvite?: boolean;
}

import { useEffect, useState } from "react";
import {
  TransactionObject,
  TransactionRole,
} from "../../../types/Transaction";
import { DocumentObject } from "../../../types/Document";

export const useErrorMessages = (
  transaction: TransactionObject,
  requiredDocuments: DocumentObject[]
) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    const _errorMessages: string[] = [];
    if (!transaction) return;

    // check for buyer, seller and escrow
    const roleNames = transaction?.sharedWith?.flatMap((x) =>
      x.transactionRoles.map((r) => r.name)
    );
    if (!roleNames?.includes(TransactionRole.Buyer)) {
      _errorMessages.push("Transaction must have a buyer");
    }
    if (!roleNames?.includes(TransactionRole.Seller)) {
      _errorMessages.push("Transaction must have a seller");
    }
    if (!roleNames?.includes(TransactionRole.Escrow)) {
      _errorMessages.push("Transaction must have an escrow");
    }

    // check that all required transaction fields are checked
    if (!transaction.is_forms_filled) {
      _errorMessages.push("Escrow must confirm that all forms filed with faa");
    }
    if (!transaction.is_deposit_state) {
      _errorMessages.push("Escrow must confirm deposit state");
    }
    if (!transaction.is_disbursement_funds) {
      _errorMessages.push("Escrow must confirm funds disbursement");
    }
    if (!transaction.is_seller_approval) {
      _errorMessages.push("Seller must approve transaction");
    }
    if (!transaction.is_funds_received) {
      _errorMessages.push("Escrow must confirm funds received");
    }

    // check that all required documents are filled
    if (requiredDocuments.some((x) =>  {
      return x.is_docusign ? !x.file_key || !x.is_signed : !x.file_key
    })) {
      _errorMessages.push("All required documents must be completed");
    }

    setErrorMessages(_errorMessages);
  }, [transaction, requiredDocuments]);

  return errorMessages;
};

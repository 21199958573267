import React from "react";
import {VolasTooltip} from "../UI/VolasTooltip";

const Label = (props: { hidden: string; title: string; required: boolean, tooltip?: string }) => {
  const { hidden, title, required, tooltip } = props;
  return (
    <div className={`self-start items-center flex w-10/12 ${!hidden && "invisible"}`}>
      <p className="text-xxs text-gray-400 font-semibold self-center text-left tracking-wider pl-2">
        {title}
      </p>
      {
        required && (
          <p className={`text-red-600 ml-2 text-lg`}>*</p>
        )
      }
      {
        tooltip && <VolasTooltip tooltip={tooltip} />
      }
    </div>
  );
};

export default Label;

import { MutableRefObject, SyntheticEvent, ChangeEvent } from "react";
import { AircraftModalTypes, AircraftObject } from "./Aircraft";
import { DocModalType, DocumentObject, File } from "./Document";
import { InvitationRecipient, InvitationTypes } from "./Invitations";
import { Permission } from "./Permission";
import { UserObject } from "./User";
import { InvitedResource } from "../api/auth";
import { PrivacySetting } from "./PrivacySetting";
import {DocumentResourceType} from "./Resource";
export interface ModalProps {
  childModal: ModalOptions;
  childModalProps: any;
  modalIsOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  navigateLocation?: string;
}

interface BaseModalProps {
  isSaving?: boolean;
}

/*******************************************
 * Child Modal Props
 *******************************************/

export interface AircraftModalProps {
  aircraftData?: AircraftObject;
  modalType: AircraftModalTypes;
  handleSubmit(...args: any): Promise<void>;
}

export interface AircraftFormData {
  title: string;
  type: string;
  base: string;
  tailNumber: string;
  serialNumber: string;
  files?: any;
  addAsOwner?: string;
}

export interface DeleteModalProps {
  deleteModalType: DeleteModalOptions;
  title: string;
  buttonText: string;
  handleSubmit(): Promise<void>;
}

export interface SuccessModalProps {
  buttonTitle: string;
  successMessage: string;
  secondaryMessage?: string;
  handleSubmit(): Promise<void>;
}

export interface DocModalProps extends BaseModalProps {
  // unique to document modal
  documentSuggestedTitles: string[];
  hiddenFileInput: MutableRefObject<HTMLInputElement | null>;
  hiddenFileInput2: MutableRefObject<HTMLInputElement | null>;
  selectedDoc: DocumentObject | undefined;
  docModalType: DocModalType;
  isDocumentNullable: boolean;
  metadata: Record<string, any>;
  // can these be combine?
  handleClick: (arg: SyntheticEvent) => void;
  handleClick2: (arg: SyntheticEvent) => void;
  // double check
  updateDocumentTitle?: (updatedDocumentTitle: string) => Promise<void>;
  // submit function
  submitFile?: (
    fileData: File,
    documentTitle: string,
    existingDocumentId: string,
    isDocusign: boolean,
    isSigned: boolean,
    metadata: Record<string, any>,
    addAutoTags?: boolean
  ) => Promise<void>;
  resourceType?: DocumentResourceType;
}

export interface EditUserModalProps {
  setIsOpen: (arg0: boolean) => void;
  user: UserObject;
  permissionsList: Permission[];
  shareType: InvitationTypes;
  onSave: (roleIds: string[], permissionLevel: string) => void;
  permissionsDisabled?: boolean;
}

export interface ConfirmFinalizeModalProps {
  confirmMessage: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export interface TransactionErrorsModalProps {
  errorMessages: string[];
  onAccept: () => void;
}

export interface DeleteModalProps {
  handleDelete: () => void;
  setIsOpen: (isOpen: boolean) => void;
  deleteModalType: DeleteModalOptions;
  aircraftTitle?: string;
  inviteEmail?: string;
}

export interface EditProfileModalProps {
  updateUserInfo: (
    profileData: Partial<UserObject>,
    multiValue: { value: string; label: string }[],
    profilePicUpload?: ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
}

export interface InvitationModalProps {
  selectedResourceId: string;
  shareType: InvitationTypes;
  handleSubmit: (
    recipients: InvitationRecipient[],
    shareType: "aircraft" | "transaction" | "document" | "application",
    selectedResourceId: string
  ) => Promise<void>;
  permissionsList?: Permission[];
  existingSharesEmails?: string[];
  existingNetworkEmails?: string[];
}

export interface PrivacyModalProps {
  handleSubmit(privacyOptions: PrivacySetting[]): Promise<void>;
}

export interface OnboardingModalProps {
  invitedResource: InvitedResource | undefined;
  handleSubmit(): Promise<void>;
  closeModal(): void;
}

/*******************************************
 * Modal Enums
 *******************************************/

export enum DeleteModalOptions {
  Document,
  User,
  Invitation,
}

export enum ModalOptions {
  Document,
  DeleteResource,
  Share,
  Success,
  Privacy,
  ResetPasswordSuccess,
  ResetPassword,
  EditInfo,
  Onboard,
  Aircraft,
  InitiateTx,
  EditResourceUser,
  Tags,
  Default,
  DeleteInvite,
  ConfirmFinalize,
  TransactionErrors,
}

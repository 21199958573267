import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateAccount from "../Onboarding/CreateAccount";
import Experience from "../Onboarding/Experience";
import OnboardingLogo from "../Logos/OnboardingLogo";
import Welcome from "../Onboarding/Welcome";
import PrivateRoute from "../Auth/PrivateRoute";
import PageNotFound from "../Errors/PageNotFound";
import RedirectActiveSessionRoute from "../Auth/RedirectActiveSessionRoute";

const Onboard = () => {
  return (
    <div className="flex flex-col md:flex-row justify-left h-screen w-full">
      <OnboardingLogo />
      <Routes>
        <Route
          path="/"
          element={
            <RedirectActiveSessionRoute>
              <Welcome />
            </RedirectActiveSessionRoute>
          }
        />
        <Route
          path="/register"
          element={
            <RedirectActiveSessionRoute>
              <CreateAccount />
            </RedirectActiveSessionRoute>
          }
        />
        <Route
          path="/experience"
          element={
            <PrivateRoute>
              <Experience />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default Onboard;

import {Box, Tooltip} from "@chakra-ui/react";
import {FiInfo} from "react-icons/fi";
import React from "react";

export const VolasTooltip = ({tooltip}: {tooltip: string}) => {
  return (
    <Tooltip className={"flex"} hasArrow={true} label={`${tooltip}`} fontSize="md">
      <Box ml={1} mt={"-4px"}>
        <FiInfo size="12px"/>
      </Box>
    </Tooltip>
  )
}
import { MutableRefObject, SyntheticEvent } from "react";
import { ModalOptions } from "./Modal";
import { DocumentResourceType } from "./Resource";

export interface DocumentUser {
  email: string;
  permissionLevel: string;
  id: string;
  hasPendingInvite?: boolean;
}
export interface DocumentObject {
  created_at: string;
  created_by: string;
  file_name: string;
  file_key: string;
  id: string;
  title: string;
  updated_at: string;
  is_docusign: boolean;
  is_signed: boolean;
  resource_type: DocumentResourceType;
  shared_with?: DocumentUser[];
  shared_with_pending?: DocumentUser[];
}

export interface File {
  title: string;
  name: string;
  data: Blob;
}

export interface DocumentProps {
  document: DocumentObject;
  setSelectedDoc: (documentObject: DocumentObject) => void;
  setDocModalType: (docModalType: DocModalType) => void;
  setModalType: (modalType: ModalOptions) => void;
  setIsOpen: (isOpen: boolean) => void;
  readOnly?: boolean;
  isRequiredDoc?: boolean;
}

export interface DocumentUploadProps {
  setDocumentType: (arg0: DocumentType) => void;
  documentType: DocumentType;
  hiddenFileInput: MutableRefObject<HTMLInputElement | null>;
  handleClick: (arg: SyntheticEvent) => void;
  setUploadedFile: (arg0: File) => void;
  documentTitle: string;
  uploadedFile: File;
  selectedDoc: DocumentObject | undefined;
  modalType: DocModalType;
  initialFileData: File;
  isDocusign: boolean;
}

export enum DocModalType {
  Add,
  Edit,
  Default,
  Document,
  DeleteDocument,
  Share,
  Success,
}

export enum DocumentType {
  Document = "document",
  Docusign = "docusign",
}

export interface DocumentSuggestedTitle {
  title: string;
}

export enum DocumentSuggestedTitleType {
  PROFILE_DOC = "profile_doc",
  AIRCRAFT = "aircraft",
  TRANSACTION = "transaction",
}

export enum SortingOption {
  AlphabeticalASC = "Alphabetical - A to Z",
  AlphabeticalDESC = "Alphabetical - Z to A",
  DateDESC = "Date - Newest to Oldest",
  DateASC = "Date - Oldest to Newest",
}

export interface SortingProps {
  setSortBy: (sortByOptions: SortingOption) => void;
  sortBy: SortingOption;
}

import { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosInstance from "./axiosConfig";

const request = (
  requestMethod: (
    url: string,
    data?: object,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse>,
  url: string,
  data?: object,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    requestMethod(url, data, config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (error.message.includes("could not find refresh token")) {
          reject(error);
        }
        const response: AxiosResponse = error.response;
        if (response && response.data) {
          reject({ error: response.data });
        }
        reject("unknown error");
      });
  });
};

export const get = (
  url: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => request(axiosInstance.get, url, undefined, config);

export const post = (
  url: string,
  data?: object,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => request(axiosInstance.post, url, data, config);

export const put = (url: string, data?: object): Promise<AxiosResponse> =>
  request(axiosInstance.put, url, data);

export const del = (url: string): Promise<AxiosResponse> =>
  request(axiosInstance.delete, url);

import React from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../../components/Errors/PageNotFound";
import AircraftOverview from "../../components/Aircraft/AircraftOverview";
import AircraftWrapper from "../../components/Aircraft/AircraftWrapper";
import Fleet from "../../components/Aircraft/Fleet";
import AircraftUsers from "../../components/Aircraft/AircraftUsers";
import FleetWrapper from "../../components/Aircraft/FleetWrapper";
import TransactionsIndex from "../../components/Transactions/TransactionsIndex";
import { TxsDisplayType } from "../../types/Transaction";
import DocumentsLoader from "../../components/Documents/DocumentsLoader";
import { DocumentResourceType } from "../../types/Resource";

const Aircraft = () => {
  return (
      <Routes>
        <Route element={<AircraftWrapper />}>
          <Route path=":id" element={<AircraftOverview />} />
          <Route
            path=":id/documents"
            element={<DocumentsLoader resourceType={DocumentResourceType.Aircraft} />}
          />
          <Route path=":id/users" element={<AircraftUsers />} />
          <Route
            path=":id/transactions"
            element={
              <TransactionsIndex txHistoryType={TxsDisplayType.Aircraft} />
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route element={<FleetWrapper />}>
          <Route path="/" element={<Fleet />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
  );
};

export default Aircraft;

import { auth } from "./auth";
import { documentSuggestedTitles } from "./document-suggested-titles";
import { documents } from "./documents";
import { invitations } from "./invitations";
import { roles } from "./roles";
import { tags } from "./tags";
import { users } from "./users";
import { aircraft } from "./aircraft";
import { transactions } from "./transaction";
import { aircraftRoles } from "./aircraft-roles";
import { transactionRoles } from "./transaction-roles";
import { aircraftData } from "./aircraft-data";
import { analytics } from "./analytics";

export const api = {
  auth,
  aircraft,
  analytics,
  documentSuggestedTitles,
  documents,
  roles,
  tags,
  users,
  invitations,
  transactions,
  aircraftRoles,
  aircraftData,
  transactionRoles,
};

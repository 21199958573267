import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Tabs, { TabsPage } from "../UI/Tabs";
import { TabContainer } from "../UI/Containers/TabContainer";
import { TabPageHeader } from "../UI/TabHeader";
import { retrieveTabsFor } from "../../data/tabs";
import { AircraftObject } from "../../types/Aircraft";
import { api } from "../../api";
import Loader from "../Loader";
import { FleetContext } from "../../utils/store";
import { PageHeader } from "../../types/UI";

const FleetWrapper = () => {
  const [fleet, setFleet] = useState([] as AircraftObject[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const aircraft = await api.aircraft.getAircrafts();
        setFleet(aircraft);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <TabContainer
      header={<TabPageHeader pageTitle={PageHeader.Aircraft} />}
      tabs={<Tabs tabsList={retrieveTabsFor(TabsPage.Fleet)} />}
    >
      <FleetContext.Provider value={{ fleet, setFleet }}>
        <Outlet />
      </FleetContext.Provider>
    </TabContainer>
  );
};

export default FleetWrapper;

import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../../api/local-storage";
import { AuthContext, UserDataContext } from "../../utils/store";

const RedirectActiveSessionRoute = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { isAuthorized } = useContext(AuthContext);
  const { userData } = useContext(UserDataContext);

  const navigation = useNavigate();

  useEffect(() => {
    const accessToken = getAccessToken();

    if (isAuthorized && accessToken && userData && userData.id && userData.preventNavigationOnUpdate !== true) {
      navigation(`/profile/${userData.id}`);
    }
  }, [isAuthorized, userData]);

  return children;
};

export default RedirectActiveSessionRoute;

import { DocumentObject } from "../types/Document";
import { TransactionObject, TxDocumentType } from "../types/Transaction";
import { put, get, post, del } from "./axios";

/**
 * Get all transactions tied to the aircraft
 *
 * @returns an array of transactions
 */
const getAircraftTransactions = async (
  aircraftId: string
): Promise<TransactionObject[]> => {
  try {
    const res = await get(`/transaction/aircraft/${aircraftId}`);
    const transaction = res.data;
    return transaction;
  } catch (_error) {
    const error: any = _error;
    throw new Error(error);
  }
};

/**
 * Get a transaction the user has created
 */
const getTransaction = async (id: string): Promise<TransactionObject> => {
  try {
    const res = await get(`/transaction/${id}`);
    const transaction = res.data;
    return transaction;
  } catch (_error) {
    const error: any = _error;
    throw new Error(error);
  }
};

/**
 * Delete a transaction the user has created
 */
const deleteTransaction = async (id: string): Promise<boolean> => {
  try {
    await del(`/transaction/${id}`);
    return true;
  } catch (_error) {
    const error: any = _error;
    throw new Error(error);
  }
};

/**
 * Get all transactions of the user
 *
 * @returns user's transactions
 */
const getTransactions = async (): Promise<TransactionObject[]> => {
  try {
    const res = await get(`/transaction`);
    const transactions = res.data;
    return transactions;
  } catch (_error) {
    const error: any = _error;
    throw new Error(error);
  }
};

/**
 * Get all documents of the trasaction
 *
 * @param transactionId id of the transaction
 * @param txDocumentType TxDocumentType; optional or required
 *
 * @returns transaction's txDocumentType documents
 */
const getTransactionDocuments = async (
  transactionId: string,
  txDocumentType: TxDocumentType
): Promise<DocumentObject[]> => {
  try {
    const res = await get(
      `/transaction/${transactionId}/documents?type=${txDocumentType.toLowerCase()}`
    );
    const documents = res.data;
    return documents;
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Creates a new transaction
 *
 * @param name the transaction name
 * @param aircraftId id of the aircraft this transaction is for
 * @returns the new transaction
 */
const createTransaction = async (
  name: string,
  aircraftId: string
): Promise<TransactionObject> => {
  try {
    const res = await post(`/transaction/aircraft/${aircraftId}`, { name });
    const transaction = res.data;
    return transaction;
  } catch (_error) {
    const error: any = _error;
    throw new Error(error);
  }
};

interface TransactionUpdateParams {
  name?: string;
  isComplete?: boolean;
  isSellerApproval?: boolean;
  isFormsFilled?: boolean;
  isDepositState?: boolean;
  isDisbursementFunds?: boolean;
  isFundsReceived?:boolean;
}

/**
 * Updates a transaction
 *
 * @param id
 * @param updateBody a TransactionUpdateParams with fields to update
 * @returns the updated transaction
 */
const updateTransaction = async (
  id: string,
  updateBody: TransactionUpdateParams
): Promise<TransactionObject> => {
  try {
    const res = await put(`/transaction/${id}`, updateBody);
    const transaction = res.data;
    return transaction;
  } catch (_error) {
    const error: any = _error;
    throw new Error(error);
  }
};

/**
 * Removes a user from an transaction
 */
const removeTransactionUser = async (
  transactionId: string,
  email: string
): Promise<boolean> => {
  try {
    await del(`/transaction/${transactionId}/user?email=${email}`);
    return true;
  } catch (_error) {
    const error: any = _error;
    throw new Error(error);
  }
};

/**
 * Updates a user's roles and permission on an transaction regardless if they have accepted an
 * invitation or not.
 *
 * @param transactionId the transaction id
 * @param email the user's email
 * @param roleIds the role ids to replace the user's current roles
 * @param permissionLevel
 */
const updateTransactionUser = async (
  transactionId: string,
  email: string,
  roleIds: string[],
  permissionLevel: string
): Promise<void> => {
  try {
    await put(
      `/transaction/${transactionId}/update-transaction-user?email=${email}`,
      { roleIds, permissionLevel: permissionLevel.toLowerCase() }
    );
  } catch (_error) {
    const error: any = _error;
    throw new Error(error);
  }
};

/**
 * Finalizes a transaction
 *
 * Checks the transaction to see if all three requirements are met
 *   1. The transaction boolean fields are true: is_seller_approval, is_forms_filled, is_deposit_state, is_disbursement_funds
 *   2. The transaction has buyer, seller, and escrow roles
 *   3. The required documents have files associated with them
 *
 * If any of these checks fail, an error will be thrown with an appropriate message.
 *
 * @param transactionId the transction id
 */
const finalizeTransaction = async (transactionId: string): Promise<void> => {
  try {
    await post(`/transaction/${transactionId}/finalize`);
  } catch (_error) {
    const error: any = _error;
    throw new Error(error);
  }
};

export const transactions = {
  getTransactions,
  getTransaction,
  getTransactionDocuments,
  getAircraftTransactions,
  createTransaction,
  updateTransaction,
  deleteTransaction,
  removeTransactionUser,
  updateTransactionUser,
  finalizeTransaction,
};

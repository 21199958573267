import React, { useRef, useState } from "react";
import { SortingOption, SortingProps } from "../../types/Sorting";
import DropdownIcon from "../Icons/DropdownIcon";
import { useOutsideClickToCloseModal } from "../../hooks/useOutsideClickToCloseModal";

const Sorting: React.FC<SortingProps> = (props) => {
  const { setSortBy, sortBy } = props;

  const sortingRef = useRef<HTMLInputElement>(null);

  const [showSortingOptions, setShowSortingOptions] = useState(false);

  useOutsideClickToCloseModal(sortingRef, setShowSortingOptions);

  const sortingOptions = Object.values(SortingOption).map((sortName) => {
    return { title: sortName };
  });

  return (
    <div ref={sortingRef} className="self-end flex justify-end w-auto mb-5">
      <button
        className="flex items-center"
        onClick={() => setShowSortingOptions(!showSortingOptions)}
      >
        <p className="text-sm text-xxxx-gray mr-1">{sortBy}</p>
        <DropdownIcon styles="w-6" />
      </button>
    <div
      className={`${
        !showSortingOptions && "invisible"
      } border-welcome-gray absolute z-10 mt-8 bg-white h-auto shadow-3xl text-xs tracking-wider text-left`}
    >
      <ul>
        {sortingOptions.map((option: { title: SortingOption }) => {
          if (option.title === sortBy) return;
          return (
            <li
              className="py-2 px-3 border-b cursor-pointer hover:bg-xx-gray"
              key={option.title}
              onClick={() => {
                setSortBy(option.title);
                setShowSortingOptions(false);
              }}
            >
              {option.title}
            </li>
          );
        })}
      </ul>
    </div>
    </div>
  );
};

export default Sorting;

import { UserObject } from "../types/User";

export const aircraftOwnerNames = (users?: UserObject[]): string[] => {
  if (!users) {
    return [];
  }

  const ownerNames = users.map(user => {
    const isOwner = user.aircraftRoles.some(role => role.isOwner)
    return isOwner ? user.name : '';
  })

  return ownerNames.filter(name => name);
}
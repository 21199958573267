import { ResourceRole } from "../types/ResourceRole";
import { get } from "./axios";

/**
 * Gets a list of all available transaction roles
 *
 * @returns An array of transaction roles
 */
const getTransactionRoles = async (): Promise<ResourceRole[]> => {
  try {
    const res = await get("/transaction-role");
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

export const transactionRoles = {
  getTransactionRoles,
};

import React from "react";
import Select from "react-select";
import { SelectOption } from "../../../utils/documents.utils";

interface IndexContainerProps {
  title: string;
  subTitle: string;
  children: React.ReactNode;
  addResourceButton?: JSX.Element;
  dropDownProps?: {
    value: SelectOption;
    styles: any;
    options: SelectOption[];
    onChange: (value: any) => void;
  };
  usePadding?: boolean;
  classList?: string;
}

export const IndexContainer = ({
  title,
  subTitle,
  children,
  addResourceButton,
  dropDownProps,
  usePadding,
  classList
}: IndexContainerProps) => {
  return (
    <div
      className={`bg-neutral-gray w-full md:max-w-1280 ${classList} ${
        usePadding !== false ? "md:pl-16" : ""
      } md:pr-10`}
    >
      <div
        className={`flex flex-col pb-32 first:pb-0 md:pb-0 items-center md:items-start max-w-865 px-3 md:px-0 md:w-full ${
          !addResourceButton && "md:w-full mt-10"
        }`}
      >
        {addResourceButton}
        {
          title && (
            <h1 className="text-2xl mt-10 md:text-3xl text-center lg:text-left">
              {title}
            </h1>
          )
        }
        <div className="flex justify-between items-center w-full">
          <p className="text-xs md:text-base mx-auto md:mx-0 mt-2 text-center md:text-left text-xx-neutral-gray self-start">
            {subTitle}
          </p>
          {dropDownProps && <Select {...dropDownProps} isSearchable={false} />}
        </div>
        {usePadding !== false && (
          <div className="border-b border-gray-300 self-center md:self-start w-full mt-4 mb-6" />
        )}
        {children}
      </div>
    </div>
  );
};

import React from "react";
import { NavIcon } from "../../types/Icons";

const TransactionIcon: React.FC<NavIcon> = (props) => {
  const { fill, styles } = props;
  return (
    <svg
      className={styles}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.395 4.213a.727.727 0 0 1 1.028 0l3.637 3.636a.727.727 0 0 1 0 1.029l-3.637 3.636a.727.727 0 0 1-1.028-1.028L16.79 9.09H4.727a.727.727 0 0 1 0-1.455H16.79l-2.395-2.394a.727.727 0 0 1 0-1.029zm-4.79 7.273a.727.727 0 0 1 0 1.028L7.21 14.91h12.063a.727.727 0 1 1 0 1.455H7.21l2.395 2.395a.727.727 0 1 1-1.028 1.028L4.94 16.151a.727.727 0 0 1 0-1.029l3.637-3.636a.727.727 0 0 1 1.028 0z"
        fill={fill}
      />
    </svg>
  );
};

export default TransactionIcon;

import React from "react";
import logo from "../../images/volas-logo-vertical.svg";

const MobileHeader = () => {
  return (
    <div className="md:hidden w-full h-auto bg-logo-grey flex justify-center py-8">
      <img src={logo} alt="volas-logo" className="self-center h-24" />
    </div>
  );
};

export default MobileHeader;

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../api";
import deleteIcon from "../../images/delete-icon.svg";
import editIcon from "../../images/edit-icon.svg";
import volasUser from "../../images/volas-user.svg";
import { InvitationTypes } from "../../types/Invitations";
import { DeleteModalOptions, ModalOptions } from "../../types/Modal";
import { Permission } from "../../types/Permission";
import { UserObject } from "../../types/User";
import { truncateString } from "../../utils/helpers";
import { TransactionContext, UserDataContext } from "../../utils/store";
import { TOAST_STATUSES } from "../../utils/toast_statuses";
import { useModalData } from "../Modals/hooks/useModalData";
import MeatballMenu from "../UI/MeatballMenu";
import { Role } from "../UI/Role";
import Tag from "../UI/Tag";
import { useTransactionPermission } from "./hooks/useTransactionPermission";
import { User } from "../UI/User";

const TransactionUser: React.FC<{
  user: UserObject;
  pending: boolean;
  showRemoveUser: boolean;
  readOnly: boolean;
  onDelete?: () => void;
}> = (props) => {
  const { user, showRemoveUser, pending, onDelete, readOnly } = props;

  const { transaction, setTransaction } = useContext(TransactionContext);
  const { userData } = useContext(UserDataContext);
  const permission = useTransactionPermission();

  const [showMeatballMenu, setShowMeatballMenu] = useState(false);
  const [modalType, setModalType] = useState(ModalOptions.Default);
  const [modalIsOpen, setIsOpen] = useState(false);

  const permissionsList = [Permission.VIEWER, Permission.ADMIN];

  const findIndexOfUser = () =>
    transaction.users.findIndex(
      (transactionUser) => transactionUser.id === user.id
    );

  const removeUserFromTransaction = async () => {
    try {
      await toast.promise(
        api.transactions.removeTransactionUser(transaction.id, user.email),
        TOAST_STATUSES.removeTransactionUser
      );

      const updatedTransactionUserData = transaction.users.slice();
      updatedTransactionUserData.splice(findIndexOfUser(), 1);

      setTransaction({ ...transaction, users: updatedTransactionUserData });
      setIsOpen(false);
      if (onDelete) onDelete();
    } catch (error) {
      console.error(error);
    }
  };

  const onSave = async (roleIds: string[], permissionLevel: string) => {
    try {
      await toast.promise(async () => {
        await api.transactions.updateTransactionUser(
          transaction.id,
          user.email,
          roleIds,
          permissionLevel
        );
      }, TOAST_STATUSES.updateUserOnTransaction);

      const _transaction = await api.transactions.getTransaction(
        transaction.id
      );
      setTransaction(_transaction);
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveChildModalProps = () => {
    switch (modalType) {
      case ModalOptions.EditResourceUser:
        return {
          setIsOpen,
          user,
          permissionsList,
          shareType: InvitationTypes.TransactionUser,
          transactionId: transaction.id,
          onSave,
          permissionsDisabled:
            transaction && transaction.created_by !== userData.id,
        };
      case ModalOptions.DeleteResource:
        return {
          title: "Are you sure you want to remove this user from this transaction?",
          deleteModalType: DeleteModalOptions.User,
          handleSubmit: removeUserFromTransaction,
        };
    }
  };

  const editUserItem = [{
    title: "Edit User",
    img: editIcon,
    events: [
      () => setModalType(ModalOptions.EditResourceUser),
      () => setIsOpen(true),
    ],
  }]

  const removeUserItem = showRemoveUser ? [{
    title: "Remove User",
    img: deleteIcon,
    events: [
      () => setModalType(ModalOptions.DeleteResource),
      () => setIsOpen(true),
    ],
  }] : [];

  const meatballMenuItems = [
    ...editUserItem,
    ...removeUserItem
  ];

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: modalType,
    childModalProps: retrieveChildModalProps(),
  };

  useModalData(modalData);

  return <User
    user={user}
    pending={pending}
    permission={permission}
    meatballMenuItems={meatballMenuItems}
    showMeatballMenu={showMeatballMenu}
    setShowMeatballMenu={setShowMeatballMenu}
    roles={user.transactionRoles}
    readOnly={readOnly}
  />
};

export default TransactionUser;

import React, {useContext} from "react";
import DocumentsLoader from "../../components/Documents/DocumentsLoader";
import Overview from "../../components/Profile/Overview/Overview";
import Permissions from "../../components/Profile/Permissions/Permissions";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import ProfileWrapper from "../../components/Profile/ProfileWrapper";
import PageNotFound from "../../components/Errors/PageNotFound";
import {useLoadProfileUser} from "../../components/Profile/hooks/useLoadProfileUser";
import {DocumentResourceType} from "../../types/Resource";
import {ProfileUserContext, UserDataContext} from "../../utils/store";
import {PrivacySettingName} from "../../types/PrivacySetting";
import Invitations from "../../components/Profile/Invitations/Invitations";

const Profile = () => {
  const { id } = useParams();
  useLoadProfileUser(id);

  const { profileUser } = useContext(ProfileUserContext);
  const { userData } = useContext(UserDataContext);

  const isCurrentUser = profileUser.id === userData.id;
  let tabsToHide: string[] = [];

  // If user is viewing another user's profile,
  // hide tabs based on privacy settings
  if (!isCurrentUser) {
    tabsToHide = [
      PrivacySettingName.Briefcase,
      PrivacySettingName.Permissions,
      PrivacySettingName.Invitations
    ].filter((settingName) => {
      const setting = profileUser.privacy_settings.find(
        (setting) => setting.name === settingName
      );
      return !setting || !setting.isVisible;
    });
  }

  if (!profileUser.id) {
    return <></>;
  }

  return (
    <ProfileWrapper tabsToHide={tabsToHide}>
      <Routes>
        <Route index element={<Overview />} />
        <Route
          path="documents"
          element={
            !tabsToHide.includes(PrivacySettingName.Briefcase) ? (
              <DocumentsLoader resourceType={DocumentResourceType.Profile} />
            ) : (
              <Navigate to={`/profile/${profileUser.id}`} />
            )
          }
        />
        <Route
          path="permissions"
          element={
            !tabsToHide.includes(PrivacySettingName.Permissions) ? (
              <Permissions />
            ) : (
              <Navigate to={`/profile/${profileUser.id}`} />
            )
          }
        />
        <Route
          path="invitations"
          element={
            !tabsToHide.includes(PrivacySettingName.Invitations) ? (
              <Invitations />
            ) : (
              <Navigate to={`/profile/${profileUser.id}`} />
            )
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ProfileWrapper>
  );
};

export default Profile;

import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../api";
import { InvitationRecipient, InvitationTypes } from "../../types/Invitations";
import { ModalOptions } from "../../types/Modal";
import { Permission } from "../../types/Permission";
import { AircraftContext, UserDataContext } from "../../utils/store";
import { TOAST_STATUSES } from "../../utils/toast_statuses";
import { AddResourceButton } from "../Buttons/AddResourceButton";
import { useModalData } from "../Modals/hooks/useModalData";
import { IndexContainer } from "../UI/Containers/IndexContainer";
import NoDataDiv from "../UI/NoDataDiv";
import { useAircraftPermission } from "../../hooks/useAircraftPermission";
import User from "./AircraftUser";

const AircraftUsers = () => {
  const { id } = useParams();

  const { userData } = useContext(UserDataContext);
  const { aircraftData, setAircraftData } = useContext(AircraftContext);

  const permission = useAircraftPermission();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(ModalOptions.Default);

  const isCurrentUserCreator = aircraftData.created_by === userData.id;

  const isUserPotentialBuyer = aircraftData.sharedWith
    .find(user =>  {
      return user.id === userData.id &&
        user.aircraftRoles.map(role => role.name).includes('potential buyer')
    });

  const retrieveAircraftData = async () => {
    try {
      if (!id) return;
      const aircraft = await api.aircraft.getAircraft(id);
 
      setAircraftData(aircraft);
    } catch (error) {
      console.error(error);
    }
  };

  const handleShareAircraft = async (
    recipients: InvitationRecipient[],
    shareType: "aircraft" | "transaction" | "document" | "application",
    selectedResourceId: string
  ) => {
    try {
      await toast.promise(
        api.invitations.createInvitations(
          recipients,
          shareType,
          selectedResourceId
        ),
        TOAST_STATUSES.addUserToAircraft
      );
      retrieveAircraftData();
      setIsOpen(false);
    } catch (error) {}
  };

  const generatePermissionsList = (): Permission[] => {
    if (aircraftData && aircraftData.created_by === userData.id) {
      return [Permission.ADMIN, Permission.VIEWER];
    }
    if (permission === Permission.ADMIN) {
      return [Permission.ADMIN, Permission.VIEWER];
    }
    return [];
  };

  /**
   * AircraftUsers {@link InvitationModal}
   */
  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: ModalOptions.Share,
    childModalProps: {
      selectedResourceId: id,
      shareType: InvitationTypes.AircraftUser,
      handleSubmit: handleShareAircraft,
      permissionsList: generatePermissionsList(),
      existingSharesEmails: aircraftData.sharedWith.map(user => user.email)
    }
  };

  useModalData(modalData);

  const showNoDataMessage =
    (!aircraftData.sharedWith || !aircraftData.sharedWith.length) &&
    (!aircraftData.sharedWithPending || !aircraftData.sharedWithPending.length);

  if (isUserPotentialBuyer) {
    return (
      <IndexContainer
        title="Aircraft Users"
        subTitle={"You do not have necessary permissions to view aircraft users."}
      ><></></IndexContainer>
    )
  }

  return (
    <>
      <IndexContainer
        title="Aircraft Users"
        subTitle={"Assign and view aircraft users."}
        addResourceButton={
          permission === Permission.ADMIN ? (
            <AddResourceButton
              onClickEvent={() => {
                setModalType(ModalOptions.Share);
                setIsOpen(true);
              }}
              title="USER"
              styles="w-52 mt-10"
            />
          ) : (
            <></>
          )
        }
      >
        {showNoDataMessage && <NoDataDiv />}
        {aircraftData.sharedWith
          .concat(aircraftData.sharedWithPending)
          .map((user) => {
            return (
              <div className="my-2 md:my-2 w-full" key={user.email}>
                <User
                  user={user}
                  showRemoveUser={isCurrentUserCreator || !(user.id === userData.id)}
                  pending={!user.name}
                  onDelete={retrieveAircraftData}
                />
              </div>
            );
          })}
      </IndexContainer>
    </>
  );
};

export default AircraftUsers;

import React from "react";
import upload from "../../images/upload.svg";

export const UploadOrDocusign = (props: {
  label: string;
  onTap: () => void;
}) => {
  const { label, onTap } = props;

  return (
    <button
      onClick={() => onTap()}
      id="dropdownButton"
      data-dropdown-toggle="dropdown"
      className="w-32 -mt-2 text-gray-700 tracking-wide text-xs bg-volas-yellow py-1 rounded-sm font-semibold px-2 md:mx-6 text-center flex justify-between items-center"
      type="button"
    >
      <span className="flex">
        <img src={upload} alt="" className="mr-1" />
        {label}
      </span>
    </button>
  );
};

export interface Payload {
  name: string;
  value: boolean | string | ErrorOptions;
}

export enum ErrorOptions {
  Name,
  Email,
  Phone,
  Password,
  RepeatPassword,
  Unknown,
  Default,
}

import checkbox from "../../images/tx-checkbox.svg";

interface CheckBoxProps {
  isChecked: boolean;
}

const CheckBox = ({isChecked}: CheckBoxProps) => {
  if (isChecked) {
    return <img src={checkbox} alt="" className="rounded" />;
  }
  return <div className="w-4 h-4 border border-welcome-gray rounded" />;
};

export default CheckBox;

import { useContext, useEffect, useState } from "react";
import { Permission } from "../types/Permission";
import { AircraftContext, TransactionContext, UserDataContext } from "../utils/store";
import { DocumentObject } from "../types/Document";
import { Paperwork } from "../models/Paperwork";
import { useAircraftPermission } from "./useAircraftPermission";
import { useTransactionPermission } from "../components/Transactions/hooks/useTransactionPermission";

export const useDocumentPermission = (
  document: DocumentObject | Paperwork
) => {
  const { userData } = useContext(UserDataContext);

  const [permission, setPermission] = useState<Permission>(Permission.VIEWER);
  const aircraftPermission = useAircraftPermission();
  const transactionPermission = useTransactionPermission();

  useEffect(() => {
    if (!document || !userData) return;
    if (document.created_by === userData.id) {
      setPermission(Permission.ADMIN);
      return;
    }

    // If this document is in the context of an aircraft or transaction
    // if current user is an admin within the context, it takes precedence
    // over the documents permission
    if([aircraftPermission, transactionPermission].includes(Permission.ADMIN)) {
      setPermission(Permission.ADMIN);
      return
    }

    if (document.shared_with) {
      const self = document.shared_with.find((x) => x.id === userData.id);
      if (!self) return;
      if (self.permissionLevel === Permission.ADMIN.toLowerCase()) {
        setPermission(Permission.ADMIN);
      }
    }
  }, [document, userData, aircraftPermission, transactionPermission]);

  return permission;
};
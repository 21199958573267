import { SortingOption } from "../types/Sorting";
import { PaperworkResponse } from "../models/Paperwork";
import { Tag } from "../types/Tag";
/****
 * Documents Page
 ****/

/**
 * Styles the React Select Component for Documents Page Permissions Filter and Sorting Dropdowns
 * @param isDropDown if true, will remove 'dropdown' border styles.
 * @returns
 */
export const selectDocumentsStyles = (isDropDown: boolean = true) => {
  return {
    control: (provided: any) => ({
      ...provided,
      minHeight: "26px",
      border: isDropDown ? "intial" : "none",
      borderColor: "#bababa",
      background: "transparent",
      boxShadow: isDropDown ? "intial" : "unset",
      cursor: "pointer"
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      fontSize: "0.875rem",
      paddingTop: 0,
      paddingBottom: 0,
    }),
    menu: (provided: any) => {
      return { ...provided, marginTop: 0 };
    },
    menuList: (provided: any) => {
      return {
        ...provided,
        background: "transparent",
        fontSize: "0.75rem",
      };
    },
    option: (provided: any) => {
      return {
        ...provided,
        backgroundColor: "transparent",
        color: "back",
        "&:hover": {
          backgroundColor: "#efefef",
        },
      };
    },
    dropdownIndicator: (provided: any) => {
      return { ...provided, cursor: "pointer", padding: 0 };
    },
    indicatorSeparator: (provided: any) => {
      const display = "none";
      return { ...provided, display };
    },
    container: (provided: any) => {
      return { ...provided, width: "fit-content" };
    },
  };
};

export interface SelectOption {
  value: string;
  label: string;
}

export enum DocumentPermissionsFilterOption {
  ALL_DOCUMENTS_FILTER = "All Documents",
  MY_DOCUMENTS_FILTER = "My Documents",
  SHARED_DOCUMENTS_FILTER = "Shared Documents",
}

export const ALL_DOCUMENTS_OPTION: SelectOption = {
  value: DocumentPermissionsFilterOption.ALL_DOCUMENTS_FILTER,
  label: DocumentPermissionsFilterOption.ALL_DOCUMENTS_FILTER,
};
export const MY_DOCUMENTS_OPTION: SelectOption = {
  value: DocumentPermissionsFilterOption.MY_DOCUMENTS_FILTER,
  label: DocumentPermissionsFilterOption.MY_DOCUMENTS_FILTER,
};
export const SHARED_DOCUMENTS_OPTION: SelectOption = {
  value: DocumentPermissionsFilterOption.SHARED_DOCUMENTS_FILTER,
  label: DocumentPermissionsFilterOption.SHARED_DOCUMENTS_FILTER,
};

export const DOCUMENT_SELECT_OPTIONS = [
  ALL_DOCUMENTS_OPTION,
  MY_DOCUMENTS_OPTION,
  SHARED_DOCUMENTS_OPTION,
];

// sorting constants
export const DATE_ASC_OPTION: SelectOption = {
  value: SortingOption.DateASC,
  label: SortingOption.DateASC,
};
export const DATE_DESC_OPTION: SelectOption = {
  value: SortingOption.DateDESC,
  label: SortingOption.DateDESC,
};

export const ALPHABETICAL_ASC: SelectOption = {
  value: SortingOption.AlphabeticalASC,
  label: SortingOption.AlphabeticalASC,
};

export const ALPHABETICAL_DESC: SelectOption = {
  value: SortingOption.AlphabeticalDESC,
  label: SortingOption.AlphabeticalDESC,
};

export const SORT_SELECT_OPTIONS = [
  DATE_ASC_OPTION,
  DATE_DESC_OPTION,
  ALPHABETICAL_ASC,
  ALPHABETICAL_DESC,
];

/**
 * filters documents based on permissions, these include
 * 'all documents', 'user created documents' and 'documents shared to the user'.
 * @param documents
 * @param filter
 * @returns filtered documents based on permissions
 */
export const documentsFilterByPermissions = (
  documents: PaperworkResponse[],
  filter: DocumentPermissionsFilterOption
) => {
  switch (filter) {
    // no filters
    case DocumentPermissionsFilterOption.ALL_DOCUMENTS_FILTER:
      return documents;
    case DocumentPermissionsFilterOption.MY_DOCUMENTS_FILTER:
      return documents.filter((document) => !document.recipient_id);
    case DocumentPermissionsFilterOption.SHARED_DOCUMENTS_FILTER:
      return documents.filter((document) => document.recipient_id);
  }
};

/**
 * filters and returns documents matching active tag filters
 * 
 * @param documents 
 * @param activeTagFilters 
 * @returns 
 */
export const filterDocuments = (
  documents: PaperworkResponse[],
  activeTagFilters: Map<string, Tag>
) => {
  // if no active filters return unfiltered documents
  if (!activeTagFilters.size) {
    return documents;
  }
  return documents.filter((document: PaperworkResponse) => {
    let isIncluded: boolean = false;
    const tagIds = document.tags.map((tag) => tag.id);
    const tagFilterArray = Array.from(activeTagFilters.keys())
    
    tagFilterArray.forEach((tagId: string) => {
      if (tagIds.includes(tagId)) {
        isIncluded = true;
      }
    });
    return isIncluded;
  });
};
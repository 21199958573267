import React, { useContext, useState } from "react";
import { api } from "../../api";
import { ModalOptions, DeleteModalOptions } from "../../types/Modal";
import { UserAdminContext } from "../../utils/store";
import { AddResourceButton } from "../Buttons/AddResourceButton";
import { useModalData } from "../Modals/hooks/useModalData";
import { IndexContainer } from "../UI/Containers/IndexContainer";
import NoDataDiv from "../UI/NoDataDiv";
import { InvitationRecipient, InvitationTypes } from "../../types/Invitations";
import InvitationCard from "./Invitation";
import UserInvitation from "../../models/Invitation";
import { toast } from "react-toastify";

interface InvitationIndexProps {
  getInvitations(): Promise<void>;
}

const InvitationIndex = ({ getInvitations }: InvitationIndexProps) => {
  const { userInvitations } = useContext(UserAdminContext);
  const [selectedInvitation, setSelectedInvitation] =
    useState<UserInvitation>();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(ModalOptions.Default);

  const handleSubmit = async (
    recipients: InvitationRecipient[],
    shareType: InvitationTypes,
    selectedResourceId: string
  ) => {
    try {
      await toast.promise(
        api.invitations.createInvitations(
          recipients,
          shareType,
          selectedResourceId
        ),
        {
          error:
            "There was a problem, inviting user. please check that entered email and try again.",
          success: "User Invited",
        }
      );
      await refreshIndex();
    } catch (error) {
      console.error(error);
    }
  };

  const openDeleteModal = (invitation: UserInvitation) => {
    setSelectedInvitation(invitation);
    setModalType(ModalOptions.DeleteInvite);
    setIsOpen(true);
  };

  const handleDelete = async () => {
    try {
      await api.invitations.deleteInvitation(selectedInvitation!.invitationId);
      refreshIndex();
    } catch (error) {
      console.error(error);
    }
  };

  const refreshIndex = async () => {
    await getInvitations();
    setIsOpen(false);
    setModalType(ModalOptions.Default);
  };

  const retrieveChildProps = () => {
    switch (modalType) {
      case ModalOptions.Share:
        return {
          selectedResourceId: "0",
          shareType: InvitationTypes.ApplicationUser,
          handleSubmit,
        };
      case ModalOptions.Success:
        return {
          successMessage: "Invited Successfully!",
          buttonTitle: "AWESOME",
          handleSubmit: refreshIndex,
        };
      case ModalOptions.DeleteInvite:
        return {
          title: `Delete invite to ${selectedInvitation!.invitedUserEmail}?`,
          deleteModalType: DeleteModalOptions.Invitation,
          handleSubmit: handleDelete,
        };
    }
  };

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: modalType,
    childModalProps: retrieveChildProps(),
  };

  useModalData(modalData);

  const showNoDataMessage = !userInvitations.length;

  return (
    <>
      <IndexContainer
        title="Outgoing Pending Invitations"
        subTitle={"Pending invitations to Volas that you have sent but have not been accepted yet"}
        addResourceButton={
          <AddResourceButton
            onClickEvent={() => {
              setModalType(ModalOptions.Share);
              setIsOpen(true);
            }}
            title="User"
            prefix="INVITE"
            styles="w-52 my-10"
          />
        }
      >
        {showNoDataMessage && <NoDataDiv />}

        {userInvitations.map((invitation, i) => {
          return (
            <div
              className="my-2 md:my-2 w-full"
              key={invitation.invitedUserEmail + i}
            >
              <InvitationCard
                invitation={invitation}
                openDeleteModal={openDeleteModal}
              />
            </div>
          );
        })}
      </IndexContainer>
    </>
  );
};

export default InvitationIndex;

import React from "react";
import {Link} from "react-router-dom";

const ErrorHandler = (props: { errorMessage: string }) => {
  const { errorMessage } = props;
  //TODO add in correct mailto link once we have it
  return (
    <div
      className={`flex flex-wrap bg-inherit text-red-600 font-semibold mt-6 mb-3 justify-center md:justify-start w-full text-center md:text-left md:w-125 h-auto`}
    >
      <p className="md:text-lg">{errorMessage}</p>
      <p className="self-start text-gray-400 font-semibold text-sm mt-3 md:text-lg  mx-3 md:mx-1">
        If you feel this is a mistake,{" "}
        <a
          href="mailto:support@volas.com"
          className="underline md:text-gray-500"
        >
          contact support.
        </a>
      </p>
      <Link to={"/"} className="font-semibold underline mt-4 ml-1">Visit Volas Home Page</Link>
    </div>
  );
};

export default ErrorHandler;

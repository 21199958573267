import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../api/local-storage";
import { UserDataContext } from "../utils/store";

export const useRerouteValidUser = () => {
  const navigate = useNavigate();
  const { userData } = useContext(UserDataContext);

  useEffect(() => {
    const accessToken = getAccessToken();
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
      if (accessToken) {
        navigate(`/profile/${userData.id}`);
      }
    }
  }, []);
};

import { SortingOption } from "../types/Sorting";
import { sortByDate } from "./document.utils";

/**
 * This duplicates code in 'document-helpers' 
 * but might be nesscerssary to change what is sorted specifically for transactions
 * 
 * @param sort 
 * @param transactions 
 * @returns 
 */
export const handleSortData = (sort: SortingOption, transactions: any) => {
  const updatedDocs = transactions.slice();
  switch (sort) {
    case SortingOption.AlphabeticalASC:
      return updatedDocs.sort((a: any, b: any) =>
        a.title.localeCompare(b.title)
      );

    case SortingOption.AlphabeticalDESC:
      return updatedDocs.sort((a: any, b: any) =>
        b.title.localeCompare(a.title)
      );

    case SortingOption.DateASC:
      return sortByDate(SortingOption.DateASC, updatedDocs);

    case SortingOption.DateDESC: {
      return sortByDate(SortingOption.DateDESC, updatedDocs);
    }
  }
};
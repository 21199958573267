import { get } from "./axios";
import {AircraftData} from "../types/AircraftData";

/**
 * Gets a list of all available aircraft roles
 *
 * @returns An array of aircraft roles
 */
const getAircraftDataByLocationId = async (locId: string): Promise<AircraftData> => {
  try {
    const res = await get(`/aircraft-data/${locId}`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

const searchAircraftData = async (searchString: string): Promise<{label: string, value: string}[]> => {
  try {
    const res = await get(`/aircraft-data/search/${searchString}`);
    const results = res.data.map((data: AircraftData) => {
      return {label: data.name, value: data.loc_id}
    });
    console.log(results)
    return results
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

export const aircraftData = {
  getAircraftDataByLocationId,
  searchAircraftData
};
import React, { useContext, useEffect, useRef, useState } from "react";
import ArrowLight from "../../Icons/ArrowLight";
import {
  formatTimestamp,
  onChangeImage,
  retrieveUserImg,
} from "../../../utils/helpers";
import greenCheck from "../../../images/check-success.svg";
import { ProfileUserContext, UserDataContext } from "../../../utils/store";
import RedButton from "../../Buttons/RedButton";
import ProfilePic from "./ProfilePic";
import { ModalOptions } from "../../../types/Modal";
import volasUser from "../../../images/volas-user.svg";
import { RedBtnImg } from "../../../types/Button";
import { useParams } from "react-router-dom";
import { useModalData } from "../../Modals/hooks/useModalData";
import { TOAST_STATUSES } from "../../../utils/toast_statuses";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { PrivacySetting } from "../../../types/PrivacySetting";

const UserInfo: React.FC<{ logoutUser?: () => Promise<void> }> = (props) => {
  const { logoutUser } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const { setUserData, userData } = useContext(UserDataContext);
  const { id } = useParams();
  const { setProfileUser, profileUser } = useContext(ProfileUserContext);

  const isCurrentUser = userData.id === id;

  const [profilePic, setProfilePic] = useState(volasUser);
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (profileUser.profile_picture) {
      setProfilePic(profileUser.profile_picture);
    } else if (profileUser.profile_picture_key) {
      setProfilePic(retrieveUserImg(profileUser.id));
    }
  }, [profileUser]);

  const onClickImage = () => {
    if (!isCurrentUser) return;
    if (inputRef.current) inputRef.current.click();
  };

  const handlePrivacyModalSubmit = async (privacyOptions: PrivacySetting[]) => {
    try {
      const privacySettings: PrivacySetting[] = privacyOptions.map((option) => {
        return { name: option.name, isVisible: option.isVisible };
      });

      await toast.promise(
        api.users.updatePrivacySettings(privacySettings),
        TOAST_STATUSES.privacySettings
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsOpen(false);
    }
  };

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: ModalOptions.Privacy,
    childModalProps: {
      handleSubmit: handlePrivacyModalSubmit,
    },
  };

  useModalData(modalData);

  return (
    <div className="flex flex-col w-full md:w-44 items-center md:mr-8 mt-4 md:mt-10 md:ml-4">
      <ProfilePic
        inputRef={inputRef}
        onChangeImage={(e) =>
          onChangeImage(e, setUserData, userData, setProfileUser)
        }
        onClickImage={onClickImage}
        profilePic={profilePic}
        styles="w-24 h-24"
      />
      <p className="text-xl text-xxxx-gray mt-2">{profileUser.name}</p>
      <p className="text-welcome-gray mb-2 text-xxs">
        Joined {formatTimestamp(profileUser.created_at)}
      </p>
      {profileUser.roles
        .slice(0, 3)
        .map((role: { id: string; name: string }) => {
          return (
            <p
              className="flex items-center text-xx-green text-xs"
              key={role.id}
            >
              <img
                src={greenCheck}
                alt=""
                className="w-13 mr-2"
                key={role.id}
              />
              {role.name}
            </p>
          );
        })}
      {profileUser.roles.length > 3 && (
        <p className="text-xxxx-gray text-xxs">{`+ ${
          profileUser.roles.length - 3
        } more`}</p>
      )}
      {isCurrentUser && (
        <RedButton
          onClickEvent={() => setIsOpen(true)}
          styles="py-1 md:px-3 my-6 md:my-10 w-44 md:w-52 text-xs md:text-sm"
          title="PRIVACY SETTINGS"
          img={RedBtnImg.Privacy}
          imgBeforeText={true}
        />
      )}
      {isCurrentUser && (
        <button
          className="hidden md:flex text-volas-red w-auto text-sm font-bold items-center cursor-pointer"
          onClick={logoutUser}
        >
          <ArrowLight width="8" styles="w-4 mr-2" fill="#bf2431" />
          LOG OUT
        </button>
      )}
    </div>
  );
};

export default UserInfo;

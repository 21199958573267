import React from "react";
import { ConfirmFinalizeModalProps } from "../../types/Modal";
import { OutlineButton } from "../Buttons/OutlineButton";
import RedButton from "../Buttons/RedButton";

const ConfirmFinalizeModal: React.FC<ConfirmFinalizeModalProps> = (props) => {
  const { confirmMessage, onConfirm, onCancel } = props;

  return (
    <div className="flex flex-col items-center text-xxx-gray justify-center w-full md:w-336 h-312">
      <h2 className="text-2xl text-gray-700 tracking-wider mt-1 mb-5 text-center">
        {confirmMessage}
      </h2>
      <div className="my-6 flex w-full h-12">
        <div className="w-1/2 h-full">
          <RedButton
            onClickEvent={onConfirm}
            styles="text-xs self-center w-full h-full"
            title="Confirm"
          />
        </div>
        <div className="w-2" />
        <div className="w-1/2 h-full">
          <OutlineButton onClick={onCancel} title="Cancel" />
        </div>
      </div>
    </div>
  );
};

export default ConfirmFinalizeModal;

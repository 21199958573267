import React from "react";
import { TailSpin } from "react-loader-spinner";

const Loader: React.FC = () => {
  return (
    <div className="loader">
      <TailSpin height="100" width="100" color="#323234" ariaLabel="loading" />
    </div>
  );
};

export default Loader;

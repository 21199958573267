import React, { SyntheticEvent, useRef, useState } from "react";
import Document from "../Documents/Document";
import {
  DocModalType,
  DocumentObject,
  DocumentType,
  File,
} from "../../types/Document";
import { api } from "../../api";
import Sorting from "../Forms/Sorting";
import { DocumentsType } from "../../types/Profile";
import { SortingOption } from "../../types/Sorting";
import {
  DeleteModalOptions,
  DocModalProps,
  ModalOptions,
} from "../../types/Modal";
import NoDataDiv from "../UI/NoDataDiv";
import { toast } from "react-toastify";
import { TOAST_STATUSES } from "../../utils/toast_statuses";
import { AddResourceButton } from "../Buttons/AddResourceButton";
import { IndexContainer } from "../UI/Containers/IndexContainer";
import { DocumentResourceType } from "../../types/Resource";
import { InvitationRecipient, InvitationTypes } from "../../types/Invitations";
import {
  submitDocument,
  submitTransactionDocument,
} from "../../utils/document.utils";
import { useModalData } from "../Modals/hooks/useModalData";
import { useTransactionPermission } from "../Transactions/hooks/useTransactionPermission";
import { Permission } from "../../types/Permission";

interface DocumentsListProps {
  resourceType: DocumentResourceType;
  resourceId: string;
  pageTitle: string;
  pageSubtitle: string;
  documents: DocumentObject[];
  documentSuggestedTitles: string[];
  sortBy: SortingOption;
  readOnly: boolean;
  isRootView: boolean;
  metadata?: Record<string, any>;
  setDocumentsDirty: (isDirty: boolean) => void;
  setSortBy?: (sortingOption: SortingOption) => void;
  setDocuments: (documents: DocumentObject[]) => void;
}

const DocumentsList: React.FC<DocumentsListProps> = ({
  resourceType,
  resourceId,
  pageTitle,
  pageSubtitle,
  documents,
  setDocumentsDirty,
  setDocuments,
  documentSuggestedTitles,
  sortBy,
  setSortBy,
  readOnly,
  isRootView,
  metadata,
}) => {
  const permission = useTransactionPermission();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [selectedDoc, setSelectedDoc] = useState<DocumentObject>();
  const [docModalType, setDocModalType] = useState(DocModalType.Default);
  const [modalType, setModalType] = useState(ModalOptions.Default);

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const hiddenFileInput2 = useRef<HTMLInputElement>(null);

  const _refreshDocumentList = async () => {
    setIsOpen(false);
    setIsSaving(false);
    setDocModalType(DocModalType.Default);
    setModalType(ModalOptions.Default);
  };

  /*****************************
   * Share Modal Props
   *****************************/

  const handleShareDocument = async (
    recipients: InvitationRecipient[],
    shareType: "aircraft" | "transaction" | "document" | "application",
    selectedResourceId: string
  ) => {
    await toast.promise(
      api.invitations.createInvitations(
        recipients,
        shareType,
        selectedResourceId
      ),
      TOAST_STATUSES.updateDocument
    );
    setModalType(ModalOptions.Success);
  };

  /*****************************
   * Document Modal Props
   *****************************/

  const handleClick = (e: SyntheticEvent) => {
    if (!hiddenFileInput || !hiddenFileInput.current) return;
    hiddenFileInput.current.click();
  };
  const handleClick2 = (e: SyntheticEvent) => {
    if (!hiddenFileInput2 || !hiddenFileInput2.current) return;
    hiddenFileInput2.current.click();
  };

  /**
   * @param documentTitle user facing name of document entity
   * @param docType Document or Docusign
   * @param isSigned whether all parties have signed document
   * @param fileData a File, else the docusign link if saving a docusign doc
   * @param existingDocumentId id if document is new (and not a new version)
   * @param metadata Extra data. Optional
   * @param addAutoTags Whether to add autotags. Optional
   */
  async function _submitDocument(
    documentTitle: string,
    docType: DocumentType,
    isSigned: boolean,
    fileData: File,
    existingDocumentId: string | undefined,
    metadata?: Record<string, any>,
    addAutoTags?: boolean
  ) {
    let _fileData;

    _fileData = fileData.name === "" ? undefined : fileData;

    const document =
      resourceType === DocumentResourceType.Transaction
        ? await submitTransactionDocument(
            documentTitle,
            docType,
            isSigned,
            resourceId,
            metadata!.isRequired!,
            _fileData,
            existingDocumentId
          )
        : await submitDocument(
            documentTitle,
            docType,
            isSigned,
            _fileData!,
            resourceType,
            resourceId,
            existingDocumentId,
            addAutoTags || false
          );

    setDocumentsDirty(true);
  }

  const documentIndex = (docs: DocumentObject[], id: string) => {
    return docs.findIndex((doc) => doc.id === id);
  };

  const updateDocumentTitle = async (updatedDocumentTitle: string) => {
    if (!selectedDoc) return;

    setIsSaving(true);
    try {
      const { id, title } = await toast.promise(
        api.documents.updateDocument(selectedDoc.id, {
          title: updatedDocumentTitle,
        }),
        TOAST_STATUSES.updateDocument
      );

      let docs = documents.slice();

      const docIndex = documentIndex(docs, id);
      docs[docIndex].title = title;
      setDocuments(docs);
      setDocumentsDirty(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
      setIsOpen(false);
    }
  };

  const submitFile = async (
    fileData: File,
    documentTitle: string,
    existingDocumentId: string | undefined,
    isDocusign: boolean,
    isSigned: boolean,
    metadata: Record<string, any>,
    addAutoTags: boolean
  ) => {
    setIsSaving(true);

    try {
      await _submitDocument(
        documentTitle,
        isDocusign ? DocumentType.Docusign : DocumentType.Document,
        isSigned || false,
        fileData,
        existingDocumentId,
        metadata,
        addAutoTags
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
      setIsOpen(false);
    }
  }

  const singularResourceTitle = DocumentsType.Documents.slice(0, -1);

  const deleteUserDocument = async () => {
    if (!selectedDoc) return;

    try {
      await toast.promise(
        api.documents.deleteDocument(selectedDoc.id),
        TOAST_STATUSES.deleteDocument
      );

      const updatedDocuments = documents
        .slice()
        .filter((doc) => doc.id !== selectedDoc.id);

      setDocuments(updatedDocuments);
      setIsOpen(false);
    } catch (_error) {
      const error: any = _error;
      console.error(error);
    }
  };

  /*****************************
   * Modal Props
   *****************************/

  const retrieveChildProps = () => {
    switch (modalType) {
      /**
       * DocumentsList {@link DeleteModal}
       */
      case ModalOptions.DeleteResource:
        return {
          title:
            "Are you sure you want to delete this document and it's version history?",
          deleteModalType: DeleteModalOptions.Document,
          handleSubmit: deleteUserDocument,
        };
      /**
       * DocumentsList {@link InvitationModal}
       */
      case ModalOptions.Share:
        return {
          selectedResourceId: selectedDoc?.id,
          shareType: InvitationTypes.Document,
          handleSubmit: handleShareDocument,
          permissionsList: [Permission.ADMIN, Permission.VIEWER]
        };
      /**
       * DocumentsList {@link DocumentModal}
       */
      case ModalOptions.Document:
        return {
          handleClick,
          handleClick2,
          documentSuggestedTitles,
          hiddenFileInput,
          hiddenFileInput2,
          selectedDoc,
          submitFile,
          docModalType,
          updateDocumentTitle,
          isDocumentNullable: resourceType === DocumentResourceType.Transaction,
          metadata,
          resourceType
        } as DocModalProps;
      /**
       * DocumentsList {@link SuccessModal}
       */
      case ModalOptions.Success:
        return {
          successMessage: "Shared Successfully!",
          buttonTitle: "AWESOME",
          handleSubmit: _refreshDocumentList,
        };
    }
  };

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: modalType,
    childModalProps: retrieveChildProps(),
  };

  useModalData(modalData);

  const showNoDataMessage = (): boolean => {
    return !documents.length;
  };

  const showAddResourceButton = () => {
    if (readOnly) { return false }
    if (metadata && metadata.isRequired) {
      return permission === Permission.ADMIN
    }
    return true;
  }

  return (
    <IndexContainer
      title={pageTitle}
      subTitle={pageSubtitle}
      usePadding={isRootView}
      addResourceButton={
        !showAddResourceButton() ? (
          <></>
        ) : (
          <AddResourceButton
            onClickEvent={() => {
              setIsOpen(true);
              setModalType(ModalOptions.Document);
              setDocModalType(DocModalType.Add);
              setSelectedDoc(undefined);
            }}
            title={singularResourceTitle}
            styles="w-52 my-10"
          />
        )
      }
    >
      {setSortBy && <Sorting setSortBy={setSortBy} sortBy={sortBy} />}

      {showNoDataMessage() && <NoDataDiv />}

      {documents.map((doc: DocumentObject) => {
        return (
          <Document
            key={doc.id}
            document={doc}
            setSelectedDoc={setSelectedDoc}
            setModalType={setModalType}
            setDocModalType={setDocModalType}
            setIsOpen={modalData.setIsOpen}
            readOnly={readOnly}
            isRequiredDoc={metadata && metadata.isRequired}
          />
        );
      })}
    </IndexContainer>
  );
};

export default DocumentsList;

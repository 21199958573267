import React, { useContext, useState } from "react";
import {Link, useParams} from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../api";
import { InvitationRecipient, InvitationTypes } from "../../types/Invitations";
import { ModalOptions } from "../../types/Modal";
import { Permission } from "../../types/Permission";
import { TxUserRole } from "../../types/Transaction";
import { capitalize } from "../../utils/helpers";
import { TransactionContext, UserDataContext } from "../../utils/store";
import { TOAST_STATUSES } from "../../utils/toast_statuses";
import { AddResourceButton } from "../Buttons/AddResourceButton";
import { useModalData } from "../Modals/hooks/useModalData";
import { useTransactionPermission } from "./hooks/useTransactionPermission";
import TransactionHeader from "./TransactionHeader";
import TransactionUser from "./TransactionUser";

const TransactionUsers = () => {
  const { transactionId: id } = useParams();
  const { transaction, setTransaction } = useContext(TransactionContext);
  const { userData } = useContext(UserDataContext);
  const permission = useTransactionPermission();
  const [showPendingOnly, setShowPendingOnly] = useState(false)

  const [, setModalType] = useState(ModalOptions.Default);
  const [modalIsOpen, setIsOpen] = useState(false);

  const users = transaction?.sharedWith?.concat(transaction.sharedWithPending);
  const badges = Object.values(TxUserRole).map((tabName) => tabName);

  const isReadyOnly = transaction.is_complete;
  const isCurrentUserCreator = transaction.created_by === userData.id

  const handleShowPendingOnly = () => {
    setShowPendingOnly(!showPendingOnly)
  }

  const refreshTransactionUsers = async () => {
    await retrieveTransaction();
    setIsOpen(false);
  };

  const retrieveTransaction = async () => {
    try {
      if (!id) return;
      const _transaction = await api.transactions.getTransaction(id);
      setTransaction(_transaction);
    } catch (error) {
      console.error(error);
    }
  };

  const handleShareTransaction = async (
    recipients: InvitationRecipient[],
    shareType: "aircraft" | "transaction" | "document" | "application",
    selectedResourceId: string
  ) => {
    try {
      await toast.promise(
        api.invitations.createInvitations(
          recipients,
          shareType,
          selectedResourceId
        ),
        TOAST_STATUSES.addUserToTransaction
      );
      refreshTransactionUsers();
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const generatePermissionsList = (): Permission[] => {
    if (transaction && transaction.created_by === userData.id) {
      return [Permission.ADMIN, Permission.VIEWER];
    }
    if (permission === Permission.ADMIN) {
      return [Permission.VIEWER];
    }
    return [];
  };

  /**
   * TransactionUsers {@link InvitationModal}
   */
  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: ModalOptions.Share,
    childModalProps: {
      selectedResourceId: transaction.id,
      shareType: InvitationTypes.TransactionUser,
      handleSubmit: handleShareTransaction,
      permissionsList: generatePermissionsList(),
      existingSharesEmails: users ? users.map(user => user.email) : []
    }
  };

  useModalData(modalData);

  const txUserRoles = () => {
    if (!users) return [];

    const currentUserRoles = users
      .filter(u => u.id)
      .flatMap((u) => u.transactionRoles.map((r) => capitalize(r.name)));

    return badges.filter((role) => currentUserRoles.includes(role));
  };

  return (
    <div className="max-w-865 flex flex-col rel">
      <TransactionHeader
        title="Transaction Parties"
        subTitle="The Transaction Parties are everyone involved in this transaction."
        badges={badges}
        completedBadges={txUserRoles()}
        userRole={TxUserRole.Seller}
        sectionCompleted={txUserRoles().length === badges.length}
      />
      <div className="flex justify-between">
        {users?.some(user => !user.id) && (
          <div onClick={handleShowPendingOnly} className="cursor-pointer mt-16 ml-2 text-red-600 text-xs">
            {showPendingOnly ? "Show All Users" : "Show Pending Invites Only"}
          </div>
        )}
        {!isReadyOnly && permission === Permission.ADMIN && (
          <AddResourceButton
            onClickEvent={() => {
              setModalType(ModalOptions.Share);
              setIsOpen(true);
            }}
            title="User"
            styles="w-36 mt-8 mr-1 self-end"
          />
        )}
      </div>

      {users?.filter((user) => showPendingOnly ? !user.id: user).map((user) => {
        return (
          <div key={user.email} className="flex mt-4 mb-3 mx-1">
            <TransactionUser
              user={user}
              showRemoveUser={isCurrentUserCreator}
              readOnly={isReadyOnly}
              pending={!user.id}
              onDelete={refreshTransactionUsers}
            />
          </div>
        );
      })}
    </div>
  );
};

export default TransactionUsers;

import React, { useContext, useEffect, useRef, useState } from "react";
import { TxDocumentType } from "../../types/Transaction";
import TransactionHeader from "./TransactionHeader";
import { DocumentObject, DocumentSuggestedTitleType, } from "../../types/Document";
import { api } from "../../api";

import { handleSortData } from "../../utils/document.utils";
import { DocumentResourceType } from "../../types/Resource";
import DocumentsList from "../Documents/DocumentsList";
import { SortingOption } from "../../types/Sorting";
import Badges from "./Badges";
import { TransactionContext } from "../../utils/store";

// TODO -- refactor to use DocumentLoader
// There are a few differences that make refactor tricky (badges, tx header)
const TransactionDocuments: React.FC<{
  txDocumentType: TxDocumentType,
}> = (props) => {
  const { txDocumentType } = props;
  const isInitialMount = useRef(true);

  const { transaction, setRequiredDocuments } = useContext(TransactionContext);
  
  const [documentSuggestedTitles, setDocumentSuggestedTitles] = useState<string[]>([]);

  const [documents, setDocuments] = useState<DocumentObject[]>([]);
  const [sortBy] = useState(SortingOption.DateDESC);

  const [documentsDirty, setDocumentsDirty] = useState<boolean>(false)

  const docBadges: string[] = [];
  const completedDocBadges: string[] = [];

  Object.values(documents).forEach((d) => {
    docBadges.push(d.title);
    if (d.is_docusign) {
      // Docusign docs must be signed to be considered complete
      if (d.is_signed && d.file_name) {
        completedDocBadges.push(d.title);
      }
    } else {
      if (d.file_name) {
        completedDocBadges.push(d.title);
      }
    }
  });

  const retrieveDocumentSubtitle = () => {
    switch (txDocumentType) {
      case TxDocumentType.Required:
        return "Documents required for completing this transactions. An FAA Bill of Sale and FAA Registration are required and have already been added.";
      case TxDocumentType.Optional:
        return "Optional documents you can upload to this transaction.";
    }
  };

  const loadTransactionDocuments = async () => {
    const txDocs = await api.transactions.getTransactionDocuments(transaction.id, txDocumentType)
    setDocuments(handleSortData(sortBy, txDocs))

    if (txDocumentType === TxDocumentType.Required) {
      setRequiredDocuments(txDocs)
    }
  }


  useEffect(() => {
    if (transaction.id) {
      loadTransactionDocuments();
    }
  }, [transaction]);

  useEffect(() => {
    (async () => {
      try {
        const documentSuggestedTitles =
          await api.documentSuggestedTitles.getDocumentSuggestedTitlesByType(
            DocumentSuggestedTitleType.TRANSACTION
          );

        setDocumentSuggestedTitles(documentSuggestedTitles);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setDocuments(handleSortData(sortBy, documents));
    }
  }, [sortBy, documentsDirty]);

  useEffect(() => {
    if (documentsDirty) {
      loadTransactionDocuments();
      setDocumentsDirty(false);
    }
  }, [documentsDirty]);

  const isReadyOnly = transaction.is_complete;

  return (
    <div className="flex flex-col max-w-865">
      <TransactionHeader
        title={`${txDocumentType} Documents`}
        subTitle={retrieveDocumentSubtitle()}
        badges={txDocumentType === TxDocumentType.Required ? docBadges : undefined}
        sectionCompleted={false}
      />

      <Badges badges={docBadges} completedBadges={completedDocBadges} />

      <DocumentsList
        resourceType={DocumentResourceType.Transaction}
        resourceId={transaction.id}
        pageTitle={""}
        pageSubtitle={""}
        documents={documents}
        setDocuments={setDocuments}
        setDocumentsDirty={setDocumentsDirty}
        documentSuggestedTitles={documentSuggestedTitles}
        sortBy={sortBy}
        readOnly={isReadyOnly}
        isRootView={false}
        metadata={{ isRequired: txDocumentType === TxDocumentType.Required }}
      />
    </div >
  );
};

export default TransactionDocuments;

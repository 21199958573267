import { useEffect, useState } from "react";
import {
  TransactionObject,
  TransactionRole,
} from "../../../types/Transaction";
import { DocumentObject } from "../../../types/Document";

export const useTransactionProgress = (
  transaction: TransactionObject,
  requiredDocuments: DocumentObject[]
) => {
  const [transactionProgress, setTransactionProgress] = useState<number>(0);
  const [rolesProgress, setRolesProgress] = useState<number[]>([0, 3]);
  const [approvalsProgress, setApprovalsProgress] = useState<number[]>([0, 5]);
  const [documentsProgress, setDocumentsProgress] = useState<number[]>([0, requiredDocuments.length]);

  useEffect(() => {
    if (!transaction) return;

    // Denominator
    let denominator = 0
    let numerator = 0
    let completedRoles = 0
    let completedApprovals = 0
    let completedDocuments = 0

    // handle roles
    denominator += 3
    const roleNames = transaction?.sharedWith?.flatMap((x) =>
      x.transactionRoles.map((r) => r.name)
    );
    if (roleNames?.includes(TransactionRole.Buyer)) {
      completedRoles += 1
    }
    if (roleNames?.includes(TransactionRole.Seller)) {
      completedRoles += 1
    }
    if (roleNames?.includes(TransactionRole.Escrow)) {
      completedRoles += 1
    }
    setRolesProgress([completedRoles, 3])
    numerator += completedRoles

    // handle transaction checked fields
    denominator += 5
    if (transaction.is_forms_filled) {
      completedApprovals += 1
    }
    if (transaction.is_deposit_state) {
      completedApprovals += 1
    }
    if (transaction.is_disbursement_funds) {
      completedApprovals += 1
    }
    if (transaction.is_seller_approval) {
      completedApprovals += 1
    }
    if (transaction.is_funds_received) {
      completedApprovals += 1
    }
    setApprovalsProgress([completedApprovals, 5])
    numerator += completedApprovals

    denominator += requiredDocuments.length

    for (let doc of requiredDocuments) {
      if (doc.is_docusign) {
        if (doc.file_key && doc.is_signed) {
          completedDocuments += 1
        }
      } else {
        if (doc.file_key) {
          completedDocuments += 1
        }
      }
    }
    numerator += completedDocuments
    setDocumentsProgress([completedDocuments, requiredDocuments.length])

    setTransactionProgress(Math.floor((numerator / denominator) * 100));
  }, [transaction, requiredDocuments]);

  return { transactionProgress, rolesProgress, approvalsProgress, documentsProgress };
};

import { UserResourceType } from "../types/Resource";
import { UserObject } from "../types/User";
import { get, post } from "./axios";
import { clearTokens, setAccessToken, setRefreshToken } from "./local-storage";

/**
 * Verifies a token without deleting it
 *
 * @param token the token to be verified
 */
const verifyToken = async (token: string): Promise<void> => {
  try {
    await get(`auth/verify-token/${token}`);
  } catch (error: any) {
    clearTokens();
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Registers a new user
 *
 * @param name
 * @param email
 * @param password
 * @param passwordConfirm
 * @param phone
 * @param inviteToken
 * @returns the user id, access token, and refresh token
 */
const register = async (
  name: string,
  password: string,
  passwordConfirm: string,
  phone: string,
  inviteToken: string
): Promise<LoginDto> => {
  try {
    const res = await post(
      "/auth/register",
      {
        user: { name, password, passwordConfirm, phone },
        inviteToken,
      },
      {
        headers: {
          Authorization: inviteToken,
        },
      }
    );

    const { user, tokens, resource } = res.data;

    setAccessToken(tokens.access.token);
    setRefreshToken(tokens.refresh.token);

    return { user, tokens, resource };
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Logs in a user
 *
 * @param email the user's email
 * @param password the user's password
 * @returns the user id, the access token, and the refresh token
 */
const login = async (email: string, password: string): Promise<LoginDto> => {
  try {
    const res = await post("/auth/login", {
      email,
      password,
    });

    const { user, tokens } = res.data;

    setAccessToken(tokens.access.token);
    setRefreshToken(tokens.refresh.token);

    return { user, tokens };
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Logs out a user by deleting the refresh token
 *
 * @param refreshToken the refresh token
 */
const logout = async (refreshToken: string): Promise<void> => {
  try {
    await post("/auth/logout", { refreshToken });
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  } finally {
    clearTokens();
  }
};

/**
 * Refreshes the access and refresh tokens given the current refresh token
 *
 * @param refreshToken the refresh token
 * @returns new access and refresh tokens
 */
const refreshToken = async (refreshToken: string): Promise<TokenObject> => {
  try {
    const res = await post("/auth/refresh-tokens", { refreshToken });
    const tokens = res.data;
    setAccessToken(tokens.access.token);
    setRefreshToken(tokens.refresh.token);
    return tokens;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Resets a user's password if provided a valid reset password token
 *
 * @param token the reset password token
 * @param password
 * @param passwordConfirm
 */
const resetPassword = async (
  token: string,
  password: string,
  passwordConfirm: string
): Promise<void> => {
  try {
    await post(`/auth/reset-password?token=${token}`, {
      password,
      passwordConfirm,
    });
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Request an email from the backend to reset the user's password
 *
 * @param email the user's email
 */
const forgotPassword = async (email: string): Promise<void> => {
  try {
    await post("/auth/forgot-password", { email });
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Sends an email to the current user to verify that they own that email.
 *
 * Email address is obtained from the user object in the db.
 * User is obtained from the access token automatically provided in the request.
 */
const sendVerificationEmail = async (): Promise<void> => {
  try {
    await post("/auth/send-verification-email");
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Verifies a user's email when provided with a valid email verification token
 *
 * A valid email verification token can be obtained by calling the
 * sendVerificationEmail method. The email sent to the user will contain a link
 * with a token under the query parameters. When clicked, the user should be
 * redirected to a page which contains a component that calls this method with
 * that token provided in the link.
 *
 * @param token The email verification token
 */
const verifyEmail = async (token: string): Promise<void> => {
  try {
    await post(`/auth/verify-email?token=${token}`);
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Sends an invite to a user given his name, email
 *
 * @param email the user's email
 * @param name the user's name
 * @param permission permission level to be given to the new user
 */
const sendInvite = async (
  email: string,
  name: string,
  permission: string
): Promise<void> => {
  try {
    await post("/auth/send-invite", { email, name, permission });
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

// Local types
interface TokenObject {
  access: {
    token: string;
    expires: Date;
  };
  refresh: {
    token: string;
    expires: Date;
  };
}

export interface InvitedResource {
  id: string;
  name: string;
  resourceType: UserResourceType,
  permission: string,
  parentId?: string,
}

export interface LoginDto {
  user: UserObject;
  tokens: TokenObject;
  resource?: InvitedResource;
}

export const auth = {
  verifyToken,
  register,
  login,
  logout,
  refreshToken,
  resetPassword,
  forgotPassword,
  sendVerificationEmail,
  verifyEmail,
  sendInvite,
};

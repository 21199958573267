import React, {useCallback, useContext, useEffect, useState} from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../../components/Errors/PageNotFound";
import {NetworkWrapper} from "../../components/Network/NetworkWrapper";
import {NetworkInvitations} from "../../components/Network/NetworkInvitations";
import {NetworkIndex} from "../../components/Network/NetworkIndex";
import {api} from "../../api";
import {InvitationContext, UserDataContext} from "../../utils/store";
import UserInvitation from "../../models/Invitation";

export const NetworkPage = () => {
  const [ incomingInvitations, setIncomingInvitations ] = useState([] as UserInvitation[]);
  const { userData } = useContext(UserDataContext);

  const getInvitations = useCallback(async () => {
    try {
      const incomingInvites = await api.invitations.getIncomingApplicationInvitations();
      const allInvites = UserInvitation.createInvitedUsers(
        incomingInvites,
        userData.email
      );

      setIncomingInvitations(allInvites)
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getInvitations();
  }, [getInvitations])

  return (
    <InvitationContext.Provider value={{ incomingInvitations, setIncomingInvitations }}>
      <NetworkWrapper>
        <Routes>
          <Route path="/my-network" element={<NetworkIndex />} />
          <Route path="/invitations" element={<NetworkInvitations />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </NetworkWrapper>
    </InvitationContext.Provider>
  );
};

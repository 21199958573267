import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Aircraft from "../../pages/Aircraft";
import PageNotFound from "../Errors/PageNotFound";
import NavMenu from "../NavigationMenu/NavMenu";
import PrivateRoute from "../Auth/PrivateRoute";
import Profile from "../../pages/Profile";
import Paperwork from "../../pages/Paperwork";
import Transactions from "../../pages/Transactions";
import AdminPage from "../../pages/Admin/AdminPage";
import { AuthHeader } from "../Auth/AuthHeader";
import {NetworkPage} from "../../pages/Network/NetworkPage";

const Layout = () => {
  const {pathname} = useLocation();
  const navPaths = ["profile", "document", "transactions", "aircraft", "admin", "network"];
  const validPath = (path: string) => pathname.includes(path);

  return (
    <>
      <AuthHeader />
      <div className="flex flex-col md:flex-row w-full">
        {navPaths.some(validPath) && <NavMenu/>}
        <Routes>
          <Route
            path="/profile/:id/*"
            element={
              <PrivateRoute>
                <Profile/>
              </PrivateRoute>
            }
          />
          <Route
            path="/documents/*"
            element={
              <PrivateRoute>
                <Paperwork/>
              </PrivateRoute>
            }
          />
          <Route
            path="/aircraft/*"
            element={
              <PrivateRoute>
                <Aircraft/>
              </PrivateRoute>
            }
          />
          <Route
            path="/transactions/*"
            element={
              <PrivateRoute>
                <Transactions/>
              </PrivateRoute>
            }
          />
          <Route
            path="/network/*"
            element={
              <PrivateRoute>
                <NetworkPage/>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/*"
            element={
              <PrivateRoute isAdminOnly>
                <AdminPage/>
              </PrivateRoute>
            }
          />
          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
      </div>
    </>
  );
};

export default Layout;

import React from "react";
interface TagProps {
  title: string;
  styles: string;
  handleClick?(value?: any): void;
  icon?: string;
  isAdd?: boolean;
}

const Tag: React.FC<TagProps> = (props) => {
  const { title, styles, icon, isAdd } = props;

  const handleAction = () => {
    !!props.handleClick && props.handleClick(title);
  };

  const paddingRight = icon ? "pr-1 md:pr-2" : "px-1";
  const cursor = !icon ? "cursor-pointer" : "cursor-default";
  return (
    <div
      className={`${styles} flex justify-center w-auto border items-center mx-1 my-1 text-xxs md:text-xs rounded-full pl-2 ${paddingRight} ${cursor}`}
      onClick={!icon || isAdd ? handleAction : () => null}
    >
      <span className="mr-1">{title}</span>
      {icon && <img alt="" src={icon} onClick={!isAdd ? handleAction : undefined} className="cursor-pointer" />}
    </div>
  );
};

export default Tag;

import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";

import {
  DocumentObject,
  DocumentSuggestedTitleType,
} from "../../types/Document";

import { DocumentResourceType } from "../../types/Resource";
import { UserDataContext } from "../../utils/store";
import { api } from "../../api";
import { handleSortData } from "../../utils/document.utils";
import { useParams } from "react-router-dom";
import { SortingOption } from "../../types/Sorting";
import { DocumentsType } from "../../types/Profile";
import DocumentsList from "./DocumentsList";

interface DocumentsLoaderProps {
  resourceType: DocumentResourceType;
}

const DocumentsLoader = ({ resourceType }: DocumentsLoaderProps) => {
  const [documents, setDocuments] = useState<DocumentObject[]>([]);
  const [documentsDirty, setDocumentsDirty] = useState<boolean>(false);
  const [documentSuggestedTitles, setDocumentSuggestedTitles] = useState<
    string[]
  >([]);
  const [pageTitle, setPageTitle] = useState<string>("");
  const [pageSubtitle, setPageSubtitle] = useState<string>("");
  const [sortBy, setSortBy] = useState(SortingOption.DateDESC);

  const { id } = useParams();
  const isInitialMount = useRef(true);

  const { userData } = useContext(UserDataContext);

  const readOnly =
    resourceType === DocumentResourceType.Profile && id !== userData.id;

  const loadProfileDocs = useCallback(async () => {
    const docs = await api.users.getCurrentUserProfileDocuments();
    setDocuments(handleSortData(sortBy, docs));
    setPageTitle("Briefcase");
    setPageSubtitle("Upload and manage your personal documentation.");
  }, []);

  const loadAircraftDocs = useCallback(async () => {
    const docs = await api.aircraft.getAircraftDocuments(id!);
    setDocuments(handleSortData(sortBy, docs));
    setPageTitle(`Aircraft ${DocumentsType.Documents}`);
    setPageSubtitle("Upload and manage all aircraft documents.");
  }, []);

  const loadSuggestedTitles = useCallback(
    async (titleType: DocumentSuggestedTitleType) => {
      const documentSuggestedTitles =
        await api.documentSuggestedTitles.getDocumentSuggestedTitlesByType(
          titleType
        );
      setDocumentSuggestedTitles(documentSuggestedTitles);
    },
    []
  );

  const loadDocumentsByType = async () => {
    let titleType: DocumentSuggestedTitleType;

    try {
      // Load Documents
      switch (resourceType) {
        case DocumentResourceType.Profile:
          await loadProfileDocs();
          titleType = DocumentSuggestedTitleType.PROFILE_DOC;
          break;

        case DocumentResourceType.Aircraft:
          await loadAircraftDocs();
          titleType = DocumentSuggestedTitleType.AIRCRAFT;
          break;
      }

      // Load suggested titles
      await loadSuggestedTitles(titleType!);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    (async () => {
      loadDocumentsByType();
    })();
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setDocuments(handleSortData(sortBy, documents));
    }
  }, [sortBy]);

  useEffect(() => {
    (async () => {
      if (documentsDirty) {
        await loadDocumentsByType();
        setDocumentsDirty(false);
      }
    })();
  }, [documentsDirty]);

  return (
    <DocumentsList
      resourceType={resourceType}
      resourceId={id!}
      pageTitle={pageTitle}
      pageSubtitle={pageSubtitle}
      documents={documents}
      setDocuments={setDocuments}
      setDocumentsDirty={setDocumentsDirty}
      documentSuggestedTitles={documentSuggestedTitles}
      sortBy={sortBy}
      setSortBy={setSortBy}
      readOnly={readOnly}
      isRootView={true}
    />
  );
};

export default DocumentsLoader;

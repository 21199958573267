import React from "react";
import { NavIcon } from "../../types/Icons";

const ProfileIcon: React.FC<NavIcon> = (props) => {
  const { fill, styles } = props;
  return (
    <svg
      className={styles}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.631 19.093H6.53v-1.195c0-1.708 3.7-2.647 5.551-2.647 1.85 0 5.551.94 5.551 2.647v1.195zM12.08 8.849c1.536 0 2.775 1.144 2.775 2.561s-1.24 2.56-2.775 2.56c-1.536 0-2.776-1.143-2.776-2.56s1.24-2.561 2.776-2.561zm0-3.415c.509 0 .925.385.925.854 0 .47-.416.854-.925.854-.509 0-.925-.384-.925-.854s.416-.854.925-.854zm6.476 0H14.69c-.389-.99-1.406-1.707-2.609-1.707s-2.22.717-2.609 1.707H5.604c-1.018 0-1.85.769-1.85 1.708v11.95c0 .94.832 1.708 1.85 1.708h12.952c1.018 0 1.85-.768 1.85-1.707V7.142c0-.94-.832-1.708-1.85-1.708z"
        fill={fill}
      />
    </svg>
  );
};

export default ProfileIcon;

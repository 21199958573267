import React, { useContext, useEffect, useState } from "react";
import { AircraftModalTypes, AircraftObject } from "../../types/Aircraft";
import { AircraftFormData, ModalOptions } from "../../types/Modal";
import { SortingOption } from "../../types/Sorting";
import { handleSortData } from "../../utils/document.utils";
import { FleetContext } from "../../utils/store";
import Sorting from "../Forms/Sorting";
import NoDataDiv from "../UI/NoDataDiv";
import Aircraft from "./Aircraft";
import { AddResourceButton } from "../Buttons/AddResourceButton";
import { IndexContainer } from "../UI/Containers/IndexContainer";
import { useModalData } from "../Modals/hooks/useModalData";
import { api } from "../../api";
import { toast } from "react-toastify";
import { TOAST_STATUSES } from "../../utils/toast_statuses";
import { useNavigate } from 'react-router-dom';

const Fleet = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [sortBy, setSortBy] = useState(SortingOption.DateDESC);
  const [sortedAircrafts, setSortedAircrafts] = useState<AircraftObject[]>([]);

  const { fleet } = useContext(FleetContext);
  const navigate = useNavigate();

  const handleCreateAircraft = async (aircraftData: AircraftFormData) => {
    try {
      const aircraft = await toast.promise(
        api.aircraft.createAircraft(
          aircraftData.title,
          aircraftData.type,
          aircraftData.base,
          aircraftData.tailNumber,
          aircraftData.serialNumber,
          aircraftData.files,
          aircraftData.addAsOwner
        ),
        TOAST_STATUSES.saveAircraft
      );

      navigate(`/aircraft/${aircraft.id}`)
    } catch (error) {
      console.error(error);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (fleet.length) {
      const sortedAircrafts = handleSortData(sortBy, fleet);
      setSortedAircrafts(sortedAircrafts);
    }
  }, [sortBy, fleet]);

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: ModalOptions.Aircraft,
    childModalProps: {
      handleSubmit: handleCreateAircraft,
      modalType: AircraftModalTypes.Add,
    },
  };

  useModalData(modalData);

  return (
    <IndexContainer
      title="My Fleet"
      subTitle="Your collection of aircraft."
      addResourceButton={
        <AddResourceButton
          onClickEvent={() => {
            setIsOpen(true);
          }}
          title="Aircraft"
          styles="w-52 my-10"
        />
      }
    >
      <Sorting setSortBy={setSortBy} sortBy={sortBy} />

      {!sortedAircrafts.length && <NoDataDiv />}

      {sortedAircrafts.map((aircraft: AircraftObject) => {
        return <Aircraft key={aircraft.id} aircraft={aircraft} />;
      })}
    </IndexContainer>
  );
};

export default Fleet;

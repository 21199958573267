import React, {useCallback, useEffect, useState} from "react";
import { IndexContainer } from "../UI/Containers/IndexContainer";
import NoDataDiv from "../UI/NoDataDiv";
import {api} from "../../api";

function pascalToSpaces(str: string) {
  return str
    // Insert a space before all uppercase letters except for the first letter
    .replace(/(?<!^)([A-Z])/g, ' $1')
    // Trim any starting or ending spaces
    .trim();
}
export const Analytics = () => {
  const [analytics, setAnalytics] = useState([] as any);

  const getAnalytics = useCallback(async () => {
    try {
      const analytics = await api.analytics.getAnalytics();
      setAnalytics(analytics)
    } catch (error) {
      console.error(error);
    }
  }, []);

  const showNoDataMessage = !analytics.length;

  useEffect(() => {
    getAnalytics();
  }, [])

  return (
    <>
      <IndexContainer
        title="Analytics"
        subTitle={"List of Volas data analytics"}
        addResourceButton={undefined}
      >
        {showNoDataMessage && <NoDataDiv />}

        {analytics.map((obj: any) => {
          return (
            <div
              className="my-1 text-sm w-full"
              key={Object.keys(obj)[0]}
            >
              {pascalToSpaces(Object.keys(obj)[0])}: {obj[Object.keys(obj)[0]]}
            </div>
          );
        })}
      </IndexContainer>
    </>
  );
};

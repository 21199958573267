import React from "react";

interface TabContainerProps {
  header: JSX.Element;
  tabs: JSX.Element;
  children: React.ReactNode;
}

/**
 * Responsible for rendering the container holding page titles,
 * the tabs on pages, and the content inside the page.
 */
export const TabContainer = ({
  header,
  tabs,
  children,
}: TabContainerProps) => {
  return (
    <div className="md:ml-44 relative h-screen w-screen bg-white">
      <div className="flex flex-col items-center absolute h-full w-full">
        {header}
        <div className="flex flex-col h-full w-full mt-3 bg-neutral-gray">
          {tabs}
          <div className="flex-col w-full bg-neutral-gray pb-48 md:pb-10">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

import { Status } from "../../../../types/Transaction";
import greenCheck from "../../../../images/check-success.svg";
import linkArrow from "../../../../images/arrow-diangle-up.svg";
import DocumentVersions from "../../../Documents/DocumentVersions";
import { DocumentObject } from "../../../../types/Document";
import { formatTimestamp } from "../../../../utils/helpers";
import { Link } from "react-router-dom";
import { UploadOrDocusign } from "../../../Documents/UploadOrDocusign";
import {FiPenTool} from "react-icons/fi";
import React from "react";
interface ResourceLeftProps {
  title: string;
  timestamp?: string;
  onClick: (...args: any) => void;
  subTitle?: string;
  titleExtension?: string;
  status?: Status;
  documentVersions?: DocumentObject[];
  onViewFile?: (id: string) => Promise<void>;
  documentLink?: string;
  documentLinkLabel?: string;
  rolesComponent?: JSX.Element;
}

const ResourceLeft = ({
  title,
  timestamp,
  onClick,
  subTitle,
  titleExtension,
  status,
  documentVersions,
  onViewFile,
  documentLink,
  documentLinkLabel,
  rolesComponent
}: ResourceLeftProps) => {
  const hasNoFile =
    documentVersions?.length === 1 && !documentVersions[0].file_name;

  const showDocumentVersions = !!(
    documentVersions &&
    documentVersions.length > 0 &&
    !hasNoFile
  );

  const DocumentLinkDisplay = () => {
    if (documentLink && documentLinkLabel) return (
      <Link
        to={documentLink}
        className="flex items-center text-xs text-welcome-gray underline mb-1 mt-2"
      >
        <span className="mr-2">{documentLinkLabel}</span>
        <img alt="" src={linkArrow}/>
      </Link>
    )

    if (documentLinkLabel) return (
      <div className="flex items-center text-xs text-welcome-gray mb-1 mt-2">
        <span className="mr-2">{documentLinkLabel}</span>
      </div>
    )

    return <></>
  }

  const DocumentVersionsDisplay = () =>
    showDocumentVersions ? (
      <div className="md:hidden mb-2 mt-2">
        <DocumentVersions
          documentVersions={documentVersions!}
          onViewFile={onViewFile!}
        />
      </div>
    ) : null;

  const UploadOrDocusignDisplay = () =>
    hasNoFile ? (
      <div className="md:hidden mb-2 mt-2">
        <UploadOrDocusign
          label={documentVersions![0].is_docusign ? "e-Sign" : "Upload"}
          onTap={() => onClick()}
        />
      </div>
    ) : null;

  const TitleExtensionDisplay = () =>
    titleExtension ? (
      <div className="text-welcome-gray ml-2 md:ml-5">{titleExtension}</div>
    ) : null;

  const StatusDisplay = () =>
    status ? (
      <div className="flex text-xs italic text-xxxxx-gray mt-6">
        {status === Status.Complete && (
          <img src={greenCheck} alt="" className="mr-2" />
        )}
        {status}
      </div>
    ) : null;

  const SubTitleDisplayDisplay = () =>
    subTitle ? (
      <div className="text-xxxs text-volas-red tracking-normal leading-4">
        {subTitle}
      </div>
    ) : null;

  return (
    <div className="flex flex-col w-full h-full justify-center md:justify-evenly">
      <UploadOrDocusignDisplay />
      <div
        onClick={onClick}
        className="flex flex-col text-gray-800 font-semibold cursor-pointer tracking-widest text-xs md:text-sm mb-1 md:mb-0 md:font-bold"
      >
        <div className="flex items-center justify-between w-2/3">
          <div className="flex items-center">
            {title.includes("(e-Sign)") && <FiPenTool fontSize={14} className="mr-2" />}
            {title}
          </div>
          <TitleExtensionDisplay />
        </div>
        <SubTitleDisplayDisplay />
      </div>
      {
        timestamp && (
        <h1 className="text-gray-400 text-xs mt-1">
          {formatTimestamp(timestamp)}
        </h1>
        )
      }
      <StatusDisplay />
      <DocumentVersionsDisplay />
      <DocumentLinkDisplay />
      { rolesComponent }
    </div>
  );
};

export default ResourceLeft;

import React from "react";
import { Link } from "react-router-dom";
import { DocumentResourceType } from "../../types/Resource";
import volasLogo from "../../images/logo.svg";
import ArrowLight from "../Icons/ArrowLight";
import { PageHeader } from "../../types/UI";
interface TabPageHeaderProps {
  pageTitle: PageHeader | string;
}

export const TabPageHeader = ({ pageTitle }: TabPageHeaderProps) => {
  return (
    <div className="flex flex-col w-full justify-center md:justify-start items-center md:items-start my-10 md:my-14 uppercase">
      <img src={volasLogo} alt="" className="md:hidden w-5" />
      <h2 className="select-none md:ml-16 mt-3 md:mt-0 text-sm text-xxx-red tracking-widest font-semibold h-5">
        {pageTitle}
      </h2>
    </div>
  );
};

interface TabSubPageHeaderProps {
  pageTitle: DocumentResourceType;
  tabName: string;
};

export const TabSubPageHeader = ({
  pageTitle,
  tabName,
}: TabSubPageHeaderProps) => {
  return (
    <div className="flex flex-col md:flex-row items-center select-none self-center md:self-start ml-3 md:ml-16 my-10 md:my-14 text-xs md:text-sm text-xxx-red tracking-widest font-semibold">
      <div className="md:hidden flex flex-col items-center">
        <img src={volasLogo} alt="" className="w-17" />
        <h2 className="my-3 text-sm">{pageTitle}</h2>
      </div>
      <div className="flex">
        <p className="text-welcome-gray">AIRCRAFT</p>
        <ArrowLight
          styles="transform rotate-180 mx-2"
          fill="#8C8C8C"
          width="8px"
        />
        <Link to="/aircraft" className="text-welcome-gray cursor-pointer">
          {tabName}
        </Link>
      </div>
    </div>
  );
};

import React, {useCallback, useContext, useEffect, useState} from "react";
import { api } from "../../api";
import { IndexContainer } from "../UI/Containers/IndexContainer";
import NoDataDiv from "../UI/NoDataDiv";
import InvitationCard from "../Admin/Invitation";
import UserInvitation from "../../models/Invitation";
import {InvitationContext, UserDataContext} from "../../utils/store";
import {DeleteModalOptions, ModalOptions} from "../../types/Modal";
import {InvitationRecipient, InvitationTypes} from "../../types/Invitations";
import {toast} from "react-toastify";
import {useModalData} from "../Modals/hooks/useModalData";
import {AddResourceButton} from "../Buttons/AddResourceButton";
import {useGetNetworkUsers} from "../../hooks/useGetNetworkUsers";

export const NetworkInvitations = () => {
  const [ userIncomingInvitations, setUserIncomingInvitations ] = useState([] as UserInvitation[]);
  const [ userOutgoingInvitations, setUserOutgoingInvitations ] = useState([] as UserInvitation[]);
  const [selectedInvitation, setSelectedInvitation] =
    useState<UserInvitation>();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(ModalOptions.Default);
  const { setIncomingInvitations } = useContext(InvitationContext);

  const { userData } = useContext(UserDataContext);
  const [networkUsers] = useGetNetworkUsers();

  const getInvitations = useCallback(async () => {
    try {
      const outgoingInvites = await api.invitations.getOutgoingApplicationInvitations();
      const incomingInvites = await api.invitations.getIncomingApplicationInvitations();

      const allInvites = UserInvitation.createInvitedUsers(
        outgoingInvites.concat(incomingInvites),
        userData.email
      );
      const incomingInvitations = allInvites.filter(invite => invite.isIncomingInvite)
      setUserIncomingInvitations(incomingInvitations);
      // Set global tab count
      // TODO -- shouldnt need this and previous line, combine so previous one uses context
      setIncomingInvitations(incomingInvitations)
      setUserOutgoingInvitations(allInvites.filter(invite => !invite.isIncomingInvite));

    } catch (error) {
      console.error(error);
    }
  }, []);

  const acceptInvitation = useCallback(async (token: string) => {
    try {
      await api.invitations.acceptResourceInvite(token)
      setIsOpen(true);
      setModalType(ModalOptions.Success);
    } catch (error) {
      console.error(error);
    }
  }, [])

  const refreshInvitations = async () => {
    await getInvitations();
    setIsOpen(false);
    setModalType(ModalOptions.Default);
  };

  const handleDelete = async () => {
    try {
      await api.invitations.deleteInvitation(selectedInvitation!.invitationId);
      await refreshInvitations();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (
    recipients: InvitationRecipient[],
    shareType: InvitationTypes,
    selectedResourceId: string
  ) => {
    try {
      await toast.promise(
        api.invitations.createInvitations(
          recipients,
          shareType,
          selectedResourceId
        ),
        {
          error:
            "There was a problem, inviting user. please check that entered email and try again.",
          success: "User Invited",
        }
      );
      await refreshInvitations();
    } catch (error) {
      console.error(error);
    }
  };

  const retrieveChildProps = () => {
    switch (modalType) {
      case ModalOptions.Share:
        return {
          selectedResourceId: "0",
          shareType: InvitationTypes.ApplicationUser,
          handleSubmit,
          existingNetworkEmails: networkUsers.map(user => user.email)
        };
      case ModalOptions.Success:
        return {
          successMessage: `You successfully accepted the invitation!`,
          buttonTitle: "Close",
          handleSubmit: refreshInvitations,
        };
      case ModalOptions.DeleteInvite:
        return {
          title: `Delete invite to ${selectedInvitation!.invitedUserEmail}?`,
          deleteModalType: DeleteModalOptions.Invitation,
          handleSubmit: handleDelete,
        };
    }
  };

  /**
   * NetworkInvitations {@link InvitationModal}
   */
  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: modalType,
    childModalProps: retrieveChildProps(),
  };

  useModalData(modalData);

  const openDeleteModal = (invitation: UserInvitation) => {
    setSelectedInvitation(invitation);
    setModalType(ModalOptions.DeleteInvite);
    setIsOpen(true);
  };

  const showNoIncomingMessage = !userIncomingInvitations.length;
  const showNoOutgoingMessage = !userOutgoingInvitations.length;

  useEffect(() => {
    getInvitations();
  }, [])

  return (
    <>
      <IndexContainer
        title="Incoming Invitations"
        subTitle={"Invitations to resources you have been invited to but not accepted yet"}
        addResourceButton={
          <AddResourceButton
            onClickEvent={() => {
              setModalType(ModalOptions.Share);
              setIsOpen(true);
            }}
            title="User"
            prefix="INVITE"
            styles="w-52 my-10"
          />
        }
      >
        {showNoIncomingMessage && <NoDataDiv />}

        {userIncomingInvitations.map((invitation, i) => {
          return (
            <div
              className="my-2 md:my-2 w-full"
              key={invitation.invitedUserEmail + i}
            >
              <InvitationCard
                invitation={invitation}
                openDeleteModal={openDeleteModal}
                acceptInvitation={acceptInvitation}
              />
            </div>
          );
        })}
      </IndexContainer>
      <IndexContainer
        title="Outgoing Invitations"
        subTitle={"Invitations you have sent which have not been accepted yet"}
      >
        {showNoOutgoingMessage && <NoDataDiv />}

        {userOutgoingInvitations.map((invitation, i) => {
          return (
            <div
              className="my-2 md:my-2 w-full"
              key={invitation.invitedUserEmail + i}
            >
              <InvitationCard
                invitation={invitation}
                openDeleteModal={openDeleteModal}
              />
            </div>
          );
        })}
      </IndexContainer>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { AircraftContext } from "../../utils/store";
import { api } from "../../api";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { AircraftObject } from "../../types/Aircraft";
import Tabs, { TabsPage } from "../UI/Tabs";
import Loader from "../Loader";
import { TabContainer } from "../UI/Containers/TabContainer";
import { DocumentResourceType } from "../../types/Resource";
import { TabSubPageHeader } from "../UI/TabHeader";
import { retrieveTabsFor } from "../../data/tabs";

const AircraftWrapper = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [aircraftData, setAircraftData] = useState<undefined | AircraftObject>(
    undefined
  );

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const aircraft = await api.aircraft.getAircraft(id);
          setAircraftData(aircraft);
        } catch (error) {
          navigate("/page-not-found");
          console.error(error);
        }
      })();
    } else {
      navigate("/aircraft");
    }
  }, [id, navigate]);

  if (!aircraftData) {
    return <Loader />;
  }

  return (
    <TabContainer
      header={
        <TabSubPageHeader
          pageTitle={DocumentResourceType.Aircraft}
          tabName={aircraftData.title}
        />
      }
      tabs={<Tabs tabsList={retrieveTabsFor(TabsPage.Aircraft, id)} />}
    >
      <AircraftContext.Provider value={{ aircraftData, setAircraftData }}>
        <Outlet />
      </AircraftContext.Provider>
    </TabContainer>
  );
};

export default AircraftWrapper;

import { get } from "./axios";

/**
 * Gets analytics for admin page
 *
 * @returns an array of tag objects
 */
const getAnalytics = async (): Promise<any[]> => {
  try {
    const res = await get(`/analytics`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};


export const analytics = {
  getAnalytics,
};
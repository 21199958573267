import { useEffect, useState } from "react";
import { api } from "../../../api";
import { InvitationRecipient } from "../../../types/Invitations";

/**
 * Tries to load a user id from an invitation recipient that may or may not have
 * accepted an invite
 *
 * @param invitationRecipient an invitation recipient
 * @returns a user id
 */
export const useUserId = (invitationRecipient: InvitationRecipient) => {
  const [id, setId] = useState("");

  // check again to see if the user exists in the db for the users with pending
  // invites
  useEffect(() => {
    (async () => {
      if (!invitationRecipient.id) {
        const users = await api.users.searchUsers(invitationRecipient.email);
        if (users.length > 0) {
          const user = users[0];
          // if user exists, set its id to the user id
          setId(user.id);
        }
      } else {
        setId(invitationRecipient.id);
      }
    })();
  }, [invitationRecipient]);

  return id;
};

import React from "react";
import { NavIcon } from "../../types/Icons";

const AircraftIcon: React.FC<NavIcon> = (props) => {
  const { fill, styles } = props;
  return (
    <svg
      className={styles}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.089 5.51c-.082.075-.431.405-.551.53l-1.69 1.662-8.524-1.128L3.44 8.331l6.842 2.851-2.879 2.766-2.84-.383L3 15.023l3.253 2.248a.715.715 0 0 0 .064.038.635.635 0 0 0 .029.062l2.016 3.419 1.554-1.448-.176-2.922 2.964-2.642 2.372 7.032 1.89-1.762-.535-8.572 1.776-1.57c.135-.113.489-.438.569-.513.73-.681 1.234-1.338 1.498-1.954.448-1.044.07-1.676-.194-1.96-.841-.901-2.333-.516-3.991 1.03z"
        fill={fill}
      />
    </svg>
  );
};

export default AircraftIcon;

import React, {useCallback, useEffect, useState} from "react";
import { api } from "../../api";
import { IndexContainer } from "../UI/Containers/IndexContainer";
import NoDataDiv from "../UI/NoDataDiv";
import {DeleteModalOptions, ModalOptions} from "../../types/Modal";
import {InvitationRecipient, InvitationTypes} from "../../types/Invitations";
import {toast} from "react-toastify";
import {useModalData} from "../Modals/hooks/useModalData";
import {AddResourceButton} from "../Buttons/AddResourceButton";
import {useGetNetworkUsers} from "../../hooks/useGetNetworkUsers";
import {NetworkCard} from "./NetworkCard";
import TagList from "../UI/Tags/TagList";
import {Tag} from "../../types/Tag";
import {UserObject} from "../../types/User";
import {filterNetworkUsers} from "../../utils/network.utils";

export const NetworkIndex = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(ModalOptions.Default);
  const [selectedUser, setSelectedUser] = useState<UserObject>();
  const [userCreatedTags, setUserCreatedTags] = useState<Map<string, Tag>>(
      new Map()
  );
  const [activeFilters, setActiveFilters] = useState<Map<string, Tag>>(
      new Map()
  );
  const [filteredNetworkUsers, setFilteredNetworkUsers] = useState<UserObject[]>([])

  const [networkUsers, fetchNetworkUsers] = useGetNetworkUsers();

  // const shareDocuments = async () => {
  //   try {
  //    // TODO: handle sharing of multiple docs
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  //
  const handleSubmit = async (
    recipients: InvitationRecipient[],
    shareType: InvitationTypes,
    selectedResourceId: string
  ) => {
    try {
      await toast.promise(
        api.invitations.createInvitations(
          recipients,
          shareType,
          selectedResourceId
        ),
        {
          error:
            "There was a problem, inviting user. please check that entered email and try again.",
          success: "User Invited",
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsOpen(false)
    }
  };

  /**
   * Tags methods
   */

  /**
   * retrieves all tags created by user
   */
  const getTags = useCallback(async () => {
    const tagMap = new Map();
    const USED_TAGS_ONLY = true;
    const tags = await api.tags.getNetworkTags(USED_TAGS_ONLY);
    tags.forEach((tag: Tag) => {
      tagMap.set(tag.id, tag);
    });
    setUserCreatedTags(tagMap);
  }, []);

  /**
   * submits new tag modal, adding tag to db and to selected document
   * @param newTag
   */
  const createNewTag = async (newTag: string) => {
    await api.tags.createTagAndAddToUser(newTag, selectedUser!.id);
    await reloadNetworkIndex();
  };

  /**
   * adds already created tag to selected document
   * @param tag
   */
  const addTagToUser = async (tag: Tag) => {
    await api.tags.addNetworkTag(selectedUser!.id, tag.id);
    await reloadNetworkIndex();
  };

  const handleTagSubmit = (tagName: string, tag?: Tag) => {
    // if tag is already created
    if (tag) {
      return addTagToUser(tag);
    }
    createNewTag(tagName);
  };

  /**
   * removes selected tag from document
   * @param userId
   * @param tagId
   */
  const removeTag = async (userId: string, tagId: string) => {
    await api.tags.removeNetworkTag(userId, tagId);
    await reloadNetworkIndex();
  };

  const reloadNetworkIndex = async () => {
    await fetchNetworkUsers();
    await getTags();
    setIsOpen(false);
    setModalType(ModalOptions.Default);
    setSelectedUser(undefined);
  }

  /**
   * Tag Filters
   */

  /**
   * if tag is active, deactivates tag filter; otherwise, tag is set to active,
   * @param tag
   */
  const updateTagFilters = (tag: Tag) => {
    const activeTagFilters = new Map(activeFilters);
    if (activeTagFilters.has(tag.id)) {
      activeTagFilters.delete(tag.id);
    } else {
      activeTagFilters.set(tag.id, tag);
    }
    setActiveFilters(activeTagFilters);
  };

  /**
   * clears active tag filters
   */
  const clearTagFilters = () => {
    const activeTagFilters = new Map(activeFilters);
    activeTagFilters.clear();
    setActiveFilters(activeTagFilters);
  };

  const retrieveChildProps = () => {
    switch (modalType) {
      case ModalOptions.Share:
        return {
          selectedResourceId: "0",
          shareType: InvitationTypes.ApplicationUser,
          handleSubmit,
          existingNetworkEmails: networkUsers.map(user => user.email)
        };
      case ModalOptions.Success:
        return {
          successMessage: `Invitation Sent!`,
          buttonTitle: "Close",
        };
      /**
       * PaperworkIndex {@link TagsModal}
       */
      case ModalOptions.Tags:
        return {
          selectedUser: selectedUser,
          handleSubmit: handleTagSubmit,
        };
    }
  };

  /**
   * NetworkInvitations {@link InvitationModal}
   */
  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: modalType,
    childModalProps: retrieveChildProps(),
  };

  useModalData(modalData);

  const showNoNetworkUsers = !networkUsers.length;

  /**
   * retrieves user generated tags
   */
  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    if (networkUsers.length) {
      const networkUsersFilteredByTags = filterNetworkUsers(
          networkUsers,
          activeFilters
      )

      setFilteredNetworkUsers(networkUsersFilteredByTags)
    }
  }, [networkUsers, activeFilters])

  return (
    <>
      <IndexContainer
        title="Your Network"
        subTitle={"List of people in your Volas network"}
        addResourceButton={
          <AddResourceButton
            onClickEvent={() => {
              setModalType(ModalOptions.Share);
              setIsOpen(true);
            }}
            title="User"
            prefix="INVITE"
            styles="w-52 mt-10"
          />
        }
      >
        {showNoNetworkUsers && <NoDataDiv />}

        <div className="flex w-full justify-between mb-4">
          <TagList
            tagList={Array.from(userCreatedTags.values())}
            handleClearTags={clearTagFilters}
            activeTagFilters={Array.from(activeFilters.values())}
            updateTagFilters={updateTagFilters}
          />
        </div>

        {filteredNetworkUsers.map((user, i) => {
          return (
            <div
              className="my-2 md:my-2 w-full"
              key={user.email}
            >
              <NetworkCard
                user={user}
                setSelectedUser={setSelectedUser}
                setModalType={setModalType}
                setIsOpen={setIsOpen}
                removeTag={removeTag}
              />
            </div>
          );
        })}
      </IndexContainer>
    </>
  );
};

import { DocumentObject } from "../types/Document";
import { del, get, post, put } from "./axios";
import { getAccessToken } from "./local-storage";
import { DocumentResourceType } from "../types/Resource";

/**
 * Get a document by id
 *
 * @param id the id of the document
 * @returns the document
 */
const getDocument = async (id: string): Promise<DocumentObject> => {
  try {
    const res = await get(`/document/${id}`);
    const document = res.data;
    return Object.assign(
      {
        shared_with: res.data.shared_with,
        shared_with_pending: res.data.shared_with_pending,
      },
      document
    );
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Get previous versions of a document as document objects
 *
 * @param id the id of the document
 * @returns an array of document objects
 */
const getVersions = async (id: string): Promise<DocumentObject[]> => {
  try {
    const res = await get(`/document/${id}/versions`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

const _getCreateDocUrlByResource = (
  resourceType: DocumentResourceType,
  resourceId: string,
  isRequired: boolean
): string => {
  switch (resourceType) {
    case DocumentResourceType.Profile:
      return "/document";
    case DocumentResourceType.Aircraft:
      return `/aircraft/${resourceId}/document`;
    case DocumentResourceType.Transaction:
      return isRequired
        ? `/transaction/${resourceId}/required-document`
        : `/transaction/${resourceId}/document`;
    default:
      return "";
  }
};

/** */
const getDocumentsAndVersions = async () => {
  try {
    const res = await get("/document");
    return res.data;
  } catch (error) {
    return Promise.reject(JSON.stringify(error));
  }
};
/**
 * Creates a document, adding its data to the volas DB and uploading the file to
 * s3
 *
 * #### Versions
 * To create a new version of a document, create a new document with
 * perviousVersionId set to the id of the latest document.
 *
 * @param data the file data as a blob
 * @param title the title of the docment or the document type
 * @param name the name of the document to be stored in the volas DB
 * @param resourceType
 * @param resourceId
 * @param isDocusign
 * @param isSigned
 * @param existingDocumentId
 * @param metaData
 * @returns the new document id
 */
const createDocument = async (
  data: Blob | undefined,
  title: string,
  name: string,
  resourceType: DocumentResourceType,
  resourceId: string,
  isDocusign: boolean,
  isSigned: boolean,
  existingDocumentId?: string,
  metaData?: Record<string, any>,
  addAutoTags?: boolean
): Promise<DocumentObject> => {
  try {
    const formData = new FormData();
    const isRequired = metaData && metaData.isRequired;

    formData.append("isDocusign", isDocusign.toString());
    formData.append("isSigned", isSigned.toString());
    formData.append("file", data as File);
    formData.append("title", title);
    formData.append("fileName", name);
    formData.append("addAutoTags", (!!addAutoTags).toString());
    existingDocumentId &&
      formData.append("existingDocumentId", existingDocumentId);

    // Call different endpoints depending on resource type
    const createDocEndpoint = _getCreateDocUrlByResource(
      resourceType,
      resourceId,
      isRequired
    );

    if (isRequired) {
      formData.append("isRequired", metaData.isRequired);
    }

    const res = await post(createDocEndpoint, formData, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });
    const document = res.data;
    return document;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Downloads a file blob given a document id
 *
 * Use the `downloadBlob` helper method in `utils/helpers` to trigger a download
 * in the user's browser using the blob returned by this method.
 *
 * @param id the document id
 * @returns the blob of the file
 */
const downloadDocument = async (id: string): Promise<Blob> => {
  try {
    const res = await get(`/document/${id}/download`, {
      responseType: "blob",
    });
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Retrieves a presigned url from AWS for this document
 * Only users with this URL can view the document (its not public)
 * There is an expiry on the url
 *
 * @param fileKey
 */
const getDocumentSignedUrl = async (fileKey: string): Promise<string> => {
  try {
    const res = await get(`/document/signed-url?fileKey=${fileKey}`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Deletes a document
 *
 * @param id the document id
 */
const deleteDocument = async (id: string): Promise<void> => {
  try {
    await del(`/document/${id}`);
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Adds a tag to a document
 * @param documentId the document id
 * @param tagId the tag id
 */
const addTag = async (documentId: string, tagId: string): Promise<void> => {
  try {
    await post(`/document/${documentId}/add-tag/${tagId}`);
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * removes a tag from a document
 *
 * @param documentId the document id
 * @param tagId the tag id
 */
const removeTag = async (documentId: string, tagId: string): Promise<void> => {
  try {
    await del(`/document/${documentId}/remove-tag/${tagId}`);
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Updates a document
 *
 * @param id the id of the document
 * @param updateBody the fields to be updated
 * @returns an updated document object
 */
const updateDocument = async (
  id: string,
  updateBody: { title: string, is_docusign?: boolean }
): Promise<DocumentObject> => {
  try {
    const res = await put(`/document/${id}`, { updateBody });
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

export const documents = {
  getDocument,
  getVersions,
  getDocumentsAndVersions,
  createDocument,
  downloadDocument,
  getDocumentSignedUrl,
  deleteDocument,
  addTag,
  removeTag,
  updateDocument,
};

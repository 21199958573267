import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ChakraProvider} from '@chakra-ui/react'
import {api} from "../api";
import {clearTokens} from "../api/local-storage";
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import {useAuthorize} from "../hooks/useAuthorize";
import {UserObject} from "../types/User";
import {
  AuthContext,
  initialModalData,
  initialProfileUser,
  LoadingContext,
  ModalContext,
  ProfileUserContext,
  UserDataContext,
} from "../utils/store";
import WithAxios from "./Auth/WithAxios";
import Body from "./Body";
import ModalWrapper from "./Modals/ModalWrapper";

const AppWrapper = () => {
  const [userData, setUserData] = useState({} as UserObject);
  const [profileUser, setProfileUser] = useState(initialProfileUser);
  const [showLoading, setShowLoading] = useState(false);
  const [modalData, setModalData] = useState(initialModalData);
  const [isAuthorized, setIsAuthorized] = useAuthorize();
  const navigate = useNavigate();

  const getAuthorizedUser = useCallback(async () => {
    try {
      const user = await api.users.getCurrentUser();
      setUserData({...user});
    } catch (error) {
      clearTokens();
      navigate("/");
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      getAuthorizedUser();
    }
  }, [isAuthorized]);

  // Default state for isAuthorized is undefined
  // If isAuthorized is not true/false, then wait for
  //   verifyToken response to complete before rendering
  if (isAuthorized === undefined) {
    return <></>;
  }

  return (
    <ErrorBoundary>
      <LoadingContext.Provider value={{showLoading, setShowLoading}}>
        <UserDataContext.Provider value={{userData, setUserData}}>
          <ProfileUserContext.Provider value={{profileUser, setProfileUser}}>
            <AuthContext.Provider value={{isAuthorized, setIsAuthorized}}>
              <WithAxios>
                <ToastContainer
                  position="top-right"
                  autoClose={4000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <ChakraProvider>
                  <ModalContext.Provider value={{modalData, setModalData}}>
                    <ModalWrapper/>
                    <Body/>
                  </ModalContext.Provider>
                </ChakraProvider>
              </WithAxios>
            </AuthContext.Provider>
          </ProfileUserContext.Provider>
        </UserDataContext.Provider>
      </LoadingContext.Provider>
    </ErrorBoundary>
  );
};

export default AppWrapper;

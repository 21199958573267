import React from "react";
import { Link } from "react-router-dom";

const NavItem = (props: any) => {
  const { url, title, icon } = props;

  return (
    <Link to={url}>
      <div className="nav-menu-container">
        {icon}
        <h3 className="nav-menu-text">{title}</h3>
      </div>
    </Link>
  );
};

export default NavItem;

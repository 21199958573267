import React from "react";
import { TransactionErrorsModalProps } from "../../types/Modal";
import RedButton from "../Buttons/RedButton";

const TransactionErrorsModal: React.FC<TransactionErrorsModalProps> = (
  props
) => {
  const { errorMessages, onAccept } = props;

  return (
    <div className="flex flex-col items-center text-xxx-gray justify-center w-full md:w-336 h-312">
      <h2 className="text-2xl text-red-700 tracking-wider mt-1 mb-5 text-center">
        Cannot finalize transaction
      </h2>
      <div className="overflow-auto">
        {errorMessages.map((message) => (
          <div key={message} className="flex text-gray-500">
            <div>-</div>
            <div className="pl-2">{message}</div>
          </div>
        ))}
      </div>
      <div className="my-6 flex w-full h-12">
        <RedButton
          onClickEvent={onAccept}
          styles="text-xs self-center w-full h-full"
          title="OK"
        />
      </div>
    </div>
  );
};

export default TransactionErrorsModal;

import React, { useEffect, useState } from "react";
import { api } from "../api";
import { getRefreshToken } from "../api/local-storage";

export const useAuthorize = (): [
  boolean | undefined,
  React.Dispatch<React.SetStateAction<boolean | undefined>>
] => {
  const [isAuthorized, setIsAuthorized] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    const refreshToken = getRefreshToken();
    (async () => {
      try {
        if (refreshToken) {
          await api.auth.verifyToken(refreshToken);
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false);
        }
      } catch (error) {
        setIsAuthorized(false);
      }
    })();
  }, []);
  return [isAuthorized, setIsAuthorized];
};

import { useState } from "react";
import { ResourceContainer } from "../UI/Containers/ResourceContainer";
import MeatballMenu from "../UI/MeatballMenu";
import arrowUp from "../../images/arrow-diangle-up.svg";
import emailIcon from "../../images/Mail-Icon-White-on-Grey.svg";
import {UserObject} from "../../types/User";
import {useNavigate} from "react-router-dom";
import TagList from "../UI/Tags/TagList";
import {ModalOptions} from "../../types/Modal";

interface NetworkCardProps {
  user: UserObject;
  setModalType: (modalType: ModalOptions) => void;
  setIsOpen: (isOpen: boolean) => void;
  setSelectedUser(user: UserObject): void;
  removeTag: (userId: string, tagId: string) => void;
}

export const NetworkCard = ({
  user,
  setModalType,
  setIsOpen,
  setSelectedUser,
  removeTag
}: NetworkCardProps) => {
  const [showMeatballMenu, setShowMeatballMenu] = useState(false);
  const navigate = useNavigate();
  // const shareDocuments = [
  //   {
  //     title: "delete invite",
  //     img: deleteIcon,
  //     events: [
  //       () => openDeleteModal!(invitation)
  //     ],
  //   },
  // ];

  const emailUserItem = [
    {
      title: "Email User",
      img: emailIcon,
      events: [
        () => window.location.href = `mailto:${user.email}`
      ],
    },
  ];

  const viewProfileItem = [
    {
      title: "View Profile",
      img: arrowUp,
      events: [
        () => navigate(`/profile/${user.id}`)
      ],
    },
  ];

  let meatballMenuItems: any[] = [...emailUserItem, ...viewProfileItem]

  return (
    <ResourceContainer
      title={user.name}
      subTitle={user.email}
      onClick={() => navigate(`/profile/${user.id}`)}
      timestamp={user.phone}
      tagListComponent={
        <TagList
          tagList={user.tags || []}
          resourceId={user.id}
          handleRemoveTag={removeTag}
          handleNewTag={() => {
            setSelectedUser(user);
            setModalType(ModalOptions.Tags);
            setIsOpen!(true);
          }}
        />
      }
      meatBallMenu={
        meatballMenuItems.length ?
          <MeatballMenu
            meatballMenuItems={meatballMenuItems}
            showMeatballMenu={showMeatballMenu}
            setShowMeatballMenu={setShowMeatballMenu}
          /> : undefined
      }
    />
  );
};
import { DocumentObject } from './Document';
import { Paperwork } from '../models/Paperwork';

export interface MeatballMenuProps {
  meatballMenuItems: any;
  showMeatballMenu: boolean;
  setShowMeatballMenu: (arg0: boolean) => void;
  setSelectedDoc?: (arg0: any) => void;
  doc?: DocumentObject | Paperwork;
}

export interface MeatballMenuItem {
  title: string;
  img: string;
  events: (() => any)[];
}

export enum PageHeader {
  Paperwork = 'Paperwork',
  Aircraft = 'Aircraft',
  Transactions = 'Transactions',
  Profile = 'Profile',
  Admin = 'Admin',
  Templates = 'Templates',
  Network = 'Network'
}

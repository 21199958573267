import {useCallback, useEffect, useState} from "react";
import { api } from "../api";
import {UserObject} from "../types/User";

export const useGetNetworkUsers = (): [UserObject[], () => Promise<void>] => {
  const [networkUsers, setNetworkUsers] = useState<UserObject[]>([]);

  const fetchNetworkUsers = useCallback(async () => {
    const _networkUsers = await api.users.getNetworkUsers();
    setNetworkUsers(_networkUsers);
  }, []);

  useEffect(() => {
    fetchNetworkUsers();
  }, []);

  return [networkUsers, fetchNetworkUsers];
};
import React, { RefObject } from "react";

export enum DocumentsType {
  Documents = "Documents",
  Paperwork = "Paperwork"
}

// TODO -- update home_airport to be camelCase throughout app
export interface ProfileData {
  name: string;
  phone: string;
  home_airport: string;
}

export interface ProfilePicProps {
  inputRef: RefObject<HTMLInputElement>;
  onChangeImage: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void> | void;
  onClickImage: () => void;
  profilePic: string;
  styles: string;
}

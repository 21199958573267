import React, { SyntheticEvent, useEffect, useState } from "react";
import OnboardingLogo from "../Logos/OnboardingLogo";
import arrow from "../../images/arrow-light-red.svg";
import confirmCheck from "../../images/confirm-check.svg";
import { Link, useNavigate } from "react-router-dom";
import eyeClosed from "../../images/eye-closed.svg";
import eyeOpen from "../../images/open-eye-icon.svg";
import greenCheck from "../../images/checkmark.svg";
import Label from "../Forms/Label";
import MobileHeader from "../Logos/MobileHeader";
import { api } from "../../api";
import RedButton from "../Buttons/RedButton";
import { toast } from "react-toastify";
import { TOAST_STATUSES } from "../../utils/toast_statuses";

const PasswordReset = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [passwordConfirmVisibility, setPasswordConfirmVisibility] = useState(
    false
  );
  const [passwordResetSent, setPasswordResetSent] = useState(false);

  const navigate = useNavigate();
  const token = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    (async () => {
      if (!token) {
        navigate("/");
      } else {
        try {
          await api.auth.verifyToken(token);
        } catch (_error) {
          navigate("/");
        }
      }
    })();
  }, [token, navigate]);

  const passwordReset = async (e: SyntheticEvent) => {
    try {
      e.preventDefault();
      if (token) {
        await toast.promise(
          api.auth.resetPassword(token, password, passwordConfirm),
          TOAST_STATUSES.resetPassword
        );
        setPasswordResetSent(true);
      }
    } catch (_error) {
      const error: any = _error;
      console.error(error);
    }
  };

  const passwordsMatch = () =>
    password === passwordConfirm && password && passwordConfirm;

  return (
    <div className="md:font-normal h-screen mx-auto my-0 flex flex-col md:flex-row pb-10 md:pb-0">
      <OnboardingLogo />
      <div className="flex flex-col md:w-2/3 md:overflow-auto">
        <MobileHeader />
        <div className="flex flex-col h-auto w-full self-center mt-24 w-11/12 md:self-start max-w-400 md:max-w-screen-lg md:h-screen text-center md:text-left md:pl-16 lg:pl-28">
          {!passwordResetSent && (
            <div className="my-auto w-full lg:w-2/3">
              <h2 className="text-xl md:text-xxxl pt-2">
                Reset your password.
              </h2>
              <h3 className="pt-5 text-sm md:text-base w-full">
                Password must be at least 8 characters long.
              </h3>
              <form>
                <div className="flex flex-col justify-center mt-8 md:mt-4">
                  <div className="self-center flex justify-center md:self-start md:justify-start w-full md:w-2/3 my-1">
                    <div className="flex flex-col justify-center w-10/12 md:w-96 mt-4">
                      <Label
                        required={true}
                        hidden={password}
                        title="PASSWORD"
                      />
                      <input
                        required
                        className="w-full md:w-96 pl-2 pb-1 outline-none text-xs md:text-sm text-left font-bold md:font-normal focus:outline-none border-xxx-gray border-b md:mx-0 placeholder-gray-300 md:placeholder-gray-700"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={!passwordVisibility ? "password" : "text"}
                        placeholder="PASSWORD"
                      />
                    </div>
                    {!password && <p className="self-end text-red-600">*</p>}
                    {password && (
                      <img
                        alt=""
                        src={passwordVisibility ? eyeOpen : eyeClosed}
                        onClick={() =>
                          setPasswordVisibility(!passwordVisibility)
                        }
                        className="hidden md:block self-end mb-2 select-none cursor-pointer"
                      />
                    )}
                  </div>
                  <div className="flex justify-center self-center md:self-start md:justify-start w-full my-1 md:my-0">
                    <div className="flex flex-col justify-center w-10/12 md:w-96 mt-4">
                      <Label
                        required={true}
                        hidden={passwordConfirm}
                        title="CONFIRM PASSWORD"
                      />
                      <input
                        required
                        className={`w-full md:w-96 pl-2
                  ${
                    passwordConfirm && passwordsMatch()
                      ? "border-input-green"
                      : passwordConfirm && !passwordsMatch()
                      ? "border-red-400"
                      : "border-xxx-gray"
                  } outline-none text-xs md:text-sm pb-1 text-left font-bold md:font-normal focus:outline-none border-b md:mx-0 placeholder-gray-300 md:placeholder-gray-700`}
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        type={!passwordConfirmVisibility ? "password" : "text"}
                        placeholder="CONFIRM PASSWORD"
                      />
                    </div>
                    <p
                      className={
                        !passwordConfirm ? "self-end text-red-600" : "hidden"
                      }
                    >
                      *
                    </p>
                    {passwordConfirm && (
                      <img
                        alt=""
                        src={passwordConfirmVisibility ? eyeOpen : eyeClosed}
                        onClick={() =>
                          setPasswordConfirmVisibility(
                            !passwordConfirmVisibility
                          )
                        }
                        className={`hidden md:block self-end mb-2 select-none cursor-pointer ${
                          passwordsMatch() && password && "md:hidden"
                        }`}
                      />
                    )}
                    <img
                      alt=""
                      src={greenCheck}
                      className={`${
                        passwordConfirm && passwordsMatch() ? "block" : "hidden"
                      } self-end pb-2 h-full select-none text-input-green -mr-4 md:mr-0`}
                    />
                  </div>
                </div>
                {passwordConfirm && (
                  <p
                    className={`absolute md:hidden text-sm w-9/12 md:w-1/2 mt-1 flex w-full justify-center  ${
                      passwordsMatch() ? "text-input-green" : "text-red-400"
                    }`}
                  >
                    {!passwordsMatch() && "Passwords don't match"}
                  </p>
                )}
                <div className="flex justify-center md:justify-start">
                  <RedButton
                    onClickEvent={passwordReset}
                    styles="mt-20 w-56 px-3 py-3 text-xs"
                    title="RESET PASSWORD"
                    disabled={!passwordsMatch()}
                  />
                </div>
              </form>
            </div>
          )}

          {passwordResetSent && (
            <div className="md:pl-10">
              <div className="center-vertical flex flex-col items-center md:items-start w-96">
                <img src={confirmCheck} className="w-10" alt="" />
                <h2 className="md:text-xl lg:text-2xl mt-5">
                  Password reset successfully!
                </h2>
                <Link
                  to="/login"
                  className="text-volas-red text-sm flex flex mt-6"
                >
                  <img src={arrow} alt="" className="mr-2" />
                  Return to login
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;

import { RefObject, useEffect } from "react";

export const useOutsideClickToCloseModal = (
  ref: RefObject<HTMLInputElement>,
  showModal: (arg0: boolean) => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        showModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

import React from "react";

interface RoleProps {
  name: string;
}

export const Role: React.FC<RoleProps> = ({name}) => {
  return (
    <div className="flex items-center mr-3">
      <p className="text-xx-green text-xs">{name}</p>
    </div>
  )
}
import React from "react";
import radioSelected from "../../images/radio-selected.svg";
import radioDefault from "../../images/radio-default.svg";
import { useDropzone } from 'react-dropzone';
import upload from "../../images/upload.svg";
import greenCheck from "../../images/green-check-icon.svg";
import x from "../../images/black-x.svg";
import {
  DocModalType,
  DocumentUploadProps,
  DocumentType,
} from "../../types/Document";
import fileIcon from "../../images/file-icon.svg";

const DocumentUpload: React.FC<DocumentUploadProps> = (props) => {
  const {
    documentType,
    isDocusign,
    setDocumentType,
    hiddenFileInput,
    handleClick,
    setUploadedFile,
    documentTitle,
    uploadedFile,
    selectedDoc,
    modalType,
    initialFileData,
  } = props;

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const fileUploaded = acceptedFiles[0];

      setUploadedFile({
        data: fileUploaded,
        title: documentTitle,
        name: fileUploaded.name,
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true, // Disable click to open file dialog since we have custom button
    multiple: false // Allow only one file to be dropped
  });

  const handleUploadedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = e.target.files;
    if (!files) return;

    const fileUploaded = files[0];

    setUploadedFile({
      data: fileUploaded,
      title: documentTitle,
      name: fileUploaded?.name,
    });
  };

  const docTypeMatches = (isDocusign && documentType === DocumentType.Docusign) || (!isDocusign && documentType === DocumentType.Document);

  return (
    <div {...getRootProps()} className="flex rounded h-140 w-11/12 md:w-363 bg-gray-100 self-center form-check">
      <input {...getInputProps()} />
      <img
        src={docTypeMatches ? radioSelected : radioDefault}
        alt=""
        className="w-1.4 mt-1 align-top cursor-pointer self-center flex justify-center ml-4 mr-10"
        onClick={() => { setDocumentType(isDocusign ? DocumentType.Docusign : DocumentType.Document) }}
      />
      <div className="flex flex-col justify-center self-center items-start w-full pl-4 border-l border-gray-200 h-full my-3 pb-3">
        <p className="text-gray-400 text-xs pr-4 md:pr-2 md:text-sm">
          {modalType === DocModalType.Edit
            ? `Click file below to update ${documentType === DocumentType.Docusign ? "e-Sign" : "document"}.`
            : isDocusign ? "Drag file here or click upload to upload an e-Sign document" : "Drag file here or click upload to upload a file, PDF, PNG, GIF, JPG, XLS, TXT, & DOC supported"}
        </p>

        {modalType === DocModalType.Edit && (
          <button className="flex items-center justify-start w-auto mt-3 py-1 -ml-1 tracking-wide">
            <img src={fileIcon} alt="" className="w-1.2 md:w-1.4 mr-2" />
            <div
              className="text-xxs cursor-pointer hover:text-gray-500"
              onClick={handleClick}
            >
              {!uploadedFile.name && docTypeMatches && selectedDoc ?
                selectedDoc.file_name ? `${selectedDoc.file_name?.slice(0, 20)}...` : "Click to select file..."
               : (
                <div className="flex items-center">
                  {uploadedFile.name.slice(0, 17)}...
                  {docTypeMatches ?? <img
                    src={greenCheck}
                    alt=""
                    className="w-1.2 md:w-1.4 ml-2"
                  />}
                </div>
              )}
            </div>
            <input
              type="file"
              name="file"
              onClick={(event) => event.currentTarget.value = ""}
              className="hidden"
              ref={hiddenFileInput}
              onChange={handleUploadedFile}
            />
          </button>
        )}

        {modalType === DocModalType.Add && (
          <div>
            <button
              className={`${uploadedFile.name && "hidden"
                } flex justify-center bg-white ring-1 rounded tracking-wider hover:bg-gray-100 ring-red-600 w-28 self-center text-xs font-semibold py-1 mt-4`}
              onClick={(e) => {
                handleClick(e);
              }}
            >
              <img src={upload} alt="upload-icon" className="mr-2" />
              UPLOAD
              <input
                type="file"
                name="file"
                className="hidden"
                ref={hiddenFileInput}
                onClick={(event) => {
                  event.currentTarget.value = "";
                  setDocumentType(isDocusign ? DocumentType.Docusign : DocumentType.Document);
                }}
                onChange={handleUploadedFile}
              />
            </button>

            {docTypeMatches && uploadedFile.name && (
              <div className="flex bg-neutral-gray w-auto justify-start my-1 py-1 px-3 mt-3">
                <p className="text-xxs self-center w-auto">{`${uploadedFile.name.slice(
                  0,
                  20
                )}...`}</p>
                <img
                  src={x}
                  alt=""
                  className="text-lg cursor-pointer mx-2 w-4"
                  onClick={() => setUploadedFile(initialFileData)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentUpload;

import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../api";
import { TOAST_STATUSES } from "../../utils/toast_statuses";
import RedButton from "../Buttons/RedButton";

const CreateModal: React.FC<{
  setIsOpen: (arg0: boolean) => void;
  aircraftId: string;
  aircraftTitle: string;
}> = (props) => {
  const { setIsOpen, aircraftId, aircraftTitle } = props;

  const navigate = useNavigate();
  const createTransaction = async () => {
    try {
      const tx = await toast.promise(
        api.transactions.createTransaction(`Transaction on ${aircraftTitle}`, aircraftId),
        TOAST_STATUSES.createTransaction
      );

      navigate(`/transactions/${tx.id}`, {state: `/aircraft/${tx.aircraft_id}/transactions/`});
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col items-center text-xxx-gray justify-center w-full md:w-336 h-312">
      <h1 className="text-2xl text-xxx-gray text-center tracking-wider mb-6 px-5">
        Initiate transaction for: {aircraftTitle}
      </h1>
      <h2 className="text-base text-xxx-gray text-center tracking-wider">
        Are you sure you want to create a transaction for this aircraft?
      </h2>
      <RedButton
        onClickEvent={() => {
          createTransaction();
          setIsOpen(false);
        }}
        styles="w-56 px-3 py-3 mt-20 text-xs self-center"
        title="CREATE TRANSACTION"
      />
    </div>
  );
};

export default CreateModal;

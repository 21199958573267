import React from "react";
import arrowWhite from "../../images/arrow-white.svg";
import privacyIcon from "../../images/privacy-icon.svg";
import { RedBtn, RedBtnImg } from "../../types/Button";

const RedButton: React.FC<RedBtn> = (props) => {
  const { onClickEvent, styles, disabled, title, img, imgBeforeText, type } = props;

  const retrieveImg = () => {
    switch (img) {
      case RedBtnImg.Arrow:
        return (
          <img
            src={arrowWhite}
            alt=""
            className="text-white self-center ml-3"
          />
        );
      case RedBtnImg.Privacy:
        return <img src={privacyIcon} alt="" className="w-1.4 mr-2 md:pl-0" />;
      default:
        return null;
    }
  };

  return (
    <button
      type={type || 'button'}
      onClick={onClickEvent && onClickEvent}
      className={`${disabled && "opacity-40 disabled"} ${styles} red-btn`}
      disabled={disabled || false}
    >
      {imgBeforeText && retrieveImg()}
      {title}
      {!imgBeforeText && retrieveImg()}
    </button>
  );
};

export default RedButton;

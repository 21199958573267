import { useState } from "react";
import chevronDown from "../../images/chevron-down.svg";
import { Tag } from "../../types/Tag";
import TagList from "../UI/Tags/TagList";

interface CreatedTagDropdownProps {
  isVisible: boolean;
  tags: Tag[];
  handleClick: (tag: Tag) => void;
  tagInputValue: string;
}

/**
 * dropdown to reveal created tags
 *
 * @param handleClick
 * @param tags
 * @param isVisible
 * @param tagInputValue
 * @returns
 */
const CreatedTagDropdown = ({ handleClick, tags, isVisible, tagInputValue }: CreatedTagDropdownProps) => {
  if(!isVisible || !tags.length) {
    return null
  }
  return (
    <div className="max-w-18r">
      <div
        className="h-8 flex items-center justify-center cursor-pointer"
      >
        <h4 className="text-sm">Select from created tags</h4>
      </div>
      <div className="my-2 flex justify-center">
        <TagList tagList={tags} handleOnClick={handleClick} tagInputValue={tagInputValue} />
      </div>
    </div>
  );
};

export default CreatedTagDropdown;

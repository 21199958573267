import { useState } from "react";
import { ResourceContainer } from "../UI/Containers/ResourceContainer";
import MeatballMenu from "../UI/MeatballMenu";
import deleteIcon from "../../images/delete-icon.svg";
import arrowUp from "../../images/arrow-diangle-up.svg"
import UserInvitation from '../../models/Invitation';
import {useNavigate} from "react-router-dom";
import {InvitationTypes} from "../../types/Invitations";

interface InvitationCardProps {
  invitation: UserInvitation;
  openDeleteModal?: (invitation: UserInvitation) => void;
  acceptInvitation?: (token: string) => void;
}

const InvitationCard = ({ invitation, openDeleteModal, acceptInvitation }: InvitationCardProps) => {
  const [showMeatballMenu, setShowMeatballMenu] = useState(false);
  const navigate = useNavigate();

  const handleOpenResource = (invitation: UserInvitation) => {
    if(invitation.resourceType === InvitationTypes.AircraftUser) {
      // @ts-ignore
      navigate(`/aircraft/${invitation.id}`)
    } else if(invitation.resourceType === InvitationTypes.TransactionUser) {
      // @ts-ignore
      navigate(`/transactions/${invitation.id}`)
    }
  }

  // For aircraft and transactions, allow user to visit the resource
  const resourceLinkMenuItem = !invitation.isIncomingInvite && [InvitationTypes.AircraftUser, InvitationTypes.TransactionUser].includes(invitation.resourceType) ? [
    {
      title: `Visit ${invitation.resourceType}`,
      img: arrowUp,
      events: [
        () => handleOpenResource(invitation)
      ]
    }
  ] : []

  const deleteMenuItem = [
    {
      title: "delete invite",
      img: deleteIcon,
      events: [
        () => openDeleteModal!(invitation)
      ],
    },
  ];

  const acceptInviteMenuItem = [
    {
      title: "accept invite",
      img: arrowUp,
      events: [
        () => acceptInvitation!(invitation.token!)
      ],
    },
  ];

  let meatballMenuItems: any[] = []
  if (invitation.isIncomingInvite) {
    if (!!invitation.token) {
      meatballMenuItems = meatballMenuItems.concat(acceptInviteMenuItem)
    }

    if ([InvitationTypes.AircraftUser, InvitationTypes.TransactionUser].includes(invitation.resourceType)) {
      meatballMenuItems = meatballMenuItems.concat(resourceLinkMenuItem)
    }
  } else {
    if (!!openDeleteModal) {
      meatballMenuItems = meatballMenuItems.concat(deleteMenuItem)
    }
  }

  return (
    <ResourceContainer
      title={invitation.title}
      subTitle={invitation.subTitle}
      timestamp={`Invited on ${invitation.timestamp}`}
      status={invitation.statusText}
      onClick={() => null}
      meatBallMenu={
        meatballMenuItems.length ?
        <MeatballMenu
          meatballMenuItems={meatballMenuItems}
          showMeatballMenu={showMeatballMenu}
          setShowMeatballMenu={setShowMeatballMenu}
        /> : undefined
      }
    />
  );
};

export default InvitationCard;
import React, {useCallback, useContext, useEffect, useState} from "react";
import { api } from "../../../api";
import { IndexContainer } from "../../UI/Containers/IndexContainer";
import NoDataDiv from "../../UI/NoDataDiv";
import InvitationCard from "../../Admin/Invitation";
import UserInvitation from "../../../models/Invitation";
import {UserDataContext} from "../../../utils/store";

const Invitations = () => {
  const [ userIncomingInvitations, setUserIncomingInvitations ] = useState([] as UserInvitation[]);
  const [ userOutgoingInvitations, setUserOutgoingInvitations ] = useState([] as UserInvitation[]);
  const { userData } = useContext(UserDataContext);

  const getInvitations = useCallback(async () => {
    try {
      const aircraftInvites = await api.invitations.getAircraftInvitations();
      const transactionInvites = await api.invitations.getTransactionInvitations();
      const documentInvites = await api.invitations.getDocumentInvitations();

      const allInvites = UserInvitation.createInvitedUsers(
        aircraftInvites.concat(transactionInvites).concat(documentInvites),
        userData.email
      );
      setUserIncomingInvitations(allInvites.filter(invite => invite.isIncomingInvite));
      setUserOutgoingInvitations(allInvites.filter(invite => !invite.isIncomingInvite));
    } catch (error) {
      console.error(error);
    }
  }, []);


  const showNoIncomingMessage = !userIncomingInvitations.length;
  const showNoOutgoingMessage = !userOutgoingInvitations.length;

  useEffect(() => {
    getInvitations();
  }, [])

  return (
    <>
      <IndexContainer
        title="Incoming Invitations"
        subTitle={"Invitations to resources you have been invited to but not accepted yet"}
      >
        {showNoIncomingMessage && <NoDataDiv />}

        {userIncomingInvitations.map((invitation, i) => {
          return (
            <div
              className="my-2 md:my-2 w-full"
              key={invitation.invitedUserEmail + i}
            >
              <InvitationCard
                invitation={invitation}
                // openDeleteModal={openDeleteModal}
              />
            </div>
          );
        })}
      </IndexContainer>
      <IndexContainer
        title="Outgoing Invitations"
        subTitle={"Invitations you have sent which have not been accepted yet"}
      >
        {showNoOutgoingMessage && <NoDataDiv />}

        {userOutgoingInvitations.map((invitation, i) => {
          return (
            <div
              className="my-2 md:my-2 w-full"
              key={invitation.invitedUserEmail + i}
            >
              <InvitationCard
                invitation={invitation}
                // openDeleteModal={openDeleteModal}
              />
            </div>
          );
        })}
      </IndexContainer>
    </>
  );
};

export default Invitations;

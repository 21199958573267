import React, {useCallback, useContext, useEffect, useState} from "react";
import {api} from "../../api";
import volasLogo from "../../images/mini-volas-logo.svg";
import volasUser from "../../images/volas-user.svg";
import {InvitationRecipient, InvitationTypes} from "../../types/Invitations";
import {InvitationModalProps} from "../../types/Modal";
import {retrieveUserImg, truncateString, validateEmail,} from "../../utils/helpers";
import {UserDataContext} from "../../utils/store";
import RedButton from "../Buttons/RedButton";
import Loader from "../Loader";
import {useDocument} from "./hooks/useDocument";
import {useUserEmailList} from "./hooks/useUserEmailList";
import ShareDocInput from "./InvitationInput";
import {InviteNotice} from "./InviteNotice";
import UserListItem from "./UserListItem";

const InvitationModal: React.FC<InvitationModalProps> = ({
  selectedResourceId,
  shareType,
  handleSubmit,
  permissionsList,
  existingSharesEmails,
  existingNetworkEmails
}) => {
  const document = useDocument(selectedResourceId, shareType);

  const { userData } = useContext(UserDataContext);

  const initUserSearchData = {
    name: "",
    picture: volasUser,
    email: "",
  };

  const [userEmail, setUserEmail] = useState("");
  const [userInviteList, setUserInviteList] = useUserEmailList(document);
  const [userSearchList, setUserSearchList] = useState([initUserSearchData]);
  const [isLoading, setIsLoading] = useState(false);
  const [resourceRoleIds, setResourceRoleIds] = useState<string[]>([]);

  const SHARE_TITLES = {
    [InvitationTypes.AircraftUser]: `Add a new user to your ${InvitationTypes.AircraftUser}`,
    [InvitationTypes.TransactionUser]: `Add a new user to your ${InvitationTypes.TransactionUser}`,
    [InvitationTypes.Document]: `Share ${InvitationTypes.Document}`,
    [InvitationTypes.ApplicationUser]: `Invite a User to your Volas Network`,
  };

  /**
   * ensures email is not current user and not already included
   *
   * @param email
   * @param userList
   * @returns
   */
  const isValidUser = (email: string, userList?: any): boolean => {
    // If this is a document and email is the session user's email
    // A user can't share a document with themselves, but can add themselves to other resources
    if (shareType === InvitationTypes.Document && email === userData.email) {
      return false;
    }

    // If email is included in the list of resource's invite list
    for (let user of userInviteList) {
      if (email === user.email) return false;
    }

    // If email is included in existing shared emails
    if (existingSharesEmails && existingSharesEmails.includes(email)) {
      return false
    }

    // Allow user to be added if the user exists
    if (userExists) {
      if (existingSharesEmails && existingSharesEmails.includes(userSearchList[0]?.email)) {
        return false
      }
      return true
    }

    if (userList && userList[0]?.email) {
      if (existingSharesEmails && existingSharesEmails.includes(userList[0]?.email)) {
        return false
      }
      return true
    }

    return validateEmail(email);
  };

  /**
   * determines if the Add User List is shown.
   *
   * @param email
   * @param userList
   * @returns
   */
  const showUserList = (email: string, userList?: any[]): boolean => {
    return isValidUser(email, userList);
  };

  /**
   * finds user by entered email, if email is validated
   *
   */
  const findUserByEmail = useCallback(async () => {
    // Only search when search string is at least 3
    if (userEmail.length <= 3) {
      setUserSearchList([])
      return
    }
    const userList = await api.users.searchUsers(userEmail);

    if (showUserList(userEmail, userList)) {
      try {
        const user = userList[0];
        const userNotFound = { ...initUserSearchData, email: userEmail };

        if (user) {
          const userPicture = user.profile_picture_key
            ? retrieveUserImg(user.id)
            : volasLogo;

          const foundUser = {
            name: user.name,
            picture: userPicture,
            email: user.email,
            id: user.id,
          };

          setUserSearchList([foundUser]);
        } else {
          setUserSearchList([userNotFound]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [userEmail]);

  const showNewInviteNotice = (): boolean => {
    const newInvite = (user: InvitationRecipient) => user.isNewInvite;
    return userInviteList.some(newInvite);
  };

  const showNewInviteNoticeText = (): string => {
    const docText = shareType === InvitationTypes.Document ? "is not in the Volas network and" : ''
    return `* User ${docText} will receive an invitation`;
  }

  const showPendingInviteNotice = (): boolean => {
    const pendingInvite = (user: InvitationRecipient) => user.hasPendingInvite;
    return userInviteList.some(pendingInvite);
  };

  // TODO: Determine if we want to show a non member notice
  const showNonMemberNotice = (): boolean => {
    const pendingInvite = (user: InvitationRecipient) => !user.id;
    return userInviteList.some(pendingInvite);
  };

  const isNewInvite = (userId?: string): boolean => {
    if (userId === userData.id) { return false }

    if (shareType === InvitationTypes.Document) {
      return !userId;
    }

    return true;
  }

  const handleAddUser = async (user: InvitationRecipient) => {
    const invitedUser = {
      isAdmin: false,
      email: user.email || "",
      permission: undefined,
      id: user.id || "",
      picture: user.picture,
      isNewInvite: isNewInvite(user.id),
      name: user.name || ""
    };

    if (isValidUser(user.email)) {
      const updatedInviteList = [...userInviteList.slice(), invitedUser];
      setUserInviteList(updatedInviteList);
      setUserEmail("");
    }
  };

  /**
   * formats invitationRecipients down to relevant invitation data
   *
   * @param invitationRecipients
   * @returns
   */
  const formattedRecipients = (invitationRecipients: InvitationRecipient[]) => {
    return invitationRecipients.map((invitationRecipient) => {
      return {
        email: invitationRecipient.email,
        permission:
          invitationRecipient.permission &&
          invitationRecipient.permission.toLowerCase(),
        resourceRoleIds,
      };
    });
  };

  const handleInviteUsers = async () => {
    const recipients = formattedRecipients(userInviteList);
    try {
      handleSubmit(recipients, shareType, selectedResourceId);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled = (): boolean => {
    // If this is a network invite, we dont need a permission or role
    if (shareType === InvitationTypes.ApplicationUser) {
      return false;
    }

    // Permission must be selected
    if (userInviteList.some(user => !user.permission)) {
      return true;
    }

    // Transaction / Aircraft users must have at least one role selected
    return (
      (shareType === InvitationTypes.AircraftUser ||
        shareType === InvitationTypes.TransactionUser) &&
      resourceRoleIds.length === 0
    );
  };

  const hideModalInput =
    shareType !== InvitationTypes.Document &&
    userInviteList.length > 0;

  const userExists = !!(
    userSearchList[0]?.name
  );

  const inNetwork = !!(
    existingNetworkEmails?.includes(userSearchList[0]?.email)
  )

  const userInNetwork = (
    inNetwork && shareType === InvitationTypes.ApplicationUser
  );

  const userInviteText = (): string => {
    if (shareType === InvitationTypes.ApplicationUser) {
      return userInNetwork ? "Already In Your Network" : "Invite User"
    }

    return "Share"
  };

  useEffect(() => {
    findUserByEmail();
  }, [userEmail]);

  return (
    <div className="flex flex-col text-center items-center bg-white w-full min-h-288 h-auto py-6 md:w-468 md:ml-18">
      {isLoading && <Loader />}
      <div className="w-full flex flex-col">
        <h1 className=" text-lg md:text-xxl text-xxx-gray mt-2 mb-4">
          {SHARE_TITLES[shareType]}
        </h1>
        {!hideModalInput && (
          <ShareDocInput
            setUserEmail={setUserEmail}
            userEmail={userEmail}
            validateUser={isValidUser}
            handleAddUser={handleAddUser}
            tempUserList={userSearchList}
          />
        )}
        {showUserList(userEmail) && (
          <ul className="absolute flex flex-col self-center mt-25 md:mt-28 z-10 cursor-pointer w-full px-5">
            {userSearchList.map((user) => {
              return (
                <li
                  className="self-center flex items-center justify-between bg-gray-100 hover:bg-gray-200 h-16 w-11/12 md:full rounded shadow-3xl"
                  onClick={
                    !userInNetwork ? () => handleAddUser(user) : () => null
                  }
                  key={user.email || userEmail}
                >
                  <div className="flex items-center pl-3 text-sm">
                    <img
                      src={user.picture || volasUser}
                      alt=""
                      className="mr-2 h-8 w-8 rounded-full"
                    />
                    <div className="flex flex-col text-left">
                      {user.name && (
                        <p className="text-logo-gray">{user.name}</p>
                      )}
                      <p className="text-welcome-gray text-xs md:text-sm">
                        {user.email
                          ? truncateString(user.email, 16)
                          : userEmail}
                      </p>
                    </div>
                  </div>
                  <p className="text-xs md:text-sm text-gray-400 px-1 py-1 mr-2 border border-welcome-gray rounded">
                    {userInviteText()}
                  </p>
                </li>
              );
            })}
          </ul>
        )}
        {userInviteList.map((user: InvitationRecipient) => {
          return (
            <UserListItem
              invitationRecipient={user}
              userInviteList={userInviteList}
              setUserInviteList={setUserInviteList}
              permissionsList={permissionsList || []}
              key={user.email}
              shareType={shareType}
              setResourceRoleIds={setResourceRoleIds}
              hidePermissionMenu={shareType === InvitationTypes.ApplicationUser}
            />
          );
        })}

        <div className="w-full my-4 border-b border-gray-xx" />
        {showNewInviteNotice() && <InviteNotice text={showNewInviteNoticeText()} color="text-welcome-gray"/>}
        {showPendingInviteNotice() && <InviteNotice text="** User has a pending invitation" color="text-welcome-gray" />}

        <div className="flex flex-row justify-center">
          <RedButton
            onClickEvent={handleInviteUsers}
            styles="w-183 px-3 py-3 mt-14 mb-6 text-xs self-center"
            title={"SAVE"}
            disabled={isSubmitDisabled()}
          />
        </div>
      </div>
    </div>
  );
};

export default InvitationModal;

import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import {DocModalType, DocumentType, File} from "../../types/Document";
import Label from "../Forms/Label";
import DocumentUpload from "./DocumentUpload";
import DropdownIcon from "../Icons/DropdownIcon";
import RedButton from "../Buttons/RedButton";
import {useOutsideClickToCloseModal} from "../../hooks/useOutsideClickToCloseModal";
import {DocModalProps} from "../../types/Modal";
import {DocumentResourceType} from "../../types/Resource";
import { FiPenTool } from 'react-icons/fi';
import {VolasTooltip} from "../UI/VolasTooltip";

const DocumentModal: React.FC<DocModalProps> = (props) => {
  const {
    documentSuggestedTitles,
    hiddenFileInput,
    hiddenFileInput2,
    handleClick,
    handleClick2,
    selectedDoc,
    submitFile,
    docModalType,
    updateDocumentTitle,
    isDocumentNullable,
    metadata,
    resourceType
  } = props;

  const initialFileData = { title: "", name: "", data: new Blob() };
  const [displayDocumentTitles, setDisplayDocumentTitles] = useState(false);
  const [documentTitle, setDocumentTitle] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [uploadDocumentType, setUploadDocumentType] = useState(
    DocumentType.Document
  );
  const [uploadedFile, setUploadedFile] = useState<File>(initialFileData);

  const editingDocument = docModalType === DocModalType.Edit;
  const [addAutoTags, setAddAutoTags] = useState(!editingDocument);

  const documentTitlesRef = useRef<HTMLInputElement>(null);

  const isDocusignUpload = uploadDocumentType === DocumentType.Docusign;
  const showDocusignCheckbox = !!(uploadedFile.name && isDocusignUpload);

  useOutsideClickToCloseModal(documentTitlesRef, setDisplayDocumentTitles);

  const handleChange = () => {
    setIsSigned(!isSigned);
  };

  const formInvalid = () => {
    if (!documentTitle) {
      return true;
    }

    if (editingDocument) {
      return !uploadedFile.name && !selectedDoc?.file_name;
    }

    return !isDocumentNullable && !uploadedFile.name;
  };

  const DocusignCheckbox = (): JSX.Element => {
    return showDocusignCheckbox ? (
      <div className="flex justify-center items-center text-welcome-gray text-sm mt-4">
        <label className="flex items-center">
          <input type="checkbox" className="mr-2" checked={isSigned} onChange={handleChange} />
          All parties have signed this docusign document.
        </label>
      </div>
    ) : <></>
  }

  const AutoTagCheckboxes = ({resourceType}: {resourceType?: DocumentResourceType}) => {
    if (resourceType && resourceType === DocumentResourceType.Aircraft && !editingDocument) {
      return (
        <div className="flex justify-center items-center text-welcome-gray text-sm mt-4">
          <label className="flex items-center cursor-pointer">
            <input type="checkbox" className="mr-2 cursor-pointer" checked={addAutoTags} onChange={() => setAddAutoTags(!addAutoTags)} />
            Add Tail Number Tag to Document
            <VolasTooltip tooltip={"Checking this will create a tag with the Aircraft Tail Number and add it to this document."} />
          </label>
        </div>
      )
    } else {
      return (
        <></>
      )
    }
  }

  useEffect(() => {
    selectedDoc?.is_docusign
      ? setUploadDocumentType(DocumentType.Docusign)
      : setUploadDocumentType(DocumentType.Document);

    editingDocument && selectedDoc && setDocumentTitle(selectedDoc.title);
  }, [selectedDoc, editingDocument]);

  const handleSavedFile = async (e: SyntheticEvent) => {
    if (!isDocumentNullable && editingDocument && !selectedDoc)
      throw new Error("No selected document");

    setIsSaving(true);

    try {
      const existingDocumentId =
        editingDocument && selectedDoc ? selectedDoc.id : "";
      if (uploadedFile.name || isDocumentNullable) {
        await submitFile!(
          uploadedFile,
          documentTitle,
          existingDocumentId,
          uploadDocumentType === DocumentType.Docusign,
          isSigned,
          metadata,
          addAutoTags
        );
      } else {
        await updateDocumentTitle!(documentTitle);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const mayUploadLater =
    !editingDocument && isDocumentNullable && !uploadedFile.name;

  return (
    <div className="md:h-full w-full md:w-423">
      <div className="flex flex-col self-center w-full h-full">
        <h1 className="flex items-center self-center text-2xl font-thin mt-3">
          {isDocusignUpload && (
            <FiPenTool fontSize={16} className="mr-2" />
          )}
          {editingDocument ? `Edit ${isDocusignUpload ? "e-Sign" : ""} Document` : "New Document"}
        </h1>

        <div className="w-full mt-7 flex flex-col">
          <div className="self-center w-11/12 md:w-363 border-b border-xxx-gray">
            <Label
              hidden="false"
              title="DOCUMENT TITLE"
              required={false}
              tooltip={!editingDocument ? "Give your document a name. Select from our suggested titles or create your own." : undefined}
            />
            <button
              type="button"
              className="relative focus:outline-none inline-flex justify-between w-full py-2 pl-2"
              aria-expanded="true"
              aria-haspopup="true"
            >
              <input
                autoFocus
                onChange={(e) => setDocumentTitle(e.target.value)}
                className="w-full text-sm placeholder-xxx-gray focus:outline-none"
                placeholder="Enter or select title"
                type="text"
                value={documentTitle}
              />
              <div
                className="w-10 flex cursor-pointer justify-end text-gray-700"
                onClick={() => {
                  setDisplayDocumentTitles(!displayDocumentTitles);
                }}
              >
                <DropdownIcon styles="h-5 w-5" />
              </div>
            </button>
          </div>

          <div
            className={`${!displayDocumentTitles && "hidden"}
              absolute mt-16 z-50 w-10/12 self-center h-auto rounded-md shadow-lg bg-white ring-1 
              cursor-pointer ring-black ring-opacity-5 divide-y divide-gray-100 max-h-48 
              overflow-y-scroll focus:outline-none`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            ref={documentTitlesRef}
          >
            {documentSuggestedTitles.map((suggestedTitle: string) => {
              return (
                <div role="none" key={suggestedTitle}>
                  <div
                    className="block pl-8 py-3 text-sm text-gray-600 hover:bg-gray-100"
                    onClick={() => {
                      setDocumentTitle(suggestedTitle);
                      setDisplayDocumentTitles(false);
                    }}
                  >
                    {suggestedTitle}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col mt-6">
            {(!editingDocument ||
              (editingDocument &&
                uploadDocumentType === DocumentType.Document)) && (
              <DocumentUpload
                documentType={uploadDocumentType}
                isDocusign={false}
                setDocumentType={setUploadDocumentType}
                hiddenFileInput={hiddenFileInput}
                handleClick={handleClick}
                setUploadedFile={setUploadedFile}
                uploadedFile={uploadedFile}
                documentTitle={documentTitle}
                selectedDoc={selectedDoc}
                modalType={docModalType}
                initialFileData={initialFileData}
              />
            )}

            {!editingDocument && (
              <p className="self-center mt-8 mb-4 text-gray-400 text-xxs tracking-extra font-semibold">
                OR UPLOAD A E-SIGN DOCUMENT
              </p>
            )}

            {(!editingDocument ||
              (editingDocument &&
                uploadDocumentType === DocumentType.Docusign)) && (
              <DocumentUpload
                documentType={uploadDocumentType}
                isDocusign={true}
                setDocumentType={setUploadDocumentType}
                hiddenFileInput={hiddenFileInput2}
                handleClick={handleClick2}
                setUploadedFile={setUploadedFile}
                uploadedFile={uploadedFile}
                documentTitle={documentTitle}
                selectedDoc={selectedDoc}
                modalType={docModalType}
                initialFileData={initialFileData}
              />
            )}
          </div>
          <DocusignCheckbox />
          <AutoTagCheckboxes resourceType={resourceType}/>
          <RedButton
            onClickEvent={handleSavedFile}
            styles={`${
              mayUploadLater ? "" : "w-44"
            } px-3 py-3 text-xs mt-8 self-center mb-10`}
            title={
              isSaving
                ? "SAVING"
                : mayUploadLater
                ? "SAVE AND UPLOAD LATER"
                : "SAVE"
            }
            disabled={formInvalid() || isSaving}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentModal;

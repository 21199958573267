import { useContext, useEffect, useState } from "react";
import { Permission } from "../types/Permission";
import { AircraftContext, UserDataContext } from "../utils/store";

export const useAircraftPermission = () => {
  const { aircraftData } = useContext(AircraftContext);
  const { userData } = useContext(UserDataContext);
  const [permission, setPermission] = useState<Permission>(Permission.VIEWER);

  useEffect(() => {
    if (!aircraftData || !userData) return;
    if (aircraftData.created_by === userData.id) {
      setPermission(Permission.ADMIN);
      return;
    }

    if (aircraftData.sharedWith) {
      const self = aircraftData.sharedWith.find((x) => x.id === userData.id);
      if (!self) return;
      if (self.permissionLevel === Permission.ADMIN.toLowerCase()) {
        setPermission(Permission.ADMIN);
      }
    }
  }, [aircraftData, userData]);

  return permission;
};

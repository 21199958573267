import { useContext, useEffect, useState } from "react";
import {
  clearTokens, getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "../../api/local-storage";
import instance from "../../api/axiosConfig";
import { api } from "../../api";
import { AuthContext } from "../../utils/store";

const WithAxios = ({ children }: { children: any }) => {
  const { setIsAuthorized } = useContext(AuthContext);
  let retries = 2
  // TODO: this may not be necessary, but we don't want to
  // render the children until the request is complete
  // There may be a better way to accomplish this.
  const [requestComplete, setRequestComplete] = useState(false);

  useEffect(() => {
    setRequestComplete(false);
    const authInterceptor = instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const config = error.config;
        const refreshToken = getRefreshToken();
        /**
         * If there is no refresh token in local storage, do nothing. The case where
         * there is no refresh token will be picked up by the client and redirect
         * the user to the login page
         */

        if (!refreshToken) {
          setIsAuthorized(false);
          return Promise.reject(error);
        }

        try {
          /**
           * If any response returns a 401, the access token is invalid/expired and
           * we need to get a new one
           */
          if (error.response.status === 401) {
            const { access, refresh } = await api.auth.refreshToken(
              refreshToken
            );

            setAccessToken(access.token);
            setRefreshToken(refresh.token);

            // Update the authorization header with the new access token
            config.headers = {
              Authorization: `Bearer ${access.token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            };

            // Retry the request with the new tokens
            // return instance.request(config);
            return
          }
        } catch (err: any) {
          if (err.toString().includes("token not found")) {
            const accessToken = getAccessToken();
            config.headers = {
              Authorization: `Bearer ${accessToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            };
            return instance.request(config);
          }

          setIsAuthorized(false);
          clearTokens();
          console.error("Axios request failed:", err)
        }
        return Promise.reject(error);
      }
    );

    setRequestComplete(true);
    return () => {
      instance.interceptors.response.eject(authInterceptor);
    };
  });

  if (!requestComplete) {
    return <></>
  }

  return children;
};

export default WithAxios;

import volasUser from "../../images/volas-user.svg";
import { Link } from "react-router-dom";
import { truncateString } from "../../utils/helpers";
import Tag from "./Tag";
import { Role } from "./Role";
import { Permission } from "../../types/Permission";
import MeatballMenu from "./MeatballMenu";
import React from "react";
import { UserObject } from "../../types/User";
import { ResourceRole } from "../../types/ResourceRole";

interface UserProps {
  user: UserObject;
  pending: boolean;
  permission: Permission;
  meatballMenuItems: any;
  showMeatballMenu: boolean;
  setShowMeatballMenu: (show: boolean) => void;
  roles?: ResourceRole[]
  readOnly?: boolean;
}

export const User: React.FC<UserProps> = (props) => {
  const {
    user,
    pending,
    permission,
    meatballMenuItems,
    showMeatballMenu,
    setShowMeatballMenu,
    roles,
    readOnly = false
  } = props;

  return (
    <div className="relative flex flex-col rounded-md bg-white w-full md:max-w-865 h-auto py-4 pl-3">
      <div className="flex justify-between w-full h-full px-2 items-center">
        <div className="flex flex-col md:items-center">
          <div className="flex">
            <img
              src={user.profile_picture || volasUser}
              alt=""
              className="mr-3 ml-2 md:ml-0"
            />
            <div className="flex flex-col justify-center text-xs md:text-sm md:w-72">
              {user.name && (
                <Link
                  to={`/profile/${user.id}`}
                  className="flex cursor-pointer"
                >
                  <p className="text-logo-grey mb-1 md:mb-0 mr-2 md:mr-5">
                    {truncateString(user.name, 18)}
                  </p>
                </Link>
              )}
              <div className="text-welcome-gray">{user.email}</div>
              <div className="flex flex-col xs:flex-row self-start pt-2 md:items-center md:justify-self-start">
                <Tag
                  title={user.permissionLevel}
                  styles="h-5 ring-1 ring-volas-red text-volas-red mr-4 mb-2 xs:mb-0"
                />
                <div className="max-w-32 flex flex-wrap mt-1">
                  {roles?.map((role) => (
                    <Role name={role.name} key={role.name} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {pending && (
          <p className="text-xxxs mr-2 md:mr-0 md:text-xs italic text-xxx-gray">Pending Invite</p>
        )}
        <div className="flex items-center self-center">
          {permission === Permission.ADMIN && !readOnly && (
            <MeatballMenu
              meatballMenuItems={meatballMenuItems}
              showMeatballMenu={showMeatballMenu}
              setShowMeatballMenu={setShowMeatballMenu}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export const TOAST_STATUSES = {
  deleteDocument: {
    pending: "Deleting document...",
    success: "Document deleted successfully",
    error: "Document deletion failed, please try again",
  },
  updateDocument: {
    pending: "Document is updating...",
    success: "Document updated successfully",
    error: "Document failed to update, please try again",
  },
  createDocument: {
    pending: "Document is saving...",
    success: "Document saved successfully",
    error: "Document failed to save, please try again",
  },
  privacySettings: {
    pending: "Updating privacy settings...",
    success: "Privacy settings updated successfully",
    error: "Privacy settings update failed, please try again",
  },
  resetPassword: {
    pending: "Resetting password...",
    error: "Password reset failed, please try again",
  },
  updateUser: {
    pending: "Updating user information...",
    success: "User information updated successfully",
    error: "User information update failed, please try again",
  },
  saveAircraft: {
    pending: "Aircraft is saving...",
    success: "Aircraft saved successfully",
    error: "Aircraft failed to save, please try again",
  },
  removeAircraftUser: {
    pending: "Removing user from aircraft...",
    success: "User removed successfully",
    error: "User removal from aircraft failed, please try again",
  },
  updateDocToEsign: {
    pending: "Updating Document to E-Sign...",
    success: "Document set to E-Sign successfully",
    error: "Error updating Document, please try again",
  },
  createTransaction: {
    pending: "Transaction is being created...",
    success: "Transaction created successfully",
    error: "Transaction failed to be created, please try again",
  },
  deleteTransaction: {
    pending: "Transaction is being deleted...",
    success: "Transaction deleted successfully",
    error: "Transaction failed to be deleted, please try again",
  },
  addUserToAircraft: {
    pending: "Inviting user to aircraft...",
    success: "User invited to aircraft successfully",
    error: "Failed to invite user to aircraft, please try again",
  },
  updateUserOnAircraft: {
    pending: "Updating user...",
    success: "User updated successfully",
    error: "Failed to update user, please try again",
  },
  addUserToTransaction: {
    pending: "Inviting user to transaction...",
    success: "User invited to transaction successfully",
    error: "Failed to invite user to transaction, please try again",
  },
  updateUserOnTransaction: {
    pending: "Updating user...",
    success: "User updated successfully",
    error: "Failed to update user, please try again",
  },
  removeTransactionUser: {
    pending: "Removing user from transaction...",
    success: "User removed successfully",
    error: "User removal from transaction failed, please try again",
  },
  finalizeTransaction: {
    pending: "Finalizing transaction...",
    success: "Transaction finalized",
    error: "Failed to finalize transaction, please try again",
  },
};

import React, { useState } from "react";
import { truncateString } from "../../utils/helpers";
import { AircraftObject } from "../../types/Aircraft";
import aircraftIcon from "../../images/aircraft-icon.svg";
import transactionIcon from "../../images/transaction-icon.svg";
import MeatballMenu from "../UI/MeatballMenu";
import { useNavigate } from "react-router-dom";
import { ResourceContainer } from "../UI/Containers/ResourceContainer";
import { ModalOptions } from "../../types/Modal";
import { useModalData } from "../Modals/hooks/useModalData";
import { aircraftOwnerNames } from "../../utils/aircrafts.utils";

const Aircraft: React.FC<{ aircraft: AircraftObject }> = (props) => {
  const { aircraft } = props;

  const navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [showMeatballMenu, setShowMeatballMenu] = useState(false);

  const aircraftHasOwners = !!aircraftOwnerNames(aircraft.sharedWith).length;
  const aircraftHasPendingTransactions = aircraft.pendingTransactionCount && aircraft.pendingTransactionCount > 0;

  const initiateTransactionItem = aircraftHasOwners && !aircraftHasPendingTransactions ?
    [{
      title: "Initiate Transaction",
      img: transactionIcon,
      events: [() => setIsOpen(true)],
    }] : [];

  const meatballMenuItems = [
    {
      title: "Aircraft Profile",
      img: aircraftIcon,
      events: [() => navigate(`/aircraft/${aircraft.id}`)],
    },
    ...initiateTransactionItem
  ];

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: ModalOptions.InitiateTx,
    childModalProps: {
      aircraftId: aircraft.id,
      aircraftTitle: aircraft.title,
      setIsOpen,
    },
  };

  useModalData(modalData);

  return (
    <ResourceContainer
      title={truncateString(aircraft.title, 18)}
      titleExtension={aircraft.tail_number}
      timestamp={aircraft.created_at}
      onClick={() => navigate(`/aircraft/${aircraft.id}`)}
      meatBallMenu={
        <MeatballMenu
          meatballMenuItems={meatballMenuItems}
          showMeatballMenu={showMeatballMenu}
          setShowMeatballMenu={setShowMeatballMenu}
        />
      }
    />
  );
};

export default Aircraft;

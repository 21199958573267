import React, {useContext} from "react";
import Tabs, { TabsPage } from "../UI/Tabs";
import { TabContainer } from "../UI/Containers/TabContainer";
import { retrieveTabsFor } from "../../data/tabs";
import {TabPageHeader} from "../UI/TabHeader";
import {PageHeader} from "../../types/UI";
import {InvitationContext} from "../../utils/store";

export const NetworkWrapper = (props: {
  children: React.ReactNode;
}) => {
  const { incomingInvitations } = useContext(InvitationContext);
  const { children } = props;

  return (
    <TabContainer
      header={<TabPageHeader pageTitle={PageHeader.Network} />}
      tabs={
        <Tabs tabsList={retrieveTabsFor(TabsPage.Network, undefined, undefined, incomingInvitations.length.toString())} />
      }
    >
      {children}
    </TabContainer>
  );
};

import React from "react";
import { Link, useLocation, useMatch } from "react-router-dom";

export enum TabsPage {
  Profile,
  Fleet,
  Aircraft,
  Documents,
  Transactions,
  Network,
  Admin,
}

export interface Tab {
  title: string;
  link: string;
  count?: string;
}

export interface TabsProps {
  tabsList: Tab[];
}

const Tabs: React.FC<TabsProps> = (props) => {
  const { tabsList } = props;
  const { pathname } = useLocation();
  // @todo define routes in one place
  const transactionPath = useMatch("/transactions/:transactionId");

  const isActive = (tab: Tab) => {
    const isTransactionPath = pathname === transactionPath?.pathname;
    return (
      pathname.endsWith(tab.link) ||
      (isTransactionPath && tab.title === "TRANSACTIONS")
    );
  };

  return (
    <ul className="flex flex-row pl-2 md:pl-12 text-center select-none overflow-x-auto overflow-y-hidden min-h-22 no-scrollbar bg-white">
      {tabsList.map((tab) => {
        return (
          <Link
            to={tab.link}
            key={tab.title}
            className={`${
              isActive(tab) && "bg-neutral-gray text-xxx-red"
            } w-auto px-4 md:px-0 md:w-40 h-12 pt-4 rounded-t text-xxx-gray cursor-pointer text-xxs md:text-sm-x font-semibold tracking-widest select-none`}
          >
            {tab.title.toUpperCase()} { tab.count ? `(${tab.count})` : ''}
          </Link>
        );
      })}
    </ul>
  );
};

export default Tabs;

import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext, UserDataContext } from "../../utils/store";

interface PrivateRouteProps {
  children: JSX.Element;
  isAdminOnly?: boolean;
}
const PrivateRoute = ({ isAdminOnly, children }: PrivateRouteProps) => {
  const { isAuthorized } = useContext(AuthContext);
  const { userData } = useContext(UserDataContext);

  const navigation = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigation("/");
    }
  }, [isAuthorized, navigation]);
  
  if (!isAuthorized || !userData.id) {
    return <></>;
  }
  
  if (isAdminOnly && !userData.is_admin) {
    return <></>;
  }

  return children;
};

export default PrivateRoute;

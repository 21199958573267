import React from "react";
import { ProfilePicProps } from "../../../types/Profile";

const ProfilePic: React.FC<ProfilePicProps> = (props) => {
  const { inputRef, onChangeImage, onClickImage, profilePic, styles } = props;

  return (
    <div>
      <input
        accept="image/*"
        ref={inputRef}
        type="file"
        className="hidden"
        onChange={onChangeImage}
      />
      <img
        onClick={onClickImage}
        src={profilePic}
        alt=""
        className={`${styles} cursor-pointer object-cover rounded-full`}
      />
    </div>
  );
};

export default ProfilePic;

import { createContext } from "react";
import UserInvitation from "../models/Invitation";
import { PaperworkResponse } from "../models/Paperwork";
import { AircraftObject } from "../types/Aircraft";
import { ModalOptions, ModalProps } from "../types/Modal";
import { TransactionObject } from "../types/Transaction";
import { UserObject } from "../types/User";
import { DocumentObject } from "../types/Document";

export const initialUserData: UserObject = {
  id: "",
  created_at: "",
  updated_at: "",
  email: "",
  is_admin: false,
  phone: "",
  name: "",
  is_email_verified: false,
  permissionLevel: "",
  roles: [],
  documents: [],
  received_documents: [],
  received_documents_as_admin: [],
  privacy_settings: [],
  access_token: "",
  profile_picture_key: "",
  profile_picture: "",
  home_airport: "",
  preventNavigationOnUpdate: false,
  aircraftRoles: [],
  transactionRoles: [],
};

export const initialAircraftData: AircraftObject = {
  id: "",
  created_at: "",
  updated_at: "",
  created_by: "",
  title: "",
  type: "",
  base: "",
  tail_number: "",
  serial_number: "",
  sharedWith: [],
  sharedWithPending: [],
};

export const initialProfileUser: UserObject = {
  id: "",
  created_at: "",
  updated_at: "",
  email: "",
  is_admin: false,
  phone: "",
  name: "",
  is_email_verified: false,
  permissionLevel: "",
  roles: [],
  documents: [],
  received_documents: [],
  received_documents_as_admin: [],
  privacy_settings: [],
  access_token: "",
  profile_picture_key: "",
  profile_picture: "",
  home_airport: "",
  aircraftRoles: [],
  transactionRoles: [],
  userInvitations: []
};

export const initialModalData: ModalProps = {
  childModal: ModalOptions.Default,
  childModalProps: null,
  modalIsOpen: false,
  setIsOpen: () => {},
};

export const UserDataContext = createContext({
  userData: initialUserData,
  setUserData: (user: UserObject) => {},
});

export const ProfileUserContext = createContext({
  profileUser: initialProfileUser,
  setProfileUser: (profileUser: UserObject) => {},
});

export const AuthContext = createContext({
  isAuthorized: false,
  setIsAuthorized: (isAuthorized: boolean) => {},
});

export const FleetContext = createContext({
  fleet: [] as AircraftObject[],
  setFleet: (aircraft: AircraftObject[]) => {},
});

export const AircraftContext = createContext({
  aircraftData: {} as AircraftObject,
  setAircraftData: (aircraft: AircraftObject) => {},
});

export const LoadingContext = createContext({
  showLoading: false,
  setShowLoading: (arg0: boolean) => {},
});

export const DocumentContext = createContext({
  documents: new Map() as Map<string, PaperworkResponse>,
  setDocuments: (documents: Map<string, PaperworkResponse>) => {},
});

export const TransactionContext = createContext({
  transaction: {} as TransactionObject,
  setTransaction: (arg0: TransactionObject) => {},
  requiredDocuments: [] as DocumentObject[],
  setRequiredDocuments: (docs: DocumentObject[]) => {}
});

export const InvitationContext = createContext({
  incomingInvitations: [] as UserInvitation[],
  setIncomingInvitations: (invitedUsers: UserInvitation[]) => {},
});

export const ModalContext = createContext({
  modalData: initialModalData as ModalProps,
  setModalData: (modalData: ModalProps) => {},
});

export const UserAdminContext = createContext({
  userInvitations: [] as UserInvitation[],
  setUserInvitations: (invitedUsers: UserInvitation[]) => {},
  allUsers: [] as UserObject[],
  setAllUsers: (allUsers: UserObject[]) => {}
});

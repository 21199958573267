import { DocumentObject } from "../../../../types/Document";
import DocumentVersions from "../../../Documents/DocumentVersions";
import { UploadOrDocusign } from "../../../Documents/UploadOrDocusign";

interface ResourceRightProps {
  meatBallMenu?: JSX.Element;
  modalWrapper?: JSX.Element;
  documentVersions?: DocumentObject[];
  onViewFile?: (id: string) => Promise<void>;
  onClick: (...args: any) => void;
}

const ResourceRight = ({
 meatBallMenu,
 documentVersions,
 onViewFile, onClick
}: ResourceRightProps) => {
  const hasNoFile = documentVersions?.length === 1 && !documentVersions[0].file_name

  const showDocumentVersions = !!(
    documentVersions && documentVersions.length > 0 && !hasNoFile
  );

  return (
    <>
      <div className="hidden md:flex items-center pt-1 self-start">
        {showDocumentVersions && (
          <div className="">
            <DocumentVersions
              documentVersions={documentVersions!}
              onViewFile={onViewFile!}
            />
          </div>
        )}

        {hasNoFile ?
          <div className="hidden md:flex">
            <UploadOrDocusign
              label={documentVersions![0].is_docusign ? "e-Sign" : "Edit / Upload"}
              onTap={() => onClick()}
            />
          </div>
          : <div/>
        }
      </div>

      <div>
        {meatBallMenu}
      </div>
    </>
  );
};

export default ResourceRight;

import { useEffect, useState } from "react";
import { api } from "../../../api";
import { ResourceRole } from "../../../types/ResourceRole";
import { InvitationTypes } from "../../../types/Invitations";

export const useResourceRoles = (resourceType: InvitationTypes) => {
  const [resourceRoles, setResourceRoles] = useState<ResourceRole[]>([]);

  useEffect(() => {
    (async () => {
      try {
        switch (resourceType) {
          case InvitationTypes.AircraftUser: {
            const _resourceRoles = await api.aircraftRoles.getAircraftRoles();
            setResourceRoles(_resourceRoles);
            break;
          }
          case InvitationTypes.TransactionUser: {
            const _resourceRoles =
              await api.transactionRoles.getTransactionRoles();
            setResourceRoles(_resourceRoles);
            break;
          }
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return resourceRoles;
};

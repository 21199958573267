import React from "react";

const ArrowLight: React.FC<{ styles: string; fill: string; width: string }> = ({
  styles,
  fill,
  width,
}) => {
  return (
    <svg
      width={width}
      height={parseInt(width) * 2}
      className={styles}
      viewBox="0 0 6 12"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.76314L0.63655 6.10651C0.454484 5.9138 0.454484 5.61248 0.63655 5.41977L1 5.76314ZM5.86345 10.1829C6.05309 10.3836 6.0441 10.7001 5.84337 10.8897C5.64264 11.0794 5.32619 11.0704 5.13655 10.8697L5.86345 10.1829ZM5.13655 0.656628C5.32619 0.4559 5.64264 0.44691 5.84337 0.636549C6.0441 0.826188 6.05309 1.14264 5.86345 1.34337L5.13655 0.656628ZM1.36345 5.41977L5.86345 10.1829L5.13655 10.8697L0.63655 6.10651L1.36345 5.41977ZM5.86345 1.34337L1.36345 6.10651L0.63655 5.41977L5.13655 0.656628L5.86345 1.34337Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowLight;

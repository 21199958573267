import React from "react";
import { DocumentObject } from "../../../../types/Document";
import { Status } from "../../../../types/Transaction";
import ResourceLeft from "./ResourceLeft";
import ResourceRight from "./ResourceRight";

interface ResourceContainerProps {
  title: string;
  timestamp?: string;
  onClick: (...args: any) => void;
  meatBallMenu?: JSX.Element;
  subTitle?: string;
  titleExtension?: string;
  documentVersions?: DocumentObject[];
  onViewFile?: (id: string) => Promise<void>;
  modalWrapper?: JSX.Element;
  status?: Status;
  documentLink?: string;
  documentLinkLabel?: string;
  tagListComponent?: JSX.Element;
  rolesComponent?: JSX.Element;
}

/**
 * This component is used to render individual
 * resources on resource index pages.
 *
 * This includes documents, aircrafts and transactions.
 */
export const ResourceContainer = ({
  title,
  onClick,
  timestamp,
  titleExtension,
  subTitle,
  status,
  documentVersions,
  onViewFile,
  meatBallMenu,
  documentLink,
  documentLinkLabel,
  tagListComponent,
  rolesComponent
}: ResourceContainerProps) => {
  const height = tagListComponent ? "min-h-100" : "h-30";
  const rounded = tagListComponent ? "rounded-t-md" : "rounded-md";
  return (
    <div className="w-full self-center md:self-start my-3">
      <div
        className={`flex flex-col py-4 px-4 relative ${rounded} bg-white max-w-865 ${height}`}
      >
        <div className="h-full w-full">
          <div className="flex w-full">
            <ResourceLeft
              title={title}
              titleExtension={titleExtension}
              subTitle={subTitle}
              timestamp={timestamp}
              onClick={onClick}
              status={status}
              documentVersions={documentVersions}
              onViewFile={onViewFile}
              documentLink={documentLink}
              documentLinkLabel={documentLinkLabel}
              rolesComponent={rolesComponent}
            />
            <ResourceRight
              meatBallMenu={meatBallMenu}
              documentVersions={documentVersions}
              onViewFile={onViewFile}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
      {tagListComponent && (
        <div className="bg-white rounded-b-md border-t border-t-neutral-gray py-2 px-2">{tagListComponent}</div>
      )}
    </div>
  );
};

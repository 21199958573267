import React from "react";
import RedButton from "../Buttons/RedButton";
import cx from "classnames";
import { DocumentResourceType } from "../../types/Resource";
import { OnboardingModalProps } from "../../types/Modal";

const OnboardingModal: React.FC<OnboardingModalProps> = ({
  closeModal,
  handleSubmit,
  invitedResource,
}) => {

  const resourceType = invitedResource?.resourceType.toLowerCase();

  const isApplicationInvite =
    resourceType === DocumentResourceType.Application;

  return (
    <div className="w-full md:w-423 md:h-full flex flex-col items-center text-center px-6">
      <h1 className="mt-10 text-xl text-xxx-gray">
        You can now complete your profile.
      </h1>

      <h2 className="mt-2 mb-4 text-md">
        Please add documents and a user profile picture.
      </h2>

      <div className="border-b-2 w-full mt-2" />

      <div
        className={cx("text-md md:mt-6 md:pb-0",
          { hidden: !invitedResource || isApplicationInvite }
        )}
      >
        <h3>Click the link below to view the {resourceType}: {invitedResource?.name} that was shared with you.</h3>
        <h3 className="text-volas-red mt-6 text-base underline cursor-pointer md:text-xs" onClick={handleSubmit}>
          View {resourceType}
        </h3>
      </div>

      <RedButton
        onClickEvent={closeModal}
        styles="w-44 px-3 py-3 text-xs mt-8 self-center mb-10"
        title="CLOSE"
      />
    </div>
  );
};

export default OnboardingModal;

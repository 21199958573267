import React from "react";
import { TransactionHeaderProps } from "../../types/Transaction";
import CheckSuccess from "../Icons/CheckSuccess";
import Badges from "./Badges";

const TransactionHeader: React.FC<TransactionHeaderProps> = (props) => {
  const { title, subTitle, badges, sectionCompleted, completedBadges } = props;

  return (
    <div className="mx-3 md:mx-0">
      <div className="flex items-center">
        <h1 className="text-xl md:text-xxxl xxx-gray leading-loose">{title}</h1>
        {sectionCompleted && (
          <CheckSuccess fill="#255201" styles="ml-2" width="24" height="24" />
        )}
      </div>
      <h3 className="text-sm text-logo-grey mb-4">{subTitle}</h3>
      {badges && completedBadges && (
        <Badges badges={badges} completedBadges={completedBadges} />
      )}
    </div>
  );
};

export default TransactionHeader;

import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import UserInfo from "./UserInfo";
import OverviewHeader from "./OverviewHeader";
import ArrowLight from "../../Icons/ArrowLight";
import Label from "../../Forms/Label";
import { ProfileUserContext, UserDataContext } from "../../../utils/store";
import Tag from "../../UI/Tag";
import { api } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { getRefreshToken } from "../../../api/local-storage";
import { EditProfileModalProps, ModalOptions } from "../../../types/Modal";
import { onChangeImage } from "../../../utils/helpers";
import { toast } from "react-toastify";
import { TOAST_STATUSES } from "../../../utils/toast_statuses";
import { useModalData } from "../../Modals/hooks/useModalData";
import { UserObject } from "../../../types/User";

const Overview = () => {
  const navigation = useNavigate();

  const { userData, setUserData } = useContext(UserDataContext);
  const { profileUser, setProfileUser } = useContext(ProfileUserContext);
  const { id } = useParams();

  const isCurrentUser = userData.id === id;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState(ModalOptions.Default);

  const profileInputs = [
    {
      label: "FULL NAME",
      data: profileUser.name || "",
    },
    {
      label: "PHONE NUMBER",
      data: profileUser.phone || "",
    },
    {
      label: "EMAIL",
      data: profileUser.email || "",
    },
    {
      label: "HOME AIRPORT",
      data: profileUser.home_airport || "",
    },
    {
      label: "AIRCRAFT EXPERIENCE",
      data: profileUser.experience || "",
    },
  ];

  const sendPasswordForgetEmail = async () => {
    try {
      await api.auth.forgotPassword(userData.email);
      setModalType(ModalOptions.Success);
      setIsOpen(true);
    } catch (_error) {
      const error: any = _error;
      console.log({ error });
    }
  };

  const logoutUser = async () => {
    const refreshToken = getRefreshToken();
    try {
      if (refreshToken) {
        await api.auth.logout(refreshToken);
        navigation("/login");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserInfo = async (
    profileData: Partial<UserObject>,
    multiValue: { value: string; label: string }[],
    profileUpload?: ChangeEvent<HTMLInputElement>
  ) => {
    // Handle text inputs
    const updatedProfileData = {
      name: profileData.name!,
      phone: profileData.phone!,
      home_airport: profileData.home_airport!,
      experience: profileData.experience!
    };

    // Handle roles multi-input
    const roleIds = multiValue.map((role: { value: string }) => role.value);

    const updatedRoles = multiValue.map((role) => {
      return { name: role.label, id: role.value };
    });

    try {
      const updatedUserData = {
        ...userData,
        ...updatedProfileData,
        roles: updatedRoles,
      };

      if (profileUpload) {
        await onChangeImage(
          profileUpload,
          setUserData,
          updatedUserData,
          setProfileUser
        );
      }

      const updateUser = api.users.updateUser(updatedProfileData);
      const updateRoles = api.users.updateRoles(roleIds);

      await toast.promise(
        Promise.all([updateUser, updateRoles]),
        TOAST_STATUSES.updateUser
      );

      setProfileUser(updatedUserData);
      setUserData(updatedUserData);
    } catch (_error) {
      const error: any = _error;
      console.error(error);
    } finally {
      setIsOpen(false);
    }
  };

  const handleSuccessSubmit = () => {
    setIsOpen(false);
  };

  const retrieveChildProps = () => {
    switch (modalType) {
      case ModalOptions.EditInfo:
        return {
          updateUserInfo,
        } as EditProfileModalProps;
      case ModalOptions.Success:
        return {
          successMessage: "Email sent",
          secondaryMessage:
            "Check your email and open the link we sent to continue.",
          buttonTitle: "AWESOME",
          handleSubmit: handleSuccessSubmit,
        };
    }
  };

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: modalType,
    childModalProps: retrieveChildProps(),
  };

  useModalData(modalData);

  useEffect(() => {
    setProfileUser(profileUser);
  }, [profileUser]);

  return (
    <div className="flex flex-col bg-neutral-gray items-center lg:items-start lg:flex-row w-full lg:h-full lg:pl-16">
      <div className="hidden md:flex md:self-center lg:self-start">
        <UserInfo logoutUser={logoutUser} />
      </div>
      <div className="w-11/12 md:w-450 md:mx-16">
        {/* Mobile overview header */}
        <div className="md:hidden w-full mt-10">
          <OverviewHeader
            setShowEditModal={setIsOpen}
            setModalType={setModalType}
          />
        </div>

        <div className="flex flex-col justify-evenly bg-x-gray rounded-md mt-4 pb-10 md:mt-10">
          {/* Desktop overview header */}
          <div className="hidden md:block mt-6">
            <OverviewHeader
              setShowEditModal={setIsOpen}
              setModalType={setModalType}
            />
          </div>
          <div className="flex flex-col justify-evenly h-auto py-2 w-full">
            {profileInputs.map((profileData) => {
              return (
                <div className="w-10/12 self-center mt-2" key={profileData.label}>
                  <Label
                    hidden="false"
                    title={profileData.label}
                    required={false}
                  />
                  <div className="h-7 text-sm text-xx-neutral-gray border-b select-none border-gray-600 pl-2 mt-1 w-full focus:outline-none">
                    {
                      profileData.label === "PHONE NUMBER" ? (
                        isCurrentUser ? (
                          <>{profileData.data}</>
                        ) : (
                          <a href={`tel:${profileData.data}`}>{profileData.data}</a>
                        )
                      ) : profileData.label === "EMAIL" ? (
                        isCurrentUser ? (
                          <>{profileData.data}</>
                        ) : (
                          <a href={`mailto:${profileData.data}`}>{profileData.data}</a>
                        )
                      ) : (
                        <>{profileData.data}</>
                      )
                    }
                  </div>
                </div>
              );
            })}
            <div className="w-10/12 self-center mt-2">
              <div className="flex-col">
                <Label hidden="false" title="ROLES" required={false} />
                {!profileUser.roles.length && (
                  <p className="text-xs md:text-sm mt-2 pl-2 pb-1 text-gray-600 w-full border-b border-gray-600">
                    No roles have been set
                  </p>
                )}
                <div className="flex flex-wrap items-center">
                  {profileUser.roles.map((role, idx) => {
                    return (
                      <Tag
                        title={role.name}
                        key={role.id}
                        styles="border-volas-red py-1"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isCurrentUser && (
          <div className="flex flex-col md:flex-row items-center justify-center h-auto md:h-28 w-full bg-x-gray mt-8 md:mb-8 rounded-md">
            <div className="flex flex-col w-10/12 md:w-8/12 h-auto bg-neutral-gray mt-5 md:mt-0 mx-2">
              <p className="text-xxs pl-2 py-2 font-semibold text-xxxxx-gray">
                PASSWORD
              </p>
              <div className="border-b border-gray-600 text-gray-600 bg-neutral-gray pl-2 -mt-1 pb-2 focus:outline-none select-none cursor-none">
                *********
              </div>
            </div>
            <button
              className="self-center w-10/12 md:w-auto text-left text-volas-red cursor-pointer underline text-sm my-4 md:m-0 md:pr-0"
              onClick={() => sendPasswordForgetEmail()}
            >
              Reset Password
            </button>
          </div>
        )}
      </div>
      {isCurrentUser && (
        <div
          className="h-20 mb-20 md:hidden text-volas-red text-sm font-bold flex items-center cursor-pointer"
          onClick={logoutUser}
        >
          <ArrowLight width="8" styles="w-auto mr-2" fill="#bf2431" />
          LOG OUT
        </div>
      )}
    </div>
  );
};

export default Overview;

import { useContext, useEffect } from "react";
import { ModalContext } from "../../../utils/store";
import { ModalProps } from "../../../types/Modal";

/**
 * Loads any user given an id, used for viewing someone else's profile
 *
 * @param modalData
 */
export const useModalData = (modalData: ModalProps) => {
  const { setModalData } = useContext(ModalContext);
  useEffect(() => {
    setModalData(modalData)
  }, [modalData.modalIsOpen, modalData.childModal]);
};

import { useContext, useEffect, useState } from "react";
import { ResourceRole } from "../../../types/ResourceRole";
import { TransactionContext, UserDataContext } from "../../../utils/store";

export const useTransactionRoles = () => {
  const { transaction } = useContext(TransactionContext);
  const { userData } = useContext(UserDataContext);
  const [roles, setRoles] = useState<ResourceRole[]>([]);

  useEffect(() => {
    if (!transaction || !transaction.sharedWith || !userData) return;

    const self = transaction.sharedWith.find((x) => x.id === userData.id);
    if (!self) return;
    setRoles(self.transactionRoles);
  }, [transaction, userData]);

  return roles;
};

export interface RedBtn {
  onClickEvent?: any;
  styles: string;
  disabled?: boolean;
  title: string;
  img?: RedBtnImg;
  imgBeforeText?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
}

export enum RedBtnImg {
  Arrow,
  Privacy
}
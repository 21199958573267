import { SortingOption } from "../types/Sorting";
import { AircraftObject } from "../types/Aircraft";
import {
  DocumentObject,
  DocumentType,
  File,
} from "../types/Document";
import { api } from "../api";
import { DocumentResourceType } from "../types/Resource";
import { TOAST_STATUSES } from "./toast_statuses";
import { toast } from "react-toastify";

export const sortByDate = (
  sortBy: SortingOption,
  documents: DocumentObject[] | AircraftObject[]
) => {
  return documents
    .map((doc) => {
      return { ...doc, created_at: new Date(doc.created_at).getTime() };
    })
    .sort((a, b) =>
      sortBy === SortingOption.DateDESC
        ? b.created_at - a.created_at
        : a.created_at - b.created_at
    )
    .map((doc) => {
      return {
        ...doc,
        created_at: new Date(doc.created_at).toISOString(),
      };
    });
};

export const handleSortData = (sort: SortingOption, documents: any) => {
  const updatedDocs = documents.slice();
  switch (sort) {
    case SortingOption.AlphabeticalASC:
      return updatedDocs.sort((a: any, b: any) =>
        a.title.localeCompare(b.title)
      );

    case SortingOption.AlphabeticalDESC:
      return updatedDocs.sort((a: any, b: any) =>
        b.title.localeCompare(a.title)
      );

    case SortingOption.DateASC:
      return sortByDate(SortingOption.DateASC, updatedDocs);

    case SortingOption.DateDESC: {
      return sortByDate(SortingOption.DateDESC, updatedDocs);
    }
  }
};

/**
 * @param documentTitle user facing name of document entity
 * @param docType Document or Docusign
 * @param isSigned
 * @param fileData a File, else the docusign link if saving a docusign doc
 * @param resourceType
 * @param resourceId
 * @param existingDocumentId
 * @param addAutoTags
 */
export async function submitDocument(
  documentTitle: string,
  docType: DocumentType,
  isSigned: boolean,
  fileData: File,
  resourceType: DocumentResourceType,
  resourceId: string,
  existingDocumentId?: string,
  addAutoTags?: boolean
): Promise<DocumentObject> {
  try {
    const _isDocusign = docType === DocumentType.Docusign;

    const { data, name } = fileData as File

    const document = await toast.promise(
      api.documents.createDocument(
        data,
        documentTitle,
        name,
        resourceType,
        resourceId,
        _isDocusign,
        isSigned,
        existingDocumentId === "" ? undefined : existingDocumentId,
        undefined,
        addAutoTags
      ),
      TOAST_STATUSES.createDocument
    );
    return document;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * @param documentTitle user facing name of document entity
 * @param docType Document or Docusign
 * @param isSigned
 * @param transactionId
 * @param isRequired
 * @param fileData a File, else the docusign link if saving a docusign doc. Optional
 * @param existingDocumentId
 */
export async function submitTransactionDocument(
  documentTitle: string,
  docType: DocumentType,
  isSigned: boolean,
  transactionId: string,
  isRequired: boolean,
  fileData?: File,
  existingDocumentId?: string,
): Promise<DocumentObject> {
  try {
    const _isDocusign = docType === DocumentType.Docusign;

    const _undefinedFileData = { data: undefined, name: "" };

    const { data, name } = fileData ? fileData : _undefinedFileData;

    const document = await toast.promise(
      api.documents.createDocument(
        data,
        documentTitle,
        name,
        DocumentResourceType.Transaction,
        transactionId,
        _isDocusign,
        isSigned,
        existingDocumentId === "" ? undefined : existingDocumentId,
        { isRequired },
      ),
      TOAST_STATUSES.createDocument
    );
    return document;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

import React, { useEffect, useState } from "react";
import Select, { MultiValue } from "react-select";
import { ResourceRole } from "../../types/ResourceRole";
import {
  DropdownTypes,
  InvitationTypes,
  UserListItems,
} from "../../types/Invitations";
import volasUser from "../../images/volas-user.svg";
import { UserRoles } from "../../types/UserRoles";
import { truncateString } from "../../utils/helpers";
import Label from "../Forms/Label";
import { useResourceRoles } from "../Modals/hooks/useResourceRoles";
import { useUserId } from "./hooks/useUserId";
import PermissionDropdown from "./PermissionDropdown";
import {api} from "../../api";
import {Permission} from "../../types/Permission";

const UserListItem: React.FC<UserListItems> = (props) => {
  const {
    invitationRecipient,
    userInviteList,
    setUserInviteList,
    permissionsList,
    shareType,
    hidePermissionMenu,
    setResourceRoleIds,
  } = props;

  const id = useUserId(invitationRecipient);

  const resourceRoles = useResourceRoles(shareType);

  const mappedRoleOptions = resourceRoles
    .map((role) => {
      return { label: role.name, value: role.id };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const [filterOptions, setFilterOptions] = useState(mappedRoleOptions);

  // This refers to user roles, could use a better name
  const [multiValue, setMultiValue]: MultiValue<any> = useState([]);
  const [fetchedRecipientPicture, setFetchedRecipientPicture] = useState<string>(volasUser)
  const [isEscrowSelected, setIsEscrowSelected] = useState(false)
  const [updatedPermission, setUpdatedPermission] = useState<
    undefined | string
    >(undefined);

  useEffect(() => {
    setResourceRoleIds(
      multiValue.map((x: { label: string; value: string }) => x.value)
    );
  }, [multiValue]);

  // For aircraft users, when permission is not set or set to viewer, allow the potential buyer role to be selected
  // This role is not allowed to be set with the admin permission
  useEffect(() => {
    if (shareType !== InvitationTypes.AircraftUser) {
      return;
    }

    if (updatedPermission?.toLowerCase() === 'admin') {
      const removePotentialBuyerFilter = (roles: {label: string; value: string}[]) => {
        return roles.filter(role => {
          return role.label !== 'potential buyer'
        });
      }

      setFilterOptions(removePotentialBuyerFilter(mappedRoleOptions));
      setMultiValue(removePotentialBuyerFilter(multiValue));
    } else {
      setFilterOptions(mappedRoleOptions);
    }
  }, [updatedPermission, resourceRoles])

  const selectRolesInputStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      border: "none",
    }),
    menuList: (provided: any, state: any) => {
      const maxHeight = "100px";
      const backgroundColor = "white";
      return { ...provided, maxHeight, backgroundColor };
    },
    dropdownIndicator: (provided: any, state: any) => {
      const cursor = "pointer";
      return { ...provided, cursor };
    },
  };

  const handleMultiChange = (option: MultiValue<any>) => {
    setMultiValue(option);
  };

  // TODO: make this logic work for transaction roles select (not working currently)
  useEffect(() => {
    // A user can't have both a 'Seller' and a 'Buyer' role, so this logic ensures both can't be selected
    // This only applies to transaction user roles, so return otherwise

    if (shareType !== InvitationTypes.TransactionUser) {
      return
    }

    const setRecipient = async () => {
      if (invitationRecipient && invitationRecipient.picture && !invitationRecipient.picture.includes("undefined")) {
        try {
          // confirm fetch doesn't fail
          const _ = await api.users.getProfilePicture(invitationRecipient.id!)
          setFetchedRecipientPicture(invitationRecipient.picture)
        } catch {}
      }
    }

    const mappedMultiSelect = multiValue.map(
      (role: { label: string }) => role.label
    );

    const sellerIndex = multiValue.findIndex(
      (role: any) => role.label === UserRoles.Seller
    );

    const buyerIndex = multiValue.findIndex(
      (role: any) => role.label === UserRoles.Buyer
    );

    const buyerAndSellerFilter = (role: string) => {
      return (
        (sellerIndex !== -1 && role === UserRoles.Buyer) ||
        (buyerIndex !== -1 && role === UserRoles.Seller)
      );
    };

    const updatedFilteredOptions = resourceRoles
      .filter((role: ResourceRole) => !mappedMultiSelect.includes(role.name))
      .filter((role) => !buyerAndSellerFilter(role.name))
      .map((role) => {
        return { label: role.name, value: role.id };
      });

    setFilterOptions(updatedFilteredOptions);
    setRecipient()
  }, [setMultiValue, multiValue, resourceRoles, setFetchedRecipientPicture]);

  const showNonMemberAsterisk = !id;
  const showNewInviteAsterisk = invitationRecipient.isNewInvite;
  const showPendingInviteAsterisk = invitationRecipient.hasPendingInvite;

  // Detect if escrow is one of the roles
  // If it is, we need to set the permission role as admin
  useEffect(() => {
    const isEscrowSelected = multiValue.map((val: any) => val.label).includes('escrow')
    if (isEscrowSelected) {
      setUpdatedPermission(Permission.ADMIN)
      setIsEscrowSelected(true)
    } else {
      setIsEscrowSelected(false)
    }
  }, [multiValue])

  return (
    <div className="flex flex-col pb-2">
      <div
        className="self-center flex border-t items-center w-full h-auto py-3 justify-between"
        key={invitationRecipient.email}
      >
        <div className="flex items-center text-xs md:text-sm">
          <img
            src={fetchedRecipientPicture}
            alt=""
            className="mr-2 md:mr-4 h-8 w-8 rounded-full md:h-9.5"
          />
          <div className="flex flex-col text-left">
            {invitationRecipient.name && (
              <p className="text-logo-gray">{invitationRecipient.name}</p>
            )}
            <p className="text-welcome-gray">
              {truncateString(invitationRecipient.email, 17)}
            </p>
          </div>
          { showNewInviteAsterisk && <span className="text-lg text-welcome-gray">*</span> }
          { showPendingInviteAsterisk && <span className="text-lg text-welcome-gray">**</span> }
        </div>
      </div>

      {!hidePermissionMenu && (
        <div className="flex w-11/12 md:w-10/12 ml-4">
          <div className="flex items-center w-full">
            <div className="flex w-24 whitespace-nowrap mr-3">
              <Label
                hidden="false"
                title="PERMISSION"
                required={true}
              />
            </div>
            <PermissionDropdown
              isDisabled={isEscrowSelected}
              user={invitationRecipient}
              userEmailList={userInviteList}
              setUserEmailList={setUserInviteList}
              rolesList={permissionsList}
              dropdownType={DropdownTypes.Permissions}
              updatedPermission={updatedPermission}
              setUpdatedPermission={setUpdatedPermission}
            />
          </div>
        </div>
      )}

      {[InvitationTypes.AircraftUser, InvitationTypes.TransactionUser].includes(
        shareType
      ) && (
        <div className="flex w-11/12 md:w-10/12 ml-4">
          <div className="flex items-center w-full">
            <div className="flex w-24 whitespace-nowrap mr-3">
              <Label
                hidden="false"
                title={
                  shareType === InvitationTypes.AircraftUser
                    ? "ROLE"
                    : "REQUIRED ROLE"
                }
                required={true}
              />
            </div>
            <Select
              className="w-2/3 text-left text-xs ring-1 mt-2 rounded"
              name="filters"
              placeholder="Add roles"
              value={multiValue}
              options={filterOptions}
              onChange={handleMultiChange}
              styles={selectRolesInputStyles}
              isMulti
            />
          </div>
        </div>
      )}

      {isEscrowSelected && (
        <div className="mt-4 text-xxs text-xxxxx-gray">
          *Escrow selected, permission set to admin
        </div>
      )}
    </div>
  );
};

export default UserListItem;

import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import RedButton from "../Buttons/RedButton";
import Label from "../Forms/Label";
import { AircraftModalProps } from "../../types/Modal";
import {AircraftModalTypes} from "../../types/Aircraft";
import AsyncSelect from 'react-select/async';
import {api} from "../../api";

const AircraftModal: React.FC<AircraftModalProps> = (props) => {
  const { modalType, aircraftData, handleSubmit } = props;
  const [aircraftTitle, setAircraftTitle] = useState(aircraftData?.title || "");
  const [aircraftType, setAircraftType] = useState(aircraftData?.type || "");
  const [aircraftBase, setAircraftBase] = useState(aircraftData?.base || "");
  const [tailNumber, setTailNumber] = useState(aircraftData?.tail_number || "N");
  const [baseName, setBaseName] = useState<string | undefined>(undefined);
  const [addAsOwner, setAddAsOwner] = useState(true);
  const [profilePicture, setProfilePicture] = useState<File>();
  const [serialNumber, setSerialNumber] = useState(
    aircraftData?.serial_number || ""
  );

  const handleChange = () => {
    setAddAsOwner(!addAsOwner);
  };

  // React select customization
  const CustomDropdownIndicator = () => null; // custom component or null to hide
  const CustomIndicatorSeparator = () => null; // custom component or null to hide

  const customComponents = {
    DropdownIndicator: CustomDropdownIndicator,
    IndicatorSeparator: CustomIndicatorSeparator,
  };

  const customStyles = {
    menu: (provided: any, state: any) => ({
      ...provided,
      color: state.selectProps.menuColor,
      border: '1px solid gray',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
    }),
  }

  // @ts-ignore
  const formatOptionLabel = ({ label, value }) => (
    <div>
      <div>{label}</div>
      <small>{value}</small>
    </div>
  );

  const NAME_INPUT_TITLE = "aircraft name";
  const TYPE_INPUT_TITLE = "aircraft type";
  const BASE_INPUT_TITLE = "aircraft base";
  const TAIL_INPUT_TITLE = "tail number";
  const SERIAL_INPUT_TITLE = "serial number";
  const isTailInput = (title: string) => title === TAIL_INPUT_TITLE
  const isBaseInput = (title: string) => title === BASE_INPUT_TITLE

  const aircraftDataOptions = (
    inputValue: string
  ) => {
    if (inputValue.length < 3) { return new Promise((resolve) => resolve([])) }
    return api.aircraftData.searchAircraftData(inputValue)
  };

  const aircraftInputs = [
    {
      title: NAME_INPUT_TITLE,
      value: aircraftTitle,
      setData: setAircraftTitle,
      maxLength: 35
    },
    {
      title: TYPE_INPUT_TITLE,
      value: aircraftType,
      setData: setAircraftType,
      maxLength: 35
    },
    {
      title: BASE_INPUT_TITLE,
      value: aircraftBase,
      setData: setAircraftBase,
      maxLength: 35
    },
    {
      title: TAIL_INPUT_TITLE,
      value: tailNumber,
      setData: setTailNumber,
      maxLength: 6
    },
    {
      title: SERIAL_INPUT_TITLE,
      value: serialNumber,
      setData: setSerialNumber,
      maxLength: 35
    },
  ];

  const invalidForm = () => {
    const requiredFields = [
      aircraftTitle,
      aircraftType,
      aircraftBase,
      tailNumber,
      serialNumber,
    ];

    return requiredFields.some((e) => !e);
  };

  /**
   * passes form data handleSubmit prop
   * aircraft id is passed to update submit method
   */
  const _handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    handleSubmit(
      {
        title: aircraftTitle,
        type: aircraftType,
        base: aircraftBase,
        tailNumber,
        serialNumber,
        files: profilePicture,
        addAsOwner
      },
      aircraftData?.id
    );
  };

  useEffect(() => {
    (async () => {
      if (aircraftBase) {
        const base = await api.aircraftData.getAircraftDataByLocationId(aircraftBase);
        setBaseName(base.name);
      }
    })();
  }, []);

  // @ts-ignore
  return (
    <div className="w-full h-auto md:w-468">
      <form className="flex flex-col items-center" onSubmit={_handleSubmit}>
        <h1 className="text-2xl my-10">{`${modalType} Aircraft`}</h1>
        <div className="flex flex-col justify-evenly h-auto w-full">
          {aircraftInputs?.map((aircraftInput, index) => {
            return (
              <div
                className="w-11/12 md:w-10/12 self-center rounded-sm ring-1 my-2"
                key={aircraftInput.title}
              >
                <Label
                  hidden="false"
                  title={aircraftInput.title.toUpperCase()}
                  required
                />
                {isBaseInput(aircraftInput.title) ? (
                  <AsyncSelect
                    formatOptionLabel={formatOptionLabel}
                    styles={customStyles}
                    components={customComponents}
                    className="text-sm text-gray-500 no-border border-collapse focus:outline-none"
                    placeholder="Search by name or location"
                    loadOptions={aircraftDataOptions}
                    value={{label: baseName, value: aircraftInput.value}}
                    onChange={(e) => {
                      setBaseName(e ? e!.label : "")
                      aircraftInput.setData(e ? e!.value : "")
                    }}
                    noOptionsMessage={() => "Please Search at least 3 letters"}
                  />
                ) : (
                  <input
                    autoFocus={index === 0}
                    className="text-sm text-gray-500 h-6 border-b border-gray-600 pt-1 pb-2 pl-2 w-full focus:outline-none"
                    type="text"
                    required
                    value={aircraftInput.value}
                    maxLength={aircraftInput.maxLength}
                    onChange={(e) => {
                      // Force "N" as the first character for tail input
                      if (isTailInput(aircraftInput.title) && (!e.target.value || (e.target.value.length === 1 && e.target.value !== "N"))) {
                        aircraftInput.setData("N" + e.target.value)
                      } else {
                        aircraftInput.setData(e.target.value)
                      }
                    }}
                  />
                )}
              </div>
            );
          })}
          <div
            className="w-11/12 md:w-10/12 self-center rounded-sm ring-1 my-2"
            key="profile_picture"
          >
            <Label
              hidden="false"
              title="Profile Picture"
              required={false}
            />
            <input
              type="file"
              onChange={(e) => setProfilePicture(e.target.files![0])}
            />
          </div>

          {modalType === AircraftModalTypes.Add && (
            <div
              className="w-11/12 md:w-10/12 self-center rounded-sm ring-1 my-2"
              key="add_as_owner"
            >
              <Label
                hidden="false"
                title={'Add self as owner?'}
                required={false}
              />
              <input type="checkbox" className="ml-2" checked={addAsOwner} onChange={handleChange} />
            </div>
          )}
        </div>
        <RedButton
          type="submit"
          styles="self-center w-44 mb-8 mt-12 px-3 py-3 text-xs"
          title="SAVE"
          disabled={invalidForm()}
        />
      </form>
    </div>
  );
};

export default AircraftModal;

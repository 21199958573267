import React, {useContext, useEffect, useState} from "react";
import {
  DocModalType,
  DocumentObject,
  DocumentProps,
} from "../../types/Document";
import MeatballMenu from "../UI/MeatballMenu";
import { truncateString, viewResource } from "../../utils/helpers";
import { api } from "../../api";
import { ModalOptions } from "../../types/Modal";
import share from "../../images/share.svg";
import deleteIcon from "../../images/delete-icon.svg";
import checkIcon from "../../images/checkmark.svg";
import editIcon from "../../images/edit-icon.svg";
import download from "../../images/download.svg";
import { ResourceContainer } from "../UI/Containers/ResourceContainer";
import { useDocumentPermission } from "../../hooks/useDocumentPermission";
import { Permission } from "../../types/Permission";
import {toast} from "react-toastify";
import {TOAST_STATUSES} from "../../utils/toast_statuses";
import {TransactionContext} from "../../utils/store";
import {useParams} from "react-router-dom";

const Document: React.FC<DocumentProps> = (props) => {
  const {
    document,
    setSelectedDoc,
    setModalType,
    setDocModalType,
    setIsOpen,
    readOnly,
    isRequiredDoc
  } = props;

  const [documentVersions, setDocumentVersions] = useState<DocumentObject[]>(
    []
  );
  const { setTransaction } = useContext(TransactionContext);
  const { transactionId } = useParams();
  const [showMeatballMenu, setShowMeatballMenu] = useState(false);
  const permission = useDocumentPermission(document);
  const isAdmin = [Permission.ADMIN].includes(permission);

  useEffect(() => {
    (async () => {
      try {
        const versions = await api.documents.getVersions(document.id);
        setDocumentVersions(versions);
      } catch (_error) {
        const error: any = _error;
        console.error(error);
      }
    })();
  }, [document]);

  const onViewFile = async (fileKey: string) => {
    if (!fileKey) { return }
    const signedUrl = await api.documents.getDocumentSignedUrl(fileKey);
    viewResource(signedUrl);
  };

  const hasNoFile = !document.file_name;
  const docTitle = (): string => {
    const title = truncateString(document.title, 24);
    return document.is_docusign ? `${title} (e-Sign)` : title;
  };

  const handleUpdateDocToESign = async () => {
    try {
      await toast.promise(async () => {
          await api.documents.updateDocument(document.id, {
            title: document.title,
            is_docusign: true
          })
          if (transactionId) {
            const transaction = await api.transactions.getTransaction(
              transactionId
            );
            setTransaction(transaction);
          }
        },
        TOAST_STATUSES.updateDocToEsign
      )
    } catch (error) {
      console.error(error);
    }
  }

  const onClick = async () => {
    return hasNoFile && (isRequiredDoc && isAdmin)
      ? (setSelectedDoc(document),
        setIsOpen!(true),
        setDocModalType(DocModalType.Edit),
        setModalType(ModalOptions.Document))
      : onViewFile(document.file_key);
  };

  const showEdit = (): boolean => {
    if(isRequiredDoc) {
      return isAdmin;
    }

    return true;
  }

  const shareMenuItem = !document.file_name || !isAdmin || readOnly
    ? []
    : [
        {
          title: "Share",
          img: share,
          events: [
            () => setModalType(ModalOptions.Share),
            () => setIsOpen!(true),
          ],
        },
      ];

  const viewMenuItem = !hasNoFile
    ? [
        {
          title: `View / Download`,
          img: download,
          events: [() => onViewFile(document.file_key)],
        },
      ]
    : [];

  const deleteMenuItem = isAdmin && !readOnly ? [
    {
      title: "Delete Document",
      img: deleteIcon,
      events: [
        () => setIsOpen!(true),
        () => setModalType(ModalOptions.DeleteResource),
      ],
    },
  ] : [];

  const markAsESignItem = transactionId && !document.is_docusign && !readOnly ? [
    {
      title: "Mark as ESign",
      img: checkIcon,
      events: [
        handleUpdateDocToESign,
      ],
    },
  ] : [];

  const editMenuItem = showEdit() && !readOnly ? [
    {
      title: "Edit / Upload",
      img: editIcon,
      events: [
        () => setIsOpen!(true),
        () => setDocModalType(DocModalType.Edit),
        () => setModalType(ModalOptions.Document),
      ],
    },
  ] : [];

  const meatballMenuItems = [
    ...shareMenuItem,
    ...editMenuItem,
    ...markAsESignItem,
    ...deleteMenuItem,
    ...viewMenuItem,
  ];

  return (
    <ResourceContainer
      title={docTitle()}
      subTitle={truncateString(document.file_name, 28, true)}
      timestamp={document.created_at}
      onClick={onClick}
      onViewFile={onViewFile}
      documentVersions={!readOnly ? documentVersions : undefined}
      meatBallMenu={
        !!meatballMenuItems.length ?
        <MeatballMenu
          meatballMenuItems={meatballMenuItems}
          setSelectedDoc={setSelectedDoc}
          showMeatballMenu={showMeatballMenu}
          setShowMeatballMenu={setShowMeatballMenu}
          doc={document}
        /> : undefined
      }
    />
  );
};

export default Document;

import React from "react";
import greenCheck from "../../images/green-check-success.svg";
import { SuccessModalProps } from "../../types/Modal";
import RedButton from "../Buttons/RedButton";

const SuccessModal: React.FC<SuccessModalProps> = (props) => {
  const {
    buttonTitle,
    successMessage,
    secondaryMessage,
    handleSubmit,
  } = props;

  return (
    <div className="flex flex-col items-center text-xxx-gray justify-center w-full md:w-336 h-312">
      <img src={greenCheck} alt="" className="w-11 self-center mt-4 mb-6" />
      <h2 className="text-2xl text-gray-700 tracking-wider mt-1 mb-5">
        {successMessage}
      </h2>
      {secondaryMessage && (
        <h3 className="text-base mx-7 text-center">{secondaryMessage}</h3>
      )}
      <RedButton
        onClickEvent={handleSubmit}
        styles="w-44 px-3 py-3 my-5 text-xs self-center"
        title={buttonTitle}
      />
    </div>
  );
};

export default SuccessModal;

import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Tag } from "../../types/Tag";
import RedButton from "../Buttons/RedButton";
import { api } from "../../api";
import { Paperwork } from "../../models/Paperwork";
import QuickSelectOptions from "../TagsModal/QuickSelectOptions";
import CreatedTagDropdown from "../TagsModal/CreatedTagDropdown";
import {UserObject} from "../../types/User";
export interface TagsModalProps {
  selectedDocument?: Paperwork;
  selectedUser?: UserObject;
  handleSubmit: (newTag: string, tag?: Tag) => void;
}

enum TagType {
  Document,
  Network
}

const TAG_MAX_LENGTH = 18;

const TagsModal = ({ selectedDocument, selectedUser, handleSubmit }: TagsModalProps) => {
  const [userCreatedTags, setUserCreatedTags] = useState<Map<string, Tag>>(
    new Map()
  );
  const [quickSelectTags, setQuickSelectTags] = useState<Tag[]>([]);
  const [tagInputValue, setTagInputValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const tagType: TagType = !!selectedDocument ? TagType.Document : TagType.Network;
  const resourceTitle = tagType === TagType.Document ? selectedDocument!.title! : selectedUser!.name;
  const selectedResource = tagType === TagType.Document ? selectedDocument : selectedUser;

  const handleNewTagInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTagInputValue(e.target.value);
  };

  const getTags = useCallback(async () => {
    const tagMap = new Map();

    let tags: Tag[] = []
    if (tagType === TagType.Document) {
      // retrieve all document tags
      tags = await api.tags.getTags();
    } else {
      // retrieve all network tags
      tags = await api.tags.getNetworkTags();
    }

    // remove any tags that already exist on the document
    const selectedResourceTagIds = selectedResource && selectedResource.tags ? selectedResource.tags.map(tag => tag.id) : [];
    tags.forEach((tag: Tag) => {
      if (!selectedResourceTagIds.includes(tag.id)) {
        tagMap.set(tag.id, tag);
      }
    });
    setUserCreatedTags(tagMap);
  }, [selectedDocument]);

  /**
   * populates the input field with selected tag
   */
  const handleQuickSelect = (tag: Tag) => {
    setTagInputValue(tag.name);
  };

  const _handleSubmit = () => {
    setLoading(true);
    const isKnownTag =
      quickSelectTags[0]?.name &&
      quickSelectTags[0]?.name.toLowerCase() === tagInputValue.toLowerCase();
    const tag = isKnownTag ? quickSelectTags[0] : undefined;
    handleSubmit(tagInputValue, tag);
  };


  useEffect(() => {
    getTags();
  }, [getTags]);

  return (
    <div>
      <div className="mb-5">
        <h2 className="text-2xl text-gray-700 tracking-wide text-center">
          Add a tag
        </h2>
        <div className="text-xs md:text-base text-center text-xx-neutral-gray">
          {resourceTitle}
        </div>
      </div>
      <input
        autoFocus={true}
        className="text-sm text-gray-500 h-6 border-b border-gray-600 pt-1 pb-2 pl-2 w-full focus:outline-none"
        type="text"
        value={tagInputValue}
        placeholder="Enter tag name"
        onChange={handleNewTagInputChange}
        maxLength={TAG_MAX_LENGTH}
      />
      <div className="my-4">
        <QuickSelectOptions
          isVisible={!!quickSelectTags.length}
          quickSelectTags={quickSelectTags}
          handleClick={handleQuickSelect}
        />
        <CreatedTagDropdown
          isVisible={!quickSelectTags.length}
          tags={Array.from(userCreatedTags.values())}
          handleClick={handleQuickSelect}
          tagInputValue={tagInputValue}
        />
      </div>

      <div className="mt-4">
        <RedButton
          onClickEvent={_handleSubmit}
          styles="w-44 px-3 py-3 my-2 text-xs mx-auto"
          title="SAVE"
          disabled={isLoading || !tagInputValue}
        />
      </div>
    </div>
  );
};

export default TagsModal;

import { Tab, TabsPage } from "../components/UI/Tabs";

const PROFILE_TABS = (id: string): Tab[] => {
  return [
    {
      title: "OVERVIEW",
      link: `/profile/${id}`,
    },
    {
      title: "BRIEFCASE",
      link: `/profile/${id}/documents`,
    },
    {
      title: "PERMISSIONS",
      link: `/profile/${id}/permissions`,
    },
    {
      title: "INVITATIONS",
      link: `/profile/${id}/invitations`,
    },
  ];
};

const DOCUMENT_TABS = (): Tab[] => {
  return [
    {
      title: "DOCUMENTS",
      link: `/documents`,
    },
    {
      title: "TEMPLATES",
      link: `/documents/templates`,
    },
  ];
};

const FLEET_TABS = (): Tab[] => {
  return [
    {
      title: "MY FLEET",
      link: `/aircraft`,
    },
  ];
};

const AIRCRAFT_TABS = (id: string): Tab[] => {
  return [
    {
      title: "OVERVIEW",
      link: `/aircraft/${id}`,
    },
    {
      title: "DOCUMENTS",
      link: `/aircraft/${id}/documents`,
    },
    {
      title: "USERS",
      link: `/aircraft/${id}/users`,
    },
    {
      title: "TRANSACTIONS",
      link: `/aircraft/${id}/transactions`,
    },
  ];
};

const TRANSACTIONS_TABS = (): Tab[] => {
  return [
    {
      title: "TRANSACTIONS",
      link: `/transactions`,
    },
  ];
};

const NETWORK_TABS = (count?: string): Tab[] => {
  return [
    {
      title: "MY NETWORK",
      link: `/network/my-network`,
    },
    {
      title: "INVITATIONS",
      link: `/network/invitations`,
      count
    }
  ];
};
const ADMIN_TABS = (): Tab[] => {
  return [
    {
      title: "INVITATIONS",
      link: `/admin/invite`,
    },
    {
      title: "ALL USERS",
      link: `/admin/users`,
    },
    {
      title: "ANALYTICS",
      link: `/admin/analytics`,
    },
  ];
};

// this is returning briefcase when it shouldn't
export const retrieveTabsFor = (
  page: TabsPage,
  id?: string,
  tabsTitlesToExclude?: string[],
  count?: string,
): Tab[] => {
  const profileTabFilter = (tab: Tab) => {
    let title = tab.title;
    return !tabsTitlesToExclude!.includes(title.toLowerCase());
  };

  switch (page) {
    case TabsPage.Profile:
      const tabs = PROFILE_TABS(id!);
      return tabs.filter(profileTabFilter);
    case TabsPage.Documents:
      return DOCUMENT_TABS();
    case TabsPage.Fleet:
      return FLEET_TABS();
    case TabsPage.Aircraft:
      return AIRCRAFT_TABS(id!);
    case TabsPage.Transactions:
      return TRANSACTIONS_TABS();
    case TabsPage.Network:
      return NETWORK_TABS(count);
    case TabsPage.Admin:
      return ADMIN_TABS();
    default:
      return [];
  }
};

import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import pattern from "../../images/yellow-pattern.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import arrow from "../../images/arrow.svg";
import x from "../../images/x.svg";
import arrowLight from "../../images/arrow-light.svg";
import { Role } from "../../types/Role";
import { UserDataContext } from "../../utils/store";
import { api } from "../../api";
import RedButton from "../Buttons/RedButton";
import { RedBtnImg } from "../../types/Button";

const Experience = (props: any) => {
  const navigate = useNavigate();

  const { userData, setUserData } = useContext(UserDataContext);

  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);

  const [queryParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      try {
        const _roles = await api.roles.getRoles();
        setRoles(_roles);

        const user = await api.users.getCurrentUser();
        setUserRoles(user.roles.map((x: Role) => x.id));
        setUserData({
          ...userData,
          roles: user.roles,
          preventNavigationOnUpdate: false,
        });
      } catch (_error) {
        const error: any = _error;
        console.error(error);
      }
    })();
  }, []);

  const updateUserRoles = (e: SyntheticEvent, roleId: string) => {
    e.preventDefault();

    const indexOfRole = userRoles.indexOf(roleId);
    let updatedUserRoles = userRoles.slice();

    if (indexOfRole === -1) {
      updatedUserRoles.push(roleId);
    } else {
      updatedUserRoles.splice(indexOfRole, 1);
    }
    setUserRoles(updatedUserRoles);
  };

  const profileLink = `/profile/${userData.id}?resourceStr=${queryParams.get("resourceStr")}`;

  const sendUserRoles = async () => {
    try {
      await api.users.updateRoles(userRoles);
      navigate(profileLink);
    } catch (_error) {
      const error: any = _error;
      console.error(error);
    }
  };

  const returnRoleByCategory = (category: string) => {
    return roles.filter((role) => role.category === category);
  };

  return (
    <div className="flex flex-col text-center h-screen md:w-2/3 md:text-left md:pl-16 lg:pl-28 overflow-auto">
      <div className="absolute top-0 right-0 ml-2 md:ml-0 flex">
        <button
          className={`${!showModal && "md:flex"} hidden mt-4 h-8 -ml-8`}
          type="button"
          onClick={() => setShowModal(true)}
          data-modal-toggle="default-modal"
        >
          <p className="self-center text-xs">Skip for now</p>
          <img
            alt=""
            src={arrow}
            className="self-center ml-2 mr-6 transform rotate-180"
          />
        </button>

        <div
          className={`${!showModal && "md:hidden"
            } hidden absolute top-0 right-0 w-96 h-56 text-sm border text-center mt-4 md:flex flex-col mr-4 rounded-lg bg-xx-gray shadow-lg`}
        >
          <img
            src={x}
            alt=""
            className="text-lg cursor-pointer self-end mt-2 mr-2 text-gray-400 h-3"
            onClick={() => setShowModal(false)}
          />
          <div className="px-4">
            <p className="font-bold mt-4 mb-2">
              If you choose to skip the profile building process, you can return
              to it later from your profile.
            </p>
            <p>
              You won't be added to the Volas network until you've completed
              your profile.
            </p>
            <Link to={profileLink} className="flex justify-center">
              <RedButton
                styles="self-center w-24 mt-8 px-3 py-3 text-xs"
                title="SKIP"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="flex flex-col my-auto h-full md:h-auto w-10/12 md:w-full self-center md:self-start">
        <div className="text-left mx-6 md:mx-0">
          <div className="flex flex-col text-center md:text-left">
            <p className="mobile-steps">YOUR EXPERIENCE 2/2</p>
            <h1 className="text-xxl md:text-xxxl text-xxx-gray">
              Aviation Experience
            </h1>
            <img
              src={pattern}
              alt=""
              className="yellow-pattern my-6 self-center md:self-start"
            />
          </div>
          <div className="w-full flex flex-col">
            <h3 className="font-semibold mt-8 text-sm md:text-base">ROLE</h3>
            <p className="text-welcome-gray text-xs md:text-sm mt-4 md:mt-0">
              Select all that apply
            </p>
            <div className="flex flex-col md:flex-row mt-6 w-full">
              <div className="roles-container">
                <h3 className="experience-header">FLYING</h3>
                {returnRoleByCategory("flying").map((role) => {
                  return (
                    <div key={role.id} className="roles-div">
                      <button
                        className={`exp-btn ${userRoles.includes(role.id)
                          ? "exp-btn-selected"
                          : "exp-btn-non-selected"
                          }`}
                        onClick={(e) => updateUserRoles(e, role.id)}
                      >
                        {role.name}
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="roles-container">
                <h3 className="experience-header">FLIGHT SUPPORT</h3>
                {returnRoleByCategory("flightSupport").map((role) => {
                  return (
                    <div key={role.id} className="roles-div">
                      <button
                        className={`exp-btn ${userRoles.includes(role.id)
                          ? "exp-btn-selected"
                          : "exp-btn-non-selected"
                          }`}
                        onClick={(e) => updateUserRoles(e, role.id)}
                      >
                        {role.name}
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="roles-container">
                <h3 className="experience-header">BUSINESS</h3>
                {returnRoleByCategory("business").map((role) => {
                  return (
                    <div key={role.id} className="roles-div">
                      <button
                        className={`exp-btn ${userRoles.includes(role.id)
                          ? "exp-btn-selected"
                          : "exp-btn-non-selected"
                          }`}
                        onClick={(e) => updateUserRoles(e, role.id)}
                      >
                        {role.name}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:justify-left mb-8 md:mb-16 mt-12">
          <RedButton
            onClickEvent={sendUserRoles}
            styles="self-center w-40 px-3 py-3 text-xs"
            title="NEXT"
            img={RedBtnImg.Arrow}
          />
          <p className="hidden md:block text-sm md:text-sm px-8 mt-6 md:mt-0 self-center">
            The next step is to build your Volas profile.
          </p>
        </div>
        <div className="md:hidden flex flex-col md:flex-row md:justify-left mb-16 h-12">
          <Link
            to={profileLink}
            className="flex justify-center self-center text-black text-sm"
            type="button"
          >
            Skip
            <img
              alt=""
              src={arrowLight}
              className="transform rotate-180 self-center ml-3"
            />
          </Link>
          <p className="font-light text-xs text-center w-1/2 self-center h-10 pb-14 mt-6">
            You can fill this out from your Volas profile
          </p>
        </div>
      </div>
    </div>
  );
};

export default Experience;

import { DocumentResourceType } from "../types/Resource";

export default class Document {
  constructor(
    public id: string,
    public created_at: string,
    public updated_at: string,
    public title: string,
    public file_name: string,
    public file_key: string,
    public is_docusign: boolean,
    public is_signed: boolean,
    public resource_type: DocumentResourceType,
    public created_by: string,
  ) {}
}
import { Tag } from "../../types/Tag";
import TagList from "../UI/Tags/TagList";
interface QuickSelectOptionsProps {
  isVisible: boolean;
  quickSelectTags: Tag[];
  handleClick: (tag: Tag) => void;
}
/**
 * shows available quick select tags if text reveals matches
 *
 * @param isVisible
 * @param quickSelectTags
 * @param handleClick
 * @returns
 */
const QuickSelectOptions = ({
  isVisible,
  quickSelectTags,
  handleClick,
}: QuickSelectOptionsProps) => {
  if (!isVisible) {
    return <></>;
  }
  return (
    <div className="h-8">
      <TagList tagList={quickSelectTags} handleOnClick={handleClick} />
    </div>
  );
};

export default QuickSelectOptions;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TxDocumentType } from "../../types/Transaction";
import FinalizeTransaction from "./FinalizeTransaction"
import RequiredApprovals from "./RequiredApprovals";
import TransactionDocuments from "./TransactionDocuments";
import TransactionUsers from "./TransactionUsers";
import ArrowLight from "../Icons/ArrowLight";

const TransactionPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const BackButton = () => (
    <div
      className="flex items-center cursor-pointer -mt-4"
      onClick={() => navigate(state ? (state as string) : "/transactions")}
    >
      <ArrowLight width="8" styles="w-4 mr-2" fill="#bf2431" />
      <span className="text-volas-red text-sm font-bold">BACK</span>
    </div>
  );

  return (
    <div className="pt-8 w-full px-3 md:p-16 pb-32 md:pb-20 bg-neutral-gray">
      <BackButton />
      <FinalizeTransaction />
      <div className="tx-divider" />
      <TransactionUsers />
      <div className="tx-divider" />
      <RequiredApprovals />
      <div className="tx-divider" />
      <TransactionDocuments
        txDocumentType={TxDocumentType.Required}
      />
      <div className="tx-divider" />
      <TransactionDocuments
        txDocumentType={TxDocumentType.Optional}
      />
    </div>
  );
};

export default TransactionPage;

import React, { useContext, useState } from "react";
import { truncateString } from "../../utils/helpers";
import MeatballMenu from "../UI/MeatballMenu";
import { Link } from "react-router-dom";
import Tag from "../UI/Tag";
import editIcon from "../../images/edit-icon.svg";
import deleteIcon from "../../images/delete-icon.svg";
import { UserObject } from "../../types/User";
import { DeleteModalOptions, ModalOptions } from "../../types/Modal";
import { Permission } from "../../types/Permission";
import { toast } from "react-toastify";
import { api } from "../../api";
import { AircraftContext, UserDataContext } from "../../utils/store";
import { TOAST_STATUSES } from "../../utils/toast_statuses";
import volasUser from "../../images/volas-user.svg";
import { useModalData } from "../Modals/hooks/useModalData";
import { InvitationTypes } from "../../types/Invitations";
import { Role } from "../UI/Role";
import { useAircraftPermission } from "../../hooks/useAircraftPermission";
import { User } from "../UI/User";

const AircraftUser: React.FC<{
  user: UserObject;
  showRemoveUser: boolean;
  pending: boolean;
  onDelete?: () => void;
}> = (props) => {
  const { user, showRemoveUser, pending, onDelete } = props;

  const { aircraftData, setAircraftData } = useContext(AircraftContext);
  const permission = useAircraftPermission();

  const [showMeatballMenu, setShowMeatballMenu] = useState(false);
  const [modalType, setModalType] = useState(ModalOptions.Default);
  const [modalIsOpen, setIsOpen] = useState(false);

  const permissionsList = [Permission.VIEWER, Permission.ADMIN];

  const removeUserFromAircraft = async () => {
    try {
      await toast.promise(
        api.aircraft.removeAircraftUser(aircraftData.id, user.email),
        TOAST_STATUSES.removeAircraftUser
      );

      setIsOpen(false);
      if (onDelete) onDelete();
    } catch (error) {
      console.error(error);
    }
  };

  const onSave = async (roleIds: string[], permissionLevel: string) => {
    await toast.promise(async () => {
      await api.aircraft.updateAircraftUser(
        aircraftData.id,
        user.email,
        roleIds,
        permissionLevel
      );
    }, TOAST_STATUSES.updateUserOnAircraft);

    const aircraft = await api.aircraft.getAircraft(aircraftData.id);
    setAircraftData(aircraft);
  };

  const retrieveChildModalProps = () => {
    switch (modalType) {
      case ModalOptions.EditResourceUser:
        return {
          setIsOpen,
          user,
          // @todo InvitationTypes -> UserResourceType
          shareType: InvitationTypes.AircraftUser,
          permissionsList,
          aircraftId: aircraftData.id,
          onSave,
        };
      case ModalOptions.DeleteResource:
        return {
          deleteModalType: DeleteModalOptions.User,
          title: `Are you sure you want to remove this user from aircraft '${aircraftData.title}'?`,
          buttonText: "Yes, Remove",
          handleSubmit: removeUserFromAircraft,
        };
    }
  };

  const editUserItem = [{
      title: "Edit User",
      img: editIcon,
      events: [
        () => setModalType(ModalOptions.EditResourceUser),
        () => setIsOpen(true),
      ],
    }]

  const removeUserItem = showRemoveUser ? [{
      title: "Remove User",
      img: deleteIcon,
      events: [
        () => setModalType(ModalOptions.DeleteResource),
        () => setIsOpen(true),
      ],
    }] : [];

  const meatballMenuItems = [
    ...editUserItem,
    ...removeUserItem
  ];

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: modalType,
    childModalProps: retrieveChildModalProps(),
  };

  useModalData(modalData);

  return <User
    user={user}
    pending={pending}
    permission={permission}
    meatballMenuItems={meatballMenuItems}
    showMeatballMenu={showMeatballMenu}
    roles={user.aircraftRoles}
    setShowMeatballMenu={setShowMeatballMenu}
  />
};

export default AircraftUser;

import { get } from "./axios";
import { DocumentSuggestedTitleType } from "../types/Document";

/**
 * Gets all suggested titles for a specific document grouping
 *
 * @returns A list of suggested titles
 */
const getDocumentSuggestedTitlesByType = async (type: DocumentSuggestedTitleType): Promise<string[]> => {
  try {
    const res = await get(`/document-suggested-title/${type}`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

export const documentSuggestedTitles = {
  getDocumentSuggestedTitlesByType,
};

import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Status, TransactionObject } from "../../types/Transaction";
import { ResourceContainer } from "../UI/Containers/ResourceContainer";
import MeatballMenu from "../UI/MeatballMenu";
import openEyeIcon from "../../images/open-eye-icon.svg";
import { Permission } from "../../types/Permission";
import deleteIcon from "../../images/delete-icon.svg";
import { DeleteModalOptions, ModalOptions } from "../../types/Modal";
import { useModalData } from "../Modals/hooks/useModalData";
import { toast } from "react-toastify";
import { api } from "../../api";
import { TOAST_STATUSES } from "../../utils/toast_statuses";

const Transaction: React.FC<{ tx: TransactionObject, permission: Permission, getTransactions: () => void }> = (props) => {
  const { tx, permission, getTransactions } = props;
  const location = useLocation()
  const navigate = useNavigate();
  const [showMeatballMenu, setShowMeatballMenu] = useState(false);
  const [modalType, setModalType] = useState(ModalOptions.Default);
  const [modalIsOpen, setIsOpen] = useState(false);

  const isAdmin = permission === Permission.ADMIN;

  const navigateToTransaction = () =>
    navigate(`/transactions/${tx.id}`, {state: location.pathname});

  const deleteTransaction = async () => {
    try {
      await toast.promise(
        api.transactions.deleteTransaction(tx.id),
        TOAST_STATUSES.deleteTransaction
      );

      setIsOpen(false);
      getTransactions();
    } catch (error) {
      console.error(error);
    }
  };

  const editItem = [
    {
      title: "View/Edit Transaction",
      img: openEyeIcon,
      events: [
        navigateToTransaction,
      ],
    }
  ];

  const deleteItem = (isAdmin && !tx.is_complete) ? [{
    title: "Delete Transaction",
    img: deleteIcon,
    events: [
      () => setModalType(ModalOptions.DeleteResource),
      () => setIsOpen(true),
    ],
  }] : [];


  const meatballMenuItems = [
    ...editItem,
    ...deleteItem
  ];

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: ModalOptions.DeleteResource,
    childModalProps: {
      deleteModalType: DeleteModalOptions.User,
      title: `Are you sure you want to delete this transaction'?`,
      buttonText: "Yes, Delete",
      handleSubmit: deleteTransaction,
    }
  };

  useModalData(modalData);

  return (
    <ResourceContainer
      status={tx.is_complete ? Status.Complete : Status.Pending}
      timestamp={tx.created_at}
      title={tx.name}
      key={tx.id}
      onClick={navigateToTransaction}
      meatBallMenu={
        <MeatballMenu
          meatballMenuItems={meatballMenuItems}
          showMeatballMenu={showMeatballMenu}
          setShowMeatballMenu={setShowMeatballMenu}
        />
      }
    />
  );
};

export default Transaction;

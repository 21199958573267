import React, { useCallback, useContext, useEffect, useState } from "react";
import { ModalOptions } from "../../types/Modal";
import { TransactionObject, TxsDisplayType } from "../../types/Transaction";
import { AddResourceButton } from "../Buttons/AddResourceButton";
import { IndexContainer } from "../UI/Containers/IndexContainer";
import NoDataDiv from "../UI/NoDataDiv";
import { api } from "../../api";
import { AircraftContext } from "../../utils/store";
import Transaction from "./Transaction";
import { useModalData } from "../Modals/hooks/useModalData";
import Select, { SingleValue } from "react-select";
import {
  DATE_DESC_OPTION,
  selectDocumentsStyles,
  SelectOption,
  SORT_SELECT_OPTIONS,
} from "../../utils/documents.utils";
import { SortingOption } from "../../types/Sorting";
import { handleSortData } from "../../utils/transactions.utils";
import { Permission } from "../../types/Permission";
import { useAircraftPermission } from "../../hooks/useAircraftPermission";
import { aircraftOwnerNames } from "../../utils/aircrafts.utils";

const TransactionsIndex: React.FC<{
  txHistoryType: TxsDisplayType;
}> = (props) => {
  const { txHistoryType } = props;

  const { aircraftData } = useContext(AircraftContext);
  let permission = useAircraftPermission();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [transactions, setTransactions] = useState([] as TransactionObject[]);

  // transactions page only (sorting)
  const [sortedTransactions, setSortedTransactions] = useState(
    [] as TransactionObject[]
  );
  const [transactionSort, setTransactionSort] =
    useState<SelectOption>(DATE_DESC_OPTION);

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: ModalOptions.InitiateTx,
    childModalProps: {
      aircraftId: aircraftData.id,
      aircraftTitle: aircraftData.title,
      setIsOpen,
    },
  };

  useModalData(modalData);

  const pageTitle =
    txHistoryType === TxsDisplayType.Aircraft
      ? "Aircraft Transaction History"
      : "User Transaction History";

  const pageSubTitle =
    txHistoryType === TxsDisplayType.Aircraft
      ? "View transactions or initiate a new transaction."
      : "To create a new transaction, select Aircraft from the main menu.";

  const showNoDataMessage = (): boolean => {
    return !transactions.length;
  };

  const getDisabledTooltip = (): string => {
    // If there are no owners, transaction add button is disabled
    if (!aircraftOwnerNames(aircraftData.sharedWith).length) {
      return "Aircraft must have at least one owner to create a transaction"
    }

    if (transactions.some((transaction) => !transaction.is_complete)) {
      return "An aircraft can only have one pending transaction."
    }

    return "";
  }

  /**
   * updates transaction sorting by date created or alphabetically
   *
   * @param transactionSortOption
   */
  const updateTransactionSort = (
    transactionSortOption: SingleValue<SelectOption>
  ) => {
    setTransactionSort(transactionSortOption as SelectOption);
  };

  /**
   * retrieves transactions for aircraft or for user (depending on txHistoryType prop)
   */
  const getTransactions = useCallback(async () => {
    try {
      switch (txHistoryType) {
        case TxsDisplayType.Aircraft: {
          const aircraftTxs = await api.transactions.getAircraftTransactions(
            aircraftData.id
          );
          return setTransactions(aircraftTxs);
        }
        case TxsDisplayType.User: {
          const userTxs = await api.transactions.getTransactions();
          return setTransactions(userTxs);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  /**
   * sorts transactions
   * @dependency transactions (context)
   * @dependency transactionsort (state)
   */
  useEffect(() => {
    if (transactions.length) {
      const sortedTransactions = handleSortData(
        transactionSort.value as SortingOption,
        transactions
      );

      setSortedTransactions(sortedTransactions);
    }
  }, [transactions, transactionSort]);

  return (
    <IndexContainer
      title={pageTitle}
      subTitle={pageSubTitle}
      addResourceButton={
        txHistoryType === TxsDisplayType.Aircraft &&
        permission === Permission.ADMIN ? (
          <AddResourceButton
            onClickEvent={() => {
              setIsOpen(true);
            }}
            title="TRANSACTION"
            styles="w-52 my-10"
            disabledTooltip={getDisabledTooltip()}
          />
        ) : undefined
      }
    >
      <div className="flex w-full justify-end mb-4">
        <Select
          value={transactionSort}
          options={SORT_SELECT_OPTIONS}
          onChange={(value) => updateTransactionSort(value)}
          styles={selectDocumentsStyles(false)}
          isSearchable={false}
        />
      </div>

      {showNoDataMessage() && <NoDataDiv />}

      {!showNoDataMessage() && sortedTransactions.map((tx: TransactionObject) => {
        // If we are on a user's transaction index, we need to get the permission from the transaction
        if (txHistoryType === TxsDisplayType.User) {
          permission = tx.permission_level === "admin" ? Permission.ADMIN : Permission.VIEWER;
        }

        return (
          <Transaction
            tx={tx}
            key={tx.id}
            permission={permission}
            getTransactions={getTransactions}
          />
          );
      })}
    </IndexContainer>
  );
};

export default TransactionsIndex;

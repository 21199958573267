import Document from './Document';
import { Tag } from '../types/Tag';
import { capitalize, truncateString } from '../utils/helpers';
import { AircraftObject } from '../types/Aircraft';
import { DocumentResourceType } from '../types/Resource';
import { DocumentUser } from "../types/Document";
import { aircraft } from "../api/aircraft";
import { TransactionObject } from "../types/Transaction";

export interface PaperworkResponse extends Document {
  tags: Tag[],
  documentVersions: Document[],
  aircraft?: AircraftObject,
  transaction?: TransactionObject,
  transaction_id: string | null,
  aircraft_id: string | null,
  sender_id: string | null,
  recipient_id: string | null;
  shared_with: DocumentUser[];
}

export class Paperwork extends Document {
  constructor(
    public id: string,
    public createdAt: string,
    public updatedAt: string,
    public title: string,
    public fileName: string,
    public fileKey: string,
    public isDocusign: boolean,
    public isSigned: boolean,
    public resourceType: DocumentResourceType,
    public createdBy: string,
    public tags: Tag[],
    public documentVersions: Document[],
    public aircraftId: string | null,
    public transactionId: string | null,
    public senderId: string | null,
    public recipientId: string | null,
    public shared_with: DocumentUser[],
    public aircraft?: AircraftObject,
    public transaction?: TransactionObject
  ) {
    super(
      id,
      createdAt,
      updatedAt,
      title,
      fileName,
      fileKey,
      isDocusign,
      isSigned,
      resourceType,
      createdBy,
    )
  }
  /**
   * formats label for link to document page
   */
  public get formattedLinkLabel(): string {
    switch (this.resourceType) {
      case DocumentResourceType.Aircraft:
        return (this.aircraft) ?
          `${capitalize(this.resourceType)}: ${this.aircraft?.title}` :
          ''
      case DocumentResourceType.Transaction:
        return (this.transaction) ?
          `${capitalize(this.resourceType)} on ${this.transaction?.aircraft_title}` :
          ''
      default:
        return ''
    }
  }

  public documentPageLink(userId: string): string | undefined {
    switch (this.resourceType) {
      case DocumentResourceType.Aircraft:
        if (
          this.aircraft &&
          (
            this.aircraft.created_by === userId ||
            (this.aircraft.sharedWith && this.aircraft.sharedWith.map(user => user.id).includes(userId))
          )
        ) {
          return `/${this.resourceType}/${this.aircraftId}`
        }
        return undefined
      case DocumentResourceType.Transaction:
        if (
          this.transaction &&
          (
            this.transaction.created_by === userId ||
            (this.transaction.sharedWith && this.transaction.sharedWith.map(user => user.id).includes(userId))
          )
        ) {
          return `/${this.resourceType}s/${this.transactionId}`
        }
        return undefined
      default:
        return ''
    }
  }

  public get formattedTitle(): string {
    const title = truncateString(this.title, 24)
    return this.is_docusign ? `${title} (docusign)` : title;
  }

  public get formattedFileName(): string {
    return truncateString(this.file_name, 28, true);
  }
}

export const createPaperworkInstance = (paperworkResponse: PaperworkResponse) => {
  return new Paperwork(
    paperworkResponse.id,
    paperworkResponse.created_at,
    paperworkResponse.updated_at,
    paperworkResponse.title,
    paperworkResponse.file_name,
    paperworkResponse.file_key,
    paperworkResponse.is_docusign,
    paperworkResponse.is_signed,
    paperworkResponse.resource_type,
    paperworkResponse.created_by,
    paperworkResponse.tags,
    paperworkResponse.documentVersions,
    paperworkResponse.aircraft_id,
    paperworkResponse.transaction_id,
    paperworkResponse.sender_id,
    paperworkResponse.recipient_id,
    paperworkResponse.shared_with,
    paperworkResponse.aircraft,
    paperworkResponse.transaction
  );
}
import { Routes, Route, Navigate } from "react-router-dom";
import PageNotFound from "../../components/Errors/PageNotFound";
import AdminWrapper from "../../components/Admin/AdminWrapper";
import InvitationIndex from "../../components/Admin/InvitationIndex";
import {useCallback, useContext, useEffect, useState} from "react";
import { api } from "../../api";
import {UserAdminContext, UserDataContext} from '../../utils/store';
import UserInvitation from '../../models/Invitation';
import { UserObject } from "../../types/User";
import { UsersIndex } from "../../components/Admin/UsersIndex";
import {Analytics} from "../../components/Admin/Analytics";

const AdminPage = () => {
  const [userInvitations, setUserInvitations] = useState<UserInvitation[]>([]);
  const [allUsers, setAllUsers] = useState<UserObject[]>([]);
  const { userData } = useContext(UserDataContext);

  const getInvitations = useCallback(async () => {
    try {
      const invites = await api.invitations.getOutgoingApplicationInvitations();
      
      const _userInvitations = UserInvitation.createInvitedUsers(invites, userData.email)
      setUserInvitations(_userInvitations)
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getAllUsers = useCallback(async () => {
    try {
      const users = await api.users.getAllUsers();
      setAllUsers(users)
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getInvitations();
  }, [getInvitations]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  return (
    <UserAdminContext.Provider value={{ userInvitations, setUserInvitations, allUsers, setAllUsers }}>
      <Routes>
        <Route element={<AdminWrapper />}>
          <Route path="/invite" element={<InvitationIndex getInvitations={getInvitations} />} />
          <Route path="/users" element={<UsersIndex />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route
            path="/"
            element={<Navigate to="/admin/invite" replace />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </UserAdminContext.Provider>
  );
};

export default AdminPage;

import React from "react";
import asset1 from "../../images/asset1.svg";
import asset2 from "../../images/asset2.svg";
import logo from "../../images/volas-logo-vertical.svg";

const OnboardingLogo = () => {
  return (
    <div className="bg-logo-grey hidden md:flex md:flex-col md:w-1/3 md:h-screen h-1/5 justify-evenly items-center">
      <img className="volas-logo-sides" src={asset2} alt={"logo-decoration"} />
      <img className="w-38 h-1/2 md:h-1/6" src={logo} alt={"logo"} />
      <img className="volas-logo-sides" src={asset1} alt={"logo-decoration"} />
    </div>
  );
};

export default OnboardingLogo;

import * as React from 'react';
import {Progress, Box, Text, Tooltip} from '@chakra-ui/react';
import {useEffect, useState} from "react";
import {FiInfo} from "react-icons/fi";

interface ProgressBarProps {
  percentageComplete: number;
  progressTooltip: string;
}

export const ProgressBar = ({percentageComplete, progressTooltip}: ProgressBarProps) => {
  // Ensure the percentageComplete is between 0 and 100
  const progress = Math.min(100, Math.max(0, percentageComplete));
  const progressRef = React.useRef<HTMLDivElement>(null);
  const [labelOffset, setLabelOffset] = useState(0);

  const tooltipContent = progressTooltip.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  useEffect(() => {
    if (progressRef.current) {
      const progressWidth = progressRef.current.offsetWidth;
      const newOffset = (progress / 100) * progressWidth;
      // Adjust the offset to keep the label inside the progress bar
      setLabelOffset(Math.min(newOffset, progressWidth - 50) + 5);
    }
  }, [progress])

  return (
    <>
      <h1 className="text-xl md:text-xxxl xxx-gray leading-loose">Transaction Progress</h1>
      <Box position="relative" width="500px" py="20px" mb="40px" maxWidth="90%" ref={progressRef}>
        <Progress
          min={0}
          max={100}
          height="20px"
          hasStripe={true}
          isAnimated={true}
          colorScheme="green"
          size="md"
          value={progress}
          borderRadius="md"
          bg="white"
        />
        <Text
          position="absolute"
          display="flex"
          alignItems="center"
          left={`${labelOffset}px`}
          top="40px"
          color="gray.600"
          fontSize="md"
          fontWeight="semibold"
          whiteSpace="nowrap"
          transform="translateX(-50%)"
        >
          <Tooltip hasArrow={true} label={tooltipContent} fontSize="md">
            <div className="flex items-center cursor-pointer">{progress}% <FiInfo className="ml-1" size="12px"/></div>
          </Tooltip>
        </Text>
      </Box>
    </>
  );
};
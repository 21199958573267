import React, { useContext, useEffect, useState } from "react";
import { api } from "../../api";
import closedEye from "../../images/closed-eye-icon.svg";
import openEye from "../../images/open-eye-icon.svg";
import { PrivacyModalProps } from "../../types/Modal";
import { PrivacySetting, PrivacySettingName } from "../../types/PrivacySetting";
import { UserDataContext } from "../../utils/store";
import RedButton from "../Buttons/RedButton";

const PrivacySettingsModal: React.FC<PrivacyModalProps> = ({
  handleSubmit,
}) => {
  const { userData } = useContext(UserDataContext);

  const [documentsPrivacy, setDocumentsPrivacy] = useState(false);
  const [permissionsPrivacy, setPermissionsPrivacy] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const privacySettings = await api.users.getPrivacySettings(userData.id);

        privacySettings.forEach((setting) => {
          switch (setting.name) {
            case PrivacySettingName.Briefcase:
              setDocumentsPrivacy(setting.isVisible);
              break;
            case PrivacySettingName.Permissions:
              setPermissionsPrivacy(setting.isVisible);
              break;
          }
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [userData.id]);

  const privacyOptions: PrivacySetting[] = [
    {
      name: PrivacySettingName.Briefcase,
      isVisible: documentsPrivacy,
      setIsVisible: setDocumentsPrivacy,
    },
    {
      name: PrivacySettingName.Permissions,
      isVisible: permissionsPrivacy,
      setIsVisible: setPermissionsPrivacy,
    },
  ];

  const handleClick = () => {
    handleSubmit([
      {
        name: PrivacySettingName.Briefcase,
        isVisible: documentsPrivacy,
      },
      {
        name: PrivacySettingName.Permissions,
        isVisible: permissionsPrivacy,
      },
    ]);
  };

  return (
    <div className="h-auto w-full px-6 md:px-0 md:w-468">
      <div className="flex flex-col items-center">
        <h1 className="text-xl md:text-2xl mt-10">Privacy Settings</h1>

        <p className="text-xs text-center md:text-sm mt-3 text-gray-500">
          Adjust what you make visible to the world
        </p>
        <div className="w-full md:w-10/12 border-b border-gray-300 mt-12" />
        {privacyOptions.map((option: PrivacySetting) => {
          return (
            <div
              className="flex cursor-pointer w-full md:w-10/12 items-center font-semibold tracking-widest justify-between border-b border-gray-300 py-4"
              key={option.name}
              onClick={() =>
                option.setIsVisible && option.setIsVisible(!option.isVisible)
              }
            >
              <p
                className={`text-sm ${
                  option.isVisible ? "text-gray-800" : "text-gray-400"
                }`}
              >
                {option.name.toUpperCase()}
              </p>
              <img src={option.isVisible ? openEye : closedEye} alt="" />
            </div>
          );
        })}

        <RedButton
          onClickEvent={handleClick}
          styles="w-28 my-7 px-3 py-3 text-xs"
          title="SAVE"
        />
      </div>
    </div>
  );
};

export default PrivacySettingsModal;

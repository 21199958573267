import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import editIcon from "../../../images/edit-small.svg";
import { ModalOptions } from "../../../types/Modal";
import { UserDataContext } from "../../../utils/store";

const OverviewHeader: React.FC<{
  setShowEditModal: (arg0: boolean) => void;
  setModalType: (arg0: ModalOptions) => void;
}> = (props) => {
  const { setShowEditModal, setModalType } = props;
  const { userData } = useContext(UserDataContext);
  const isCurrentUser = userData.id === useParams().id;

  return (
    <div className="flex justify-between mx-2 md:mx-7">
      <h2 className="text-lg md:text-2xl lg:text-3xl ">
        {isCurrentUser ? "Your Information" : "User Information"}
      </h2>
      {isCurrentUser && (
        <button
          className="flex items-center border border-volas-red bg-white rounded-md px-2 font-semibold tracking-wider h-7 text-xs hover:bg-gray-100"
          onClick={() => {
            setModalType(ModalOptions.EditInfo);
            setShowEditModal(true);
          }}
        >
          <img src={editIcon} alt="" className="mr-2" />
          EDIT
        </button>
      )}
    </div>
  );
};

export default OverviewHeader;

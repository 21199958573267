import React, { useRef } from "react";
import { useOutsideClickToCloseModal } from "../../hooks/useOutsideClickToCloseModal";
import meatballMenu from "../../images/meatball-menu.svg";
import { MeatballMenuItem, MeatballMenuProps } from "../../types/UI";

const MeatballMenu: React.FC<MeatballMenuProps> = (props) => {
  const {
    meatballMenuItems,
    showMeatballMenu,
    setShowMeatballMenu,
    setSelectedDoc,
    doc,
  } = props;

  const meatballMenuRef = useRef<HTMLInputElement>(null);

  useOutsideClickToCloseModal(meatballMenuRef, setShowMeatballMenu);

  return (
    <div
      ref={meatballMenuRef}
      className="px-1 py-4 cursor-pointer"
      onClick={() => {
        doc && setSelectedDoc && setSelectedDoc(doc);
        setShowMeatballMenu(!showMeatballMenu);
      }}
    >
      <img src={meatballMenu} alt="" className="mt-0" style={{minWidth: '25px'}} />
      {showMeatballMenu && (
        <div className="flex-col justify-center text-sm bg-white w-48 h-auto mt-2 rounded absolute z-50 right-0 left-auto ml-auto top-auto mr-4 shadow-3xl">
          {meatballMenuItems.map((item: MeatballMenuItem) => {
            return (
              <div className="w-full h-11 hover:bg-gray-100" key={item.title}>
                <button
                  className="cursor-pointer pl-2 w-full h-full flex justify-start items-center text-gray-600"
                  onClick={() => {
                    item.events.forEach((event: any) => event());
                  }}
                  onTouchStart={() => {
                    item.events.forEach((event: any) => event());
                  }}
                >
                  <img
                    src={item.img}
                    alt=""
                    className="self-center w-2.5 mr-2"
                  />
                  {item.title}
                </button>
                <div className="border w-full" />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MeatballMenu;

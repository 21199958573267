import { PrivacySetting } from "../types/PrivacySetting";
import { UserObject } from "../types/User";
import { get, post, put } from "./axios";
import { AircraftObject } from "../types/Aircraft";
import { TransactionObject } from "../types/Transaction";

/**
 * Gets the currently logged in user
 *
 * The user id is obtained from the token that is sent with the request in the
 * api
 *
 * @returns the user object
 */
const getCurrentUser = async (): Promise<UserObject> => {
  try {
    const res = await get("/user/current");
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

const getAllUsers = async (): Promise<UserObject[]> => {
  try {
    const res = await get("/user/all");
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
}

const getCurrentUserProfileDocuments = async (): Promise<Document[]> => {
  try {
    const res = await get("/user/profile-documents");
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Get a user by id
 *
 * @param id the user id
 * @returns a user object
 */
const getUserById = async (id: string): Promise<UserObject> => {
  try {
    const res = await get(`/user/${id}`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Searches for users by name or email with a string pattern
 *
 * Searches the users DB by name or email with wildcards at the start and end of
 * the pattern string. If a record exists with the name "Peter McPeterson",
 * searchUsers("ete") will return that record or any other record that contains
 * the string "ete". The wildcards are applied per word, so a search for the
 * pattern "Pet Mc" will also return the result for "Peter McPeterson". If the
 * pattern contains a space(s) the method will assume that the pattern is a
 * name.
 *
 * The search is not case sensitive.
 *
 * This is not a fuzzy search. In other words, if a record exists with the name
 * "Peter McPeterson", a search for the pattern "Pter" will not return the
 * record.
 *
 * @param pattern the search pattern
 * @returns a list of users that match the pattern with the fields id, name, and email
 */
const searchUsers = async (pattern: string): Promise<UserObject[]> => {
  try {
    const res = await get(`/user?pattern=${pattern}`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Updates a user's roles
 *
 * __NOTE__: Calling this method will overwrite the user's existing roles.
 * Include existing roles in the `roleIds` array.
 *
 * @param roleIds an array of role ids
 */
const updateRoles = async (roleIds: string[]): Promise<void> => {
  try {
    await post("/user/update-roles", { roleIds });
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Updates a user
 *
 * @param data the data that will update the user
 */
const updateUser = async (data: Partial<UserObject>): Promise<UserObject> => {
  try {
    const res = await put("/user", data);
    const user = res.data;
    return user;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Get a user's profile picture
 *
 * @param userId the id of the user
 * @returns the image as a blob
 */
const getProfilePicture = async (userId: string): Promise<Blob> => {
  try {
    const res = await get(`/user/${userId}/profile-picture`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Gets a list of a user's privacy settings
 *
 * @param userId the id of the user
 * @returns a list of privacy settings
 */
const getPrivacySettings = async (
  userId: string
): Promise<PrivacySetting[]> => {
  try {
    const res = await get(`/user/${userId}/privacy-settings`);
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Update a user's profile picture
 *
 * @param data the image data as a blob
 */
const updateProfilePicture = async (data: Blob): Promise<void> => {
  try {
    const formData = new FormData();
    formData.append("file", data);

    await post("/user/update-profile-picture", formData);
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Updates a user's privacy settings
 *
 * @param privacySettings a list of privacy settings where each object contains a `name` (from `PrivacySetting` enum) and an `isVisible` boolean
 */
const updatePrivacySettings = async (privacySettings: PrivacySetting[]) => {
  try {
    await post("/user/update-privacy-settings", privacySettings);
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Get permissions for all aircrafts user is assigned to
 *
 * @returns an array of AircraftObjects, with permission_level and role? fields
 */
const getUserAircraftRolesAndPermissions = async (): Promise<AircraftObject[]> => {
  try {
    const res = await get("/user/aircraft-permissions");
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Get permissions for all transactions user is assigned to
 *
 * @returns an array of TransactionObjects, with permission_level and role? fields
 */
const getUserTransactionRolesAndPermissions = async (): Promise<TransactionObject[]> => {
  try {
    const res = await get("/user/transaction-permissions");
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

/**
 * Get permissions for all transactions user is assigned to
 *
 * @returns an array of TransactionObjects, with permission_level and role? fields
 */
const getNetworkUsers = async (): Promise<UserObject[]> => {
  try {
    const res = await get("/user/network-users");
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

export const users = {
  getAllUsers,
  getCurrentUser,
  getCurrentUserProfileDocuments,
  getUserAircraftRolesAndPermissions,
  getUserTransactionRolesAndPermissions,
  getNetworkUsers,
  getUserById,
  updateRoles,
  updateUser,
  getProfilePicture,
  updateProfilePicture,
  searchUsers,
  updatePrivacySettings,
  getPrivacySettings,
};
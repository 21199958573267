import React, { FormEvent } from "react";
import { DeleteModalProps } from "../../types/Modal";
import RedButton from "../Buttons/RedButton";

const DeleteModal: React.FC<DeleteModalProps> = (props) => {
  const { handleSubmit, title, buttonText } = props;

  const _handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <div className="flex-col justify-center w-80 h-60 rounded">
      <form className="flex flex-col justify-evenly h-full w-full text-center px-6" onSubmit={_handleSubmit}>
        <h1 className="text-xl text-gray-500">{title}</h1>
        <RedButton
          type="submit"
          styles="self-center w-40 px-3 py-3 text-xs"
          title={buttonText}
        />
      </form>
    </div>
  );
};

DeleteModal.defaultProps = {
  buttonText: "Yes, Delete"
}

export default DeleteModal;

import React, { useContext } from "react";
import {
  RequiredForms,
  RequiredTxForms,
  TransactionRole,
} from "../../types/Transaction";
import { TransactionContext } from "../../utils/store";
import { useTransactionRoles } from "./hooks/useTransactionRoles";
import TransactionForms from "./TransactionForms";
import TransactionHeader from "./TransactionHeader";

const RequiredApprovals = () => {
  const { transaction } = useContext(TransactionContext);
  const roles = useTransactionRoles();
  const roleNames = roles.map((x) => x.name);

  const requiredForms = [
    {
      id: "1",
      title: RequiredForms.Faa,
      subTitle: "8050-1, 8050-2, International Registry, etc",
      complete: transaction.is_forms_filled,
      disabled: !roleNames.includes(TransactionRole.Escrow),
    },
    {
      id: "2",
      title: RequiredForms.Deposit,
      subTitle: "Escrow confirms deposit received",
      complete: transaction.is_deposit_state,
      disabled: !roleNames.includes(TransactionRole.Escrow),
    },
    {
      id: "3",
      title: RequiredForms.Disbursement,
      subTitle: "Escrow confirms all funds have been released",
      complete: transaction.is_disbursement_funds,
      disabled: !roleNames.includes(TransactionRole.Escrow),
    },
    {
      id: "4",
      title: RequiredForms.Seller,
      subTitle: "Transfers Volas aircraft profile",
      complete: transaction.is_seller_approval,
      disabled: !roleNames.includes(TransactionRole.Seller),
    },
    {
      id: "5",
      title: RequiredForms.Funds,
      subTitle: "Escrow confirms all funds received",
      complete: transaction.is_funds_received,
      disabled: !roleNames.includes(TransactionRole.Escrow),
    },
  ];

  const allFormsCompleted = () => {
    const formComplete = (form: RequiredTxForms) => form.complete;

    return requiredForms.every(formComplete);
  };

  const headerText = (): string => {
    return transaction.is_complete ?
      "This transaction is completed and in a read-only state." :
      "Transaction Parties are responsible for the following approvals.\nOnly the transaction creator can finalize a transaction."
  }

  return (
    <>
      <TransactionHeader
        title="Required Approvals"
        subTitle={headerText()}
        sectionCompleted={allFormsCompleted()}
      />
      <TransactionForms requiredForms={requiredForms} />
    </>
  );
};

export default RequiredApprovals;

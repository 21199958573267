import React from "react";
import { CheckSuccessIcon } from "../../types/Icons";

const CheckSuccess: React.FC<CheckSuccessIcon> = (props) => {
  const { fill, styles, height, width } = props;
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles}
    >
      <path
        d="m5.334 8.332 2 2 3.334-4"
        stroke={fill ? fill : "none"}
        strokeWidth="1.333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.001 14.665a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333z"
        stroke={fill ? fill : "none"}
        strokeWidth="1.333"
      />
    </svg>
  );
};

export default CheckSuccess;

import React, { CSSProperties } from "react";

interface ButtonProps {
  onClick: () => void;
  title: string;
}

/**
 * Just a simple button with an outline
 */
export const OutlineButton: React.FC<ButtonProps> = ({ onClick, title }) => {
  return (
    <button
      className="flex border-2 border-red-700 justify-center items-center hover:bg-gray-300 text-black tracking-wider rounded  text-xs self-center w-full h-full"
      onClick={onClick}
    >
      {title}
    </button>
  );
};

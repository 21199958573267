import React, { SyntheticEvent, useState } from "react";
import OnboardingLogo from "../Logos/OnboardingLogo";
import logo from "../../images/volas-logo-vertical.svg";
import arrow from "../../images/arrow-light-red.svg";
import { Link, useNavigate } from 'react-router-dom';
import redExclamation from "../../images/red-exclamation.svg";
import Label from "../Forms/Label";
import { api } from "../../api";
import RedButton from "../Buttons/RedButton";
import { ModalOptions } from "../../types/Modal";
import { useModalData } from "../Modals/hooks/useModalData";

const PasswordForgot = () => {
  const [email, setEmail] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const navigate = useNavigate();

  const sendPasswordForgetEmail = async (e: SyntheticEvent) => {
    try {
      e.preventDefault();
      await api.auth.forgotPassword(email);
      setIsOpen(true);
    } catch (error) {
      console.error(error);
      setShowErrorMessage(true);
    }
  };

  const handleSuccessSubmit = () => {
    setIsOpen(false);
    navigate("/login");
  }

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: ModalOptions.Success,
    navigateLocation: "/login",
    childModalProps: {
      successMessage: "Email sent",
      secondaryMessage:
        "Check your email and open the link we sent to continue.",
      buttonTitle: "AWESOME",
      handleSubmit: handleSuccessSubmit,
    },
  };

  useModalData(modalData);

  return (
    <div className="flex w-full h-screen font-light pb-10 md:font-normal flex-col md:pb-0 md:flex-row">
      <OnboardingLogo />
      <div className="md:hidden w-full h-1/4 bg-logo-grey flex justify-center">
        <img src={logo} alt="volas-logo" className="h-4/6 self-center" />
      </div>
      <div className="flex flex-col container pb-24 md:pb-0 w-full md:w-2/3 self-center h-auto text-center md:text-left justify-center mt-12 md:pl-16 lg:pl-28">
        <div className="flex flex-col h-1/2">
          <h2 className="text-xxl md:text-xxxl text-xxx-gray md:w-3/4 font-light pt-2 mx-6 md:mx-0">
            Enter your email and we'll send you a link to reset your password.
          </h2>
          <h3 className="pt-10 text-xs font-semibold tracking-widest">
            RESET PASSWORD
          </h3>
          <form onChange={() => setShowErrorMessage(false)}>
            <div className="flex justify-center md:justify-start w-full">
              <div className="flex flex-col justify-center w-10/12 mt-8">
                <Label
                  required={true}
                  hidden={email}
                  title="EMAIL YOU SIGNED UP WITH"
                />
                <div className="flex justify-center self-center md:self-start md:justify-start w-full">
                  <input
                    autoFocus
                    required
                    className={`w-96 focus:outline-none border-b border-gray-500 text-xs md:text-sm px-2 pb-2 ${
                      showErrorMessage && "border-red-600"
                    }`}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Email you signed up with"
                  />
                  <img
                    src={redExclamation}
                    alt=""
                    className={`self-end w-1.25 mb-2 ${
                      !showErrorMessage && "hidden"
                    }`}
                  />
                </div>
              </div>
            </div>
            <p
              className={`${
                !showErrorMessage && "invisible"
              } flex w-full justify-center md:justify-start text-red-600 text-xs tracking-wide mt-2 px-2`}
            >
              Please enter a valid email
            </p>
            <div className="flex justify-center md:justify-start">
              <RedButton
                onClickEvent={sendPasswordForgetEmail}
                styles="mt-14 w-52 px-3 py-3 text-xs"
                title="SEND LINK TO EMAIL"
                disabled={!email}
              />
            </div>
          </form>
          <Link
            to="/login"
            className="text-volas-red tracking-wider text-sm font-bold flex mt-6 self-center md:self-start"
          >
            <img src={arrow} alt="" className="mr-2 md:mr-3" />
            RETURN TO LOGIN
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordForgot;

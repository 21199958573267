import { useContext, useEffect } from "react";
import { ProfileUserContext } from "../../../utils/store";
import { api } from "../../../api";

/**
 * Loads any user given an id, used for viewing someone else's profile
 *
 * @param id the user id
 */
export const useLoadProfileUser = (id: string | undefined) => {
  const { setProfileUser } = useContext(ProfileUserContext);
  useEffect(() => {
    if (!id) return;
    (async () => {
      const user = await api.users.getUserById(id);
      if (user) {
        setProfileUser(user);
      }
    })();
  }, [id]);
};

import React from "react";
import logo from "../../images/volas-logo-vertical.svg";

export default class ErrorBoundary extends React.Component<
  any,
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="bg-gray-800 items-center h-screen w-screen">
          <div className="fixed translateY-1/2 w-full h-full flex flex-col justify-evenly md:justify-center">
            <img
              src={logo}
              alt="volas-logo"
              className="logo-decor w-44 md:mb-30 md:w-52 self-center"
            />
            <h1 className="self-center text-white text-2xl md:text-4xl">
              Oops. Something went wrong.
            </h1>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

import React from "react";

const NoDataDiv = () => {
  return (
    <div className="self-center bg-white md:self-start flex justify-center h-32 w-full">
      <p className="self-center text-lg">Nothing here yet.</p>
    </div>
  );
};

export default NoDataDiv;

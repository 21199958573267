import { PageHeader } from "../../types/UI";
import { TabContainer } from "../UI/Containers/TabContainer";
import { TabPageHeader } from "../UI/TabHeader";
import Tabs, { TabsPage } from "../UI/Tabs";
import { Outlet } from 'react-router-dom';
import { retrieveTabsFor } from "../../data/tabs";

const AdminWrapper = () => {
  return (
    <TabContainer
      header={<TabPageHeader pageTitle={PageHeader.Admin} />}
      tabs={
        <Tabs
          tabsList={retrieveTabsFor(TabsPage.Admin)}
        />
      }
    >
      <Outlet />
    </TabContainer>
  );
};

export default AdminWrapper;
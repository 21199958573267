import React from "react";
import { ShareInput } from "../../types/Invitations";

const ShareDocInput: React.FC<ShareInput> = (props) => {
  const {
    setUserEmail,
    userEmail,
    handleAddUser,
    validateUser,
    tempUserList,
  } = props;

  return (
    <input
      autoFocus
      placeholder="Enter valid email"
      type="text"
      className="self-center text-sm h-10 md:h-14 w-full ring-1 outline-none pl-2 rounded-sm border-b border-black"
      value={userEmail}
      onChange={(e) => {
        setUserEmail(e.target.value);
      }}
      onKeyPress={(e) =>
        e.key === "Enter" &&
        validateUser(tempUserList[0].email) &&
        tempUserList.length === 1 && tempUserList[0].email.trim() !== "" &&
        handleAddUser(tempUserList[0])
      }
    />
  );
};

export default ShareDocInput;

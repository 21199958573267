import {InvitationTypes} from "../types/Invitations";
import {Status} from "../types/Transaction";

export interface Invitation {
  id: string;
  created_at: string;
  recipient_email: string;
  sender_id: string;
  // @todo update to proper type
  permission: any;
  resource_type: InvitationTypes;
  resource_id: string;
  permission_level: string;
  name?: string;
  home_airport?: string;
  uid?: string;
  token?: string;
}

export interface AircraftInvitation extends Invitation {
  id: string;
  title: string;
}

export interface DocumentInvitation extends Invitation {
  id: string;
  title: string;
  document_resource_type: string;
}

export interface TransactionInvitation extends Invitation {
  id: string;
  name: string;
  aircraft_id: string;
}

class UserInvitation {
  constructor(private invitation: Invitation | AircraftInvitation | TransactionInvitation | DocumentInvitation, private userEmail: string) {}

  get id() {
    return this.invitation.id
  }
  get title() {
    switch(this.invitation.resource_type) {
      case InvitationTypes.AircraftUser:
        // @ts-ignore
        return `Aircraft ${this.invitation.title}`
      case InvitationTypes.TransactionUser:
        // @ts-ignore
        return `${this.invitation.name}`
      case InvitationTypes.Document:
        // @ts-ignore
        return `Document: ${this.invitation.title}`
      case InvitationTypes.ApplicationUser:
        return this.isIncomingInvite ? `From ${this.senderName}` : this.invitedUserEmail
      default:
        return ''
    }
  }

  get subTitle() {
    switch(this.invitation.resource_type) {
      case InvitationTypes.AircraftUser:
      case InvitationTypes.TransactionUser:
      case InvitationTypes.Document:
        return this.isIncomingInvite ? '' : `You invited ${this.invitation.recipient_email}`
      case InvitationTypes.ApplicationUser:
        return ``
      default:
        return ''
    }
  }

  get senderName() {
    return this.invitation.name
  }

  get homeAirport() {
    return this.invitation.home_airport
  }

  get resourceType() {
    return this.invitation.resource_type
  }
  get invitationId() {
    return this.invitation.id
  }

  get invitedUserEmail() {
    return this.invitation.recipient_email;
  }

  get isIncomingInvite() {
    return this.invitation.recipient_email === this.userEmail;
  }

  get timestamp() {
    return this.invitation.created_at;
  }

  get token() {
    return this.invitation.token;
  }

  get statusText() {
    // @todo update if used for other invitations
    return Status.Pending;
  }

  static createInvitedUsers = (invites: Invitation[] | AircraftInvitation[] | TransactionInvitation[], userEmail: string) => {
    return invites.map((invite) => new UserInvitation(invite, userEmail));
  };
}

export default UserInvitation;

import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import RedButton from "../../Buttons/RedButton";
import { api } from "../../../api";
import { UserDataContext } from "../../../utils/store";
import Select, { MultiValue } from "react-select";
import Label from "../../Forms/Label";
import {
  handlePhotoToURL,
  mapRoles,
  retrieveUserImg,
} from "../../../utils/helpers";
import ProfilePic from "./ProfilePic";
import volasUser from "../../../images/volas-user.svg";
import { EditProfileModalProps } from "../../../types/Modal";
import AsyncSelect from "react-select/async";

const EditProfileModal: React.FC<EditProfileModalProps> = (props) => {
  const { updateUserInfo } = props;

  const { userData } = useContext(UserDataContext);

  const inputRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState(userData.name);
  const [phone, setPhone] = useState(userData.phone);
  const [home_airport, setAirport] = useState(userData.home_airport);
  const [experience, setExperience] = useState(userData.experience);
  const [baseName, setBaseName] = useState<string | undefined>(undefined);

  const [profilePic, setProfilePic] = useState(volasUser);
  const [profilePicUpload, setProfilePicUpload] = useState<
    ChangeEvent<HTMLInputElement>
    >();

  const [filterOptions, setFilterOptions]: any = useState([]);
  const [multiValue, setMultiValue]: MultiValue<any> = useState(
    mapRoles(userData.roles)
  );

  // React select customization
  const CustomDropdownIndicator = () => null; // custom component or null to hide
  const CustomIndicatorSeparator = () => null; // custom component or null to hide

  const customComponents = {
    DropdownIndicator: CustomDropdownIndicator,
    IndicatorSeparator: CustomIndicatorSeparator,
  };

  const customStyles = {
    menu: (provided: any, state: any) => ({
      ...provided,
      color: state.selectProps.menuColor,
      border: '1px solid gray',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
    }),
  }

  // @ts-ignore
  const formatOptionLabel = ({ label, value }) => (
    <div>
      <div>{label}</div>
      <small>{value}</small>
    </div>
  );

  const isBaseInput = (title: string) => title === "HOME AIRPORT"

  const aircraftDataOptions = (
    inputValue: string
  ) => {
    if (inputValue.length < 3) { return new Promise((resolve) => resolve([])) }
    return api.aircraftData.searchAircraftData(inputValue)
  };

  const profileInputs = [
    {
      label: "FULL NAME",
      data: name,
      setData: setName,
      required: true
    },
    {
      label: "PHONE NUMBER",
      data: phone || "",
      setData: setPhone
    },
    {
      label: "HOME AIRPORT",
      data: home_airport || "",
      setData: setAirport
    },
    {
      label: "AIRCRAFT EXPERIENCE",
      data: experience || "",
      setData: setExperience
    },
  ];

  const selectRolesInputStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 9,
      paddingBottom: 9,
      border: "none",
    }),
    menuList: (provided: any, state: any) => {
      const height = "100px";
      const backgroundColor = "white";
      return { ...provided, height, backgroundColor };
    },
    dropdownIndicator: (provided: any, state: any) => {
      const cursor = "pointer";
      return { ...provided, cursor };
    },
  };

  useEffect(() => {
    if (userData.profile_picture) {
      setProfilePic(userData.profile_picture);
    } else if (userData.profile_picture_key) {
      setProfilePic(retrieveUserImg(userData.id));
    }
  }, [userData.profile_picture, userData.profile_picture_key, userData.id]);

  useEffect(() => {
    (async () => {
      try {
        const roles = await api.roles.getRoles();
        const options = mapRoles(roles).sort((a, b) =>
          a.label.localeCompare(b.label)
        );

        setFilterOptions(options);

        const userRoles = mapRoles(userData.roles);
        setMultiValue(userRoles);

        if (home_airport) {
          const base = await api.aircraftData.getAircraftDataByLocationId(home_airport);
          setBaseName(base.name);
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, []);

  const handleMultiChange = (option: MultiValue<any>) => {
    setMultiValue(option);
  };

  const onClickImage = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const onUploadPicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const photoURL = handlePhotoToURL(e);
    if (photoURL) {
      setProfilePic(photoURL);
      setProfilePicUpload(e);
    }
  };

  const formInvalid = () => {
    return !name
  };

  return (
    <div className="w-full h-auto md:w-468">
      <div className="flex flex-col items-center">
        <h1 className="text-2xl mt-10">Edit Profile</h1>
        <div
          className="flex items-center w-auto my-5 cursor-pointer"
          onClick={onClickImage}
        >
          <ProfilePic
            inputRef={inputRef}
            onChangeImage={(e) => onUploadPicture(e)}
            onClickImage={onClickImage}
            profilePic={profilePic}
            styles="w-10 h-10 mr-4"
          />
          <p className="text-sm text-volas-red underline">Replace Photo</p>
        </div>
        <div className="flex flex-col justify-evenly h-auto w-full">
          {profileInputs.map((profileData) => {
            return (
              <div
                className="w-11/12 md:w-10/12 self-center rounded-sm ring-1 my-2"
                key={profileData.label}
              >
                <Label
                  hidden="false"
                  title={profileData.label}
                  required={!!profileData.required}
                />
                {isBaseInput(profileData.label) ? (
                  <AsyncSelect
                    formatOptionLabel={formatOptionLabel}
                    styles={customStyles}
                    components={customComponents}
                    className="text-sm text-gray-500 no-border border-collapse focus:outline-none"
                    placeholder="Search by name or location"
                    loadOptions={aircraftDataOptions}
                    value={{label: baseName, value: profileData.data}}
                    onChange={(e) => {
                      setBaseName(e ? e!.label : "")
                      profileData.setData(e ? e!.value : "")
                    }}
                    noOptionsMessage={() => "Please Search at least 3 letters"}
                  />
                ) : (
                <input
                  className="text-sm text-gray-500 h-6 border-b border-gray-600 pt-1 pb-2 pl-2 w-full focus:outline-none"
                  type="text"
                  value={profileData.data}
                  maxLength={64}
                  onChange={(e) =>  {
                    profileData.setData(e.target.value)
                  }}
                />
                )}
              </div>
            );
          })}
        </div>
        <Select
          className="w-11/12 md:w-10/12 self-center text-sm ring-1 mt-2"
          name="filters"
          placeholder="Edit roles"
          value={multiValue}
          options={filterOptions}
          onChange={handleMultiChange}
          styles={selectRolesInputStyles}
          isMulti
        />
        <RedButton
          onClickEvent={() => updateUserInfo(
            { name, phone, home_airport, experience },
            multiValue,
            profilePicUpload
          )}
          styles="self-center w-44 mb-8 mt-12 px-3 py-3 text-xs"
          title="SAVE"
          disabled={formInvalid()}
        />
      </div>
    </div>
  );
};

export default EditProfileModal;

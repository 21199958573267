import React from "react";
import { Outlet } from "react-router-dom";
import Tabs, { TabsPage } from "../UI/Tabs";
import { TabContainer } from "../UI/Containers/TabContainer";
import { TabPageHeader } from "../UI/TabHeader";
import { retrieveTabsFor } from "../../data/tabs";
import { PageHeader } from "../../types/UI";

const PaperworkWrapper = () => {
  return (
    <TabContainer
      header={<TabPageHeader pageTitle={PageHeader.Paperwork} />}
      tabs={
        <Tabs
          tabsList={retrieveTabsFor(TabsPage.Documents)}
        />
      }
    >
      <Outlet />
    </TabContainer>
  );
};

export default PaperworkWrapper;

import React, { useCallback, useContext, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { api } from "../../api";
import Document from "../../components/Paperwork/Document";
import { IndexContainer } from "../../components/UI/Containers/IndexContainer";
import NoDataDiv from "../../components/UI/NoDataDiv";
import TagList from "../../components/UI/Tags/TagList";
import {
  Paperwork,
  createPaperworkInstance,
  PaperworkResponse,
} from "../../models/Paperwork";
import { ModalOptions } from "../../types/Modal";
import { SortingOption } from "../../types/Sorting";
import { Tag } from "../../types/Tag";
import { handleSortData } from "../../utils/document.utils";
import {
  ALL_DOCUMENTS_OPTION,
  DATE_DESC_OPTION,
  DocumentPermissionsFilterOption,
  documentsFilterByPermissions,
  DOCUMENT_SELECT_OPTIONS,
  filterDocuments,
  selectDocumentsStyles,
  SelectOption,
  SORT_SELECT_OPTIONS,
} from "../../utils/documents.utils";
import { DocumentContext } from "../../utils/store";
import { useModalData } from "../Modals/hooks/useModalData";
import { InvitationRecipient, InvitationTypes } from "../../types/Invitations";
import { toast } from "react-toastify";
import { TOAST_STATUSES } from "../../utils/toast_statuses";
import { Permission } from "../../types/Permission";

interface PaperworkIndexProps {
  retrieveUserDocuments: () => Promise<void>;
}
const PaperworkIndex = ({ retrieveUserDocuments }: PaperworkIndexProps) => {
  // documents
  const { documents } = useContext(DocumentContext);
  const [mappedDocuments, setMappedDocuments] = useState<Paperwork[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<Paperwork>();
  // documents filtering
  const [documentFilter, setDocumentFilter] =
    useState<SelectOption>(ALL_DOCUMENTS_OPTION);
  // document tags filtering (currently in use)
  const [userCreatedTags, setUserCreatedTags] = useState<Map<string, Tag>>(
    new Map()
  );
  const [activeFilters, setActiveFilters] = useState<Map<string, Tag>>(
    new Map()
  );
  // documents sorting
  const [documentSort, setDocumentSort] =
    useState<SelectOption>(DATE_DESC_OPTION);
  // modal state
  const [modalType, setModalType] = useState(ModalOptions.Default);
  const [modalIsOpen, setIsOpen] = useState(false);

  /**
   * submits new tag modal, adding tag to db and to selected document
   * @param newTag
   */
  const createNewTag = async (newTag: string) => {
    await api.tags.createTagAndAddToDocument(newTag, selectedDocument!.id);
    await reloadPaperwork();
  };

  /**
   * adds already created tag to selected document
   * @param tag
   */
  const addTagToDocument = async (tag: Tag) => {
    await api.documents.addTag(selectedDocument!.id, tag.id);
    await reloadPaperwork();
  };

  const handleSubmit = (tagName: string, tag?: Tag) => {
    // if tag is already created
    if (tag) {
      return addTagToDocument(tag);
    }
    createNewTag(tagName);
  };

  /**
   * reloads this components state
   */
  const reloadPaperwork = async () => {
    await retrieveUserDocuments();
    await getTags();
    setIsOpen(false);
    setModalType(ModalOptions.Default);
    setSelectedDocument(undefined);
  };

  const handleShareDocument = async (
    recipients: InvitationRecipient[],
    shareType: "aircraft" | "transaction" | "document" | "application",
    selectedResourceId: string
  ) => {
    await toast.promise(
      api.invitations.createInvitations(
        recipients,
        shareType,
        selectedResourceId
      ),
      TOAST_STATUSES.updateDocument
    );
    setModalType(ModalOptions.Success);
  };

  /**
   * removes selected tag from document
   * @param documentId
   * @param tagId
   */
  const removeTag = async (documentId: string, tagId: string) => {
    await api.documents.removeTag(documentId, tagId);
    await reloadPaperwork();
  };

  /**
   * creates modal child props
   * @returns
   */
  const retrieveChildProps = () => {
    switch (modalType) {
      /**
       * PaperworkIndex {@link InvitationModal}
       */
      case ModalOptions.Share:
        return {
          selectedResourceId: selectedDocument!.id,
          shareType: InvitationTypes.Document,
          handleSubmit: handleShareDocument,
          permissionsList: [Permission.ADMIN, Permission.VIEWER]
        };
      /**
       * PaperworkIndex {@link SuccessModal}
       */
      case ModalOptions.Success:
        return {
          successMessage: "Shared Successfully!",
          buttonTitle: "AWESOME",
          handleSubmit: reloadPaperwork,
        };
      /**
       * PaperworkIndex {@link TagsModal}
       */
      case ModalOptions.Tags:
        return {
          selectedDocument: selectedDocument,
          handleSubmit: handleSubmit,
        };
    }
  };

  const modalData = {
    modalIsOpen,
    setIsOpen,
    childModal: modalType,
    childModalProps: retrieveChildProps(),
  };

  useModalData(modalData);

  /**
   * retrieves all tags created by user
   */
  const getTags = useCallback(async () => {
    const tagMap = new Map();
    const USED_TAGS_ONLY = true;
    const tags = await api.tags.getTags(USED_TAGS_ONLY);
    tags.forEach((tag: Tag) => {
      tagMap.set(tag.id, tag);
    });
    setUserCreatedTags(tagMap);
  }, []);

  /**
   * updates document filter by shared, owned and all documents
   * @param filterBy
   */
  const updateDocumentFilter = (filterBy: SingleValue<SelectOption>) => {
    setDocumentFilter(filterBy as SelectOption);
  };

  /**
   * if tag is active, deactivates tag filter; otherwise, tag is set to active,
   * @param tag
   */
  const updateTagFilters = (tag: Tag) => {
    const activeTagFilters = new Map(activeFilters);
    if (activeTagFilters.has(tag.id)) {
      activeTagFilters.delete(tag.id);
    } else {
      activeTagFilters.set(tag.id, tag);
    }
    setActiveFilters(activeTagFilters);
  };

  /**
   * clears active tag filters
   */
  const clearTagFilters = () => {
    const activeTagFilters = new Map(activeFilters);
    activeTagFilters.clear();
    setActiveFilters(activeTagFilters);
  };

  /**
   * updates document sorting by date created or alphabetically
   * @param documentSortOption
   */
  const updateDocumentSort = (
    documentSortOption: SingleValue<SelectOption>
  ) => {
    setDocumentSort(documentSortOption as SelectOption);
  };

  /**
   * filters and sorts documents
   * @dependency documents (context)
   * @dependency documentFilter (state)
   * @dependency documentSort (state)
   */
  useEffect(() => {
    if (documents.size) {
      const mappedDocuments = Array.from(documents.values());
      const documentsFilteredByPermissions = documentsFilterByPermissions(
        mappedDocuments,
        documentFilter.value as DocumentPermissionsFilterOption
      );
      const documentsFilteredByTags = filterDocuments(
        documentsFilteredByPermissions,
        activeFilters
      );
      const sortedDocuments = handleSortData(
        documentSort.value as SortingOption,
        documentsFilteredByTags
      );
      const mappedPaperwork = sortedDocuments.map(
        (document: PaperworkResponse) => createPaperworkInstance(document)
      );
      setMappedDocuments(mappedPaperwork);
    }
  }, [documents, documentFilter, documentSort, activeFilters]);

  /**
   * retrieves user generated tags
   */
  useEffect(() => {
    getTags();
  }, []);

  return (
    <IndexContainer
      title={documentFilter.label}
      subTitle="Central repository for all of your files in Volas."
      dropDownProps={{
        value: documentFilter,
        options: DOCUMENT_SELECT_OPTIONS,
        onChange: updateDocumentFilter,
        styles: selectDocumentsStyles(),
      }}
    >
      <div className="flex w-full justify-between mb-4">
        <TagList
          tagList={Array.from(userCreatedTags.values())}
          handleClearTags={clearTagFilters}
          activeTagFilters={Array.from(activeFilters.values())}
          updateTagFilters={updateTagFilters}
        />
        <Select
          value={documentSort}
          options={SORT_SELECT_OPTIONS}
          onChange={(value) => updateDocumentSort(value)}
          styles={selectDocumentsStyles(false)}
          isSearchable={false}
        />
      </div>

      {!mappedDocuments.length && <NoDataDiv />}
      {mappedDocuments.map((document: Paperwork) => {
        return (
          <Document
            key={document.id}
            document={document}
            setSelectedDocument={setSelectedDocument}
            setModalType={setModalType}
            setIsOpen={setIsOpen}
            removeTag={removeTag}
          />
        );
      })}
    </IndexContainer>
  );
};

export default PaperworkIndex;

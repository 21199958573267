import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../api";
import { AuthContext, UserDataContext } from "../../utils/store";
import { InvitedResource } from "../../api/auth";
import { sharedResourceUrl } from "../../utils/helpers";


export const Accept: React.FC = () => {
  const { userData, setUserData } = useContext(UserDataContext);
  const { setIsAuthorized } = useContext(AuthContext);
  const [error, setError] = useState<boolean>(false);

  const navigate = useNavigate();

  const { token } = useParams();

  useEffect( () => {
    (async () => {
      if (token) {
        try {
          const { user, resource } = await api.invitations.acceptResourceInvite(token);
          setIsAuthorized(true);
          setUserData({...userData, id: user.id});

          navigate(sharedResourceUrl(resource));
        } catch (error) {
          setError(true);
        }
      } else {
        setError(true);
      }
    })();
  }, [])

  if (error) {
    return (
      <>
        The invitation token is invalid.
      </>
    )
  }

  return (
    <></>
  )
}
import { ResourceRole } from "../types/ResourceRole";
import { get } from "./axios";

/**
 * Gets a list of all available aircraft roles
 *
 * @returns An array of aircraft roles
 */
const getAircraftRoles = async (): Promise<ResourceRole[]> => {
  try {
    const res = await get("/aircraft-role");
    return res.data;
  } catch (error: any) {
    return Promise.reject(JSON.stringify(error));
  }
};

export const aircraftRoles = {
  getAircraftRoles,
};

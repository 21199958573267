import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Login";
import Onboard from "./Layouts/OnboardLayout";
import PasswordForgot from "./PasswordReset/PasswordForgot";
import PasswordReset from "./PasswordReset/PasswordReset";
import Layout from "./Layouts/NavLayout";
import LandingPage from "./LandingPage";
import RedirectActiveSessionRoute from "./Auth/RedirectActiveSessionRoute";
import PageNotFound from "./Errors/PageNotFound";
import { Accept } from "../pages/Invitation/Accept";

const Body = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RedirectActiveSessionRoute>
            <LandingPage />
          </RedirectActiveSessionRoute>
        }
      />
      <Route
        path="/login"
        element={
          <RedirectActiveSessionRoute>
            <Login />
          </RedirectActiveSessionRoute>
        }
      />
      <Route path="/forgot-password" element={<PasswordForgot />} />
      <Route path="/reset-password" element={<PasswordReset />} />
      <Route path="/page-not-found" element={<PageNotFound />} />
      <Route path="/invitations/accept/:token" element={<Accept />} />
      <Route path="/onboard/*" element={<Onboard />} />
      <Route path="/*" element={<Layout />} />
    </Routes>
  );
};

export default Body;

import React, { useContext } from "react";
import { UserAdminContext } from "../../utils/store";
import { IndexContainer } from "../UI/Containers/IndexContainer";
import NoDataDiv from "../UI/NoDataDiv";

export const UsersIndex = () => {
  const { allUsers } = useContext(UserAdminContext);

  return (
    <>
      <IndexContainer
        title="Volas Users"
        subTitle={"List of Volas users that have completed onboarding"}
        addResourceButton={undefined}
      >
        {!allUsers.length && <NoDataDiv />}

        {allUsers.map((user) => {
          return (
            <div
              className="my-1 text-sm w-full"
              key={user.email}
            >
              {user.email}
            </div>
          );
        })}
      </IndexContainer>
    </>
  );
};

import { UserObject } from "./User";

export interface AircraftObject {
  id: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  title: string;
  type: string;
  base: string;
  tail_number: string;
  serial_number: string;
  sharedWith: UserObject[];
  sharedWithPending: UserObject[];
  aircraft_name?: string;
  permission_level?: string;
  roles?: (string | null)[];
  pendingTransactionCount?: number;
  profile_picture_key?: string;
  files?: any;
}

export enum AircraftModalTypes {
  Edit = "Edit",
  Add = "Add",
}
